import React, { useState } from 'react';
import Table from "../../../components/Table/Table";
import AddIcon from '@mui/icons-material/Add';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import SubjectAreaEdit from './SubjectAreaEdit';
import DraggableDialog from '../../../components/hooks/Dialog';
import {
    useCheckSubjectAreaMutation,
    useCreateSubjectMutation,
    useDeleteSubjectMutation,
    useGetSubjectAreaSearchResultMutation,
    useGetSubjectDetailsQuery,
    useGetSubjectListQuery,
    useUpdateSubjectMutation
} from '../../../redux/features/subjects/subjectApi';
import useProtected from '../../../hooks/useProtected';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import EmptyCard from '../../../components/EmptyCard/EmptyCard';

const SubjectArea = () => {
    const { data, isError, isLoading, error } = useGetSubjectListQuery()
    const [createSubject, createSubjectOptions] = useCreateSubjectMutation()
    const [deleteSubject, deleteSubjectOptions] = useDeleteSubjectMutation()
    const [checkSubject, checkSubjectOptions] = useCheckSubjectAreaMutation()
    const [updateSubject, updateSubjectOptions] = useUpdateSubjectMutation()
    const [subjectAreaSearchResult, subectAreaSearchResultOptions] = useGetSubjectAreaSearchResultMutation()


    useProtected(error)
    useProtected(createSubjectOptions.error)
    useProtected(deleteSubjectOptions.error)
    useProtected(checkSubjectOptions.error)
    useProtected(updateSubjectOptions.error)

    const [rows, setRows] = useState([])
    const [addsubject, setAddsubject] = useState(false)
    const [editSubject, setEditSubject] = useState(false)
    const [deleteSub, setDeleteSub] = useState(false)
    const [subjectArea, setSubjectArea] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [subjectAreaDescription, setSubjectAreaDescription] = useState('')
    const [subjectAreaError, setSubjectAreaError] = useState('')
    const [editId, setEditId] = useState(null)
    const [editNamePrevious, setEditNamePrevious] = useState('')
    const [searchTerm, setSearchTerm] = useState('');



    useEffect(() => {
        if (!isError && !isLoading) {
            setRows(convertDataToCellRows(data.body.data));
        }
    }, [data, isError, isLoading])

    useEffect(() => {
        console.log(subjectArea, subjectAreaDescription)
    }, [subjectArea, subjectAreaDescription])

    const convertDataToCellRows = (arr) => {
        if (arr?.length > 0) {
            return arr.map((item) => {
                const startDate = new Date(item?.startDate);
                const endDate = new Date(item?.endDate);
                return {
                    cell: [
                        {
                            textBold: item?.subjectArea ? item?.subjectArea : 'N/A',
                        },
                        { text: item?.subjectAreaDescription ? item?.subjectAreaDescription : '' },
                        // { text: "Cat name" },
                        {
                            Status: true,
                            background: '#EDEDEF',
                            color: '#676C7B',
                            label: "0",
                        },
                        {
                            ActionButton: true,
                            ActionButtonLabel: 'Action',
                        },
                    ],

                    //======= Action Date Start===== //
                    action: [
                        { label: 'EDIT', onClick: (e) => editSubjectDrawer(item?._id) },
                        { label: 'DELETE', onClick: (e) => deleteSubjectAreaHandler(item?._id) },
                    ]
                }
            })
        }
    }
    const deleteSubjectAreaHandler = (id) => {
        setDeleteId(id)
        setDeleteSub(true)
    }
    const deleteSubjectHandler = async () => {
        let result = await deleteSubject(deleteId)
        if (result?.data) {
            toast.success("Subject area deleted")
            setDeleteId('')
            setDeleteSub(false)
        }
    }
    const onClick = () => {
        setDeleteSub(true)
    };

    const addSubjectDrawer = () => {
        setAddsubject(true);
    };
    const editSubjectDrawer = (id) => {
        setEditSubject(true);
        setEditId(id)
    };

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Subject Area Name',
            width: '30%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Description',
            width: '40%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: '# of Courses',
            width: '20%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: '',
            width: '10%',
            textAlign: 'left',
        },
    ];

    const isValid = () => {
        let error = 0
        if (subjectArea == '') {
            setSubjectAreaError('Required')
            error++
        }
        return (error == 0)
    }


    const addSubjectHandler = async () => {
        let data = {
            subjectArea,
            subjectAreaDescription
        }

        let resultCheck = await checkSubject({ name: subjectArea })
        if (resultCheck?.data && resultCheck?.data?.error == true && (subjectArea != editNamePrevious)) {
            setSubjectAreaError('Subject area name already exists')
            return false
        }
        if (!isValid()) {
            return false
        }

        if (editId) {

            let result = await updateSubject({ ...data, id: editId })
            if (result?.data) {
                setEditSubject(false)
                toast.success("Subject area updated")
            }
        } else {
            let result = await createSubject({ ...data })
            if (result?.data) {
                setAddsubject(false)
                toast.success("Subject area added")
            }

        }

        setEditId(null)
        setSubjectArea('')
        setSubjectAreaDescription('')
        setSubjectAreaError('')
    }
    const subjectEditDrawerCloseHandler = () => {
        setEditSubject(false)
        setSubjectArea('')
        setSubjectAreaDescription('')
        setEditId(null)
        setSubjectAreaError('')
    }
    const subjectDrawerCloseHandler = () => {
        setAddsubject(false)
        setSubjectArea('')
        setSubjectAreaDescription('')
        setEditId(null)
        setSubjectAreaError('')
    }

    //================= search part start =========================//
    const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
    const [resetsearchTerm, setResetSearchTerm] = useState(false);
    useEffect(() => {
        if (resetsearchTerm) {
            setTimeout(async () => {
                await handleResetSearch()
            }, 300);
            setResetSearchTerm(false)
        }
    }, [resetsearchTerm])

    const handleSearchText = (e) => {
        e.preventDefault()
        setSearchTerm(e.target.value)
    }
    const handleResetSearch = async () => {
        setPageNumberFromOutSide(true)
        const result = await subjectAreaSearchResult({ term: "" });
        searchDataShowing(result)
    };
    const handleSearch = async () => {
        setPageNumberFromOutSide(true)
        const result = await subjectAreaSearchResult({ term: searchTerm });
        searchDataShowing(result)
    }

    const searchDataShowing = (result) => {
        if (!result?.data?.error) {
            setRows(convertDataToCellRows(result?.data?.body?.data));
        } else {
            toast.error("Something went wrong")
        }
        setPageNumberFromOutSide(false)
    }
    //================= search part end =========================//

    return (
        <>
            <div className="TableSection">
                {
                    rows?.length > 0 ? (
                        <Table
                            title="Subject Area"
                            tableHeader={true}
                            addLabel="New Subject area"
                            icon={<AddIcon />}
                            AddButton={true}
                            headCells={headCells}
                            rows={rows}
                            onClick={addSubjectDrawer}
                            // filterCountStatus={(statuses?.length + streams?.length + userTypes?.length) > 0}
                            // filterCountNumber={(statuses?.length + streams?.length + userTypes?.length)}
                            footer={true}
                            handleSearchText={handleSearchText}
                            handleSearch={handleSearch}
                            setResetSearchTerm={setResetSearchTerm}
                            setSearchTerm={setSearchTerm}
                            resetsearchTerm={resetsearchTerm}
                            // foundResult={rows?.length}
                            showFoundResult={true}
                            // isSearchResult={isSearchResult}
                            innerHeader={true}
                            SearchBoxTop={true}
                            searchBoxPlaceholder="Search subject area"
                            // link="/invited-users"
                            // isDataLoading={isLoading || options?.isLoading}
                            FilterButtonDrawer={false}
                            // onClickFilterButton={handleFilterDrawerOpen}
                            RowsPerPage={20}
                            pageNumberFromOutSide={pageNumberFromOutSide}
                            emptyTableTitle="No subject areas added yet"
                            emptyTableNote="Added subject areas will be visible here"
                            btnLabel="New Subject area"
                            onClickEmptyCard={addSubjectDrawer}
                            showButton={true}
                        />
                    ) : (
                        <EmptyCard
                            TableTitleStatus={true}
                            TableTitle="Subject Area"
                            title="No subject areas added yet"
                            description="Added subject areas will be visible here"
                            showButton={true}
                            btnLabel="New Subject area"
                            onClick={addSubjectDrawer}
                        // btnLink={btnLink} 
                        />
                    )
                }
            </div>

            {/* ======================= New Subject add drawer ====================== * */}
            <SideDrawer
                open={addsubject}
                handleDrawerClose={() => subjectDrawerCloseHandler()}
                handleDrawerCloseButtonBottom={() => subjectDrawerCloseHandler()}
                title="Add Subject Area"
                buttonLabel="SAVE"
                sideOpen="right"
                ButtonLabel="SAVE"
                cancelButtonLabel="Cancel"
                FooterWithoutTab={true}
                clickSaveButton={(e) => addSubjectHandler()}

                body={
                    <>
                        <SubjectAreaEdit
                            subjectArea={subjectArea}
                            setSubjectArea={setSubjectArea}
                            subjectAreaDescription={subjectAreaDescription}
                            setSubjectAreaDescription={setSubjectAreaDescription}
                            subjectAreaError={subjectAreaError}
                        />
                    </>
                }
            />
            {/* ======================= Edit Subject add drawer ====================== * */}
            <SideDrawer
                open={editSubject}
                handleDrawerClose={() => subjectEditDrawerCloseHandler()}
                handleDrawerCloseButtonBottom={() => subjectEditDrawerCloseHandler()}
                title="Edit Subject Area"
                buttonLabel="Save"
                sideOpen="right"
                ButtonLabel="Save"
                cancelButtonLabel="Cancel"
                FooterWithoutTab={true}
                clickSaveButton={(e) => addSubjectHandler()}

                body={
                    <>
                        <SubjectAreaEdit
                            subjectArea={subjectArea}
                            setSubjectArea={setSubjectArea}
                            subjectAreaDescription={subjectAreaDescription}
                            setSubjectAreaDescription={setSubjectAreaDescription}
                            subjectAreaError={subjectAreaError}
                            id={editId}
                            setEditNamePrevious={setEditNamePrevious}
                        />
                    </>
                }
            />
            {/* ======================= Subject delete modal ====================== * */}
            <DraggableDialog
                openDialog={deleteSub}
                handleCloseDialog={() => setDeleteSub(false)}
                handleConfirmCloseDialog={(e) => deleteSubjectHandler()}
                title="Delete this subject area?"
                body={<div>Are you sure you want to delete this subject area?<br /> Once you delete it, you can't get it back.</div>}
                ModalFooter={true}
                actionButton="DELETE"
                cancelButton="CANCEL"
            />
        </>
    )
}

export default SubjectArea