import React, { useState, useEffect } from "react";
// import { useSearchParams } from "react-router-dom";
// import axios from 'axios';

// import { getUserData } from '../../../hooks/HelperFunctions';

// import CustomizedSnackbar from '../../../hooks/Snackbar';
// import SimpleBackdrop from '../../../components/Form/Backdrop/SimpleBackdrop'
import VerticalTab from '../../../components/VerticalTab/VerticalTab';
import { Person, Receipt, Security } from "@mui/icons-material";
// import PersonalInfo from './personal-info/PersonalInfo';
// import Billing from './billing/Billing';
// import Account from './account/Account';
import Avatar from '@mui/material/Avatar';
import { deepPurple, } from '@mui/material/colors';
import { RiLockPasswordFill } from "react-icons/ri"
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Buttons from "../../../components/Form/Button/Button";
import AccSecurity from "./Security/AccSecurity"
import Profile from "./Profile/Profile";
import { useGetUserDetailsQuery } from "../../../redux/features/users/userApi";
import { useSelector } from "react-redux";
import { getProfileAvatarFromName, toTitleCase, mapMonth } from "../../../utils/helpers";
import useProtected from "../../../hooks/useProtected";
import { useUpdateSettingsMutation } from "../../../redux/features/users/userApi";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/features/authSlice";

// import Buttons from '../../../components/Form/Button/Button';
import SimpleBackdrop from './../../../components/Form/Backdrop/SimpleBackdrop';
import { Divider } from "@mui/material";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import UpdatePersonalInfo from "./Profile/UpdatePersonalInfo";



function SettingsPage(props) {


    //  * ========================= redux api ====================== * //
    const [updateSettings, updateSettingsOptions] = useUpdateSettingsMutation();
    useProtected(updateSettingsOptions.error);

    const { _id: id } = useSelector(state => state.auth.user);
    const { data, isLoading, isError, error, refetch } = useGetUserDetailsQuery(id, {
        refetchOnMountOrArgChange: true,
    });
    useProtected(error);


    const dispatch = useDispatch();
    const [editProfile, setEditProfile] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [startTab, setStartTab] = useState(0);
    const [personalInfo, setPersonalInfo] = useState([]);
    const [formData, setFormData] = useState(null)

    // console.log("formData", formData)

    const handleEditProfile = () => {
        setEditProfile(true);
    }


    const tabData = [
        {
            icon: <Person />,
            label: "Profile",
        },

        {
            icon: <RiLockPasswordFill />,
            label: "Access",
        },

        // {
        //     icon: <Receipt />,
        //     label: "Billing",
        // },

    ]

    const tabBody = [

        {
            id: 0,
            panel:
                <Profile
                    personalInfo={personalInfo}
                    editProfile={editProfile}
                    setEditProfile={setEditProfile}
                    setPersonalInfo={setPersonalInfo}
                    refetchProfile={refetch}
                    formData={formData}
                    setFormData={setFormData}
                />,
        },
        {
            id: 1,
            panel: <AccSecurity personalInfo={personalInfo} />,
        },
        // {
        //     id: 2,
        //     panel: "tab three",
        // },

    ]

    const handleTabSwitch = (activeTab) => {
        // console.log(activeTab);
        setCurrentTab(activeTab);
    }

    useEffect(() => {
        if (!isLoading && data && !error) {
            setPersonalInfo(data.body.data)
        }
    }, [data, error, isLoading])


    // * ================= Edit sideDrawer ================= * // 

    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const EditDrawerOpen = () => setOpenEditDrawer(true)
    const EditDrawerClose = () => setOpenEditDrawer(false)

    // * ================ Edit profile handler ================== * // 
    const handleUpdateProfile = async (profileInfo) => {
        const data = {
            firstName: profileInfo?.firstName,
            middleName: profileInfo?.middleName,
            lastName: profileInfo?.lastName,
            gender: profileInfo?.gender,
            profilePic: profileInfo?.profilePic,
            country: profileInfo?.country,
            address: profileInfo?.address,
            birthDate: (profileInfo?.birthMonth && profileInfo?.birthYear) ? new Date(profileInfo?.birthYear, typeof profileInfo?.birthMonth === 'string' ? mapMonth(profileInfo?.birthMonth) : profileInfo?.birthMonth, profileInfo?.birthDay) : profileInfo?.birthDate,

        }
        const result = await updateSettings((data));

        if (!result?.data?.error) {
            EditDrawerClose()
            toast.success("Profile updated")
            // setFormData(personalInfo);
            const response = await refetch();
            if (!response?.data?.error) {
                const data = {
                    ...response.data.body.data,
                    birthMonth: response?.data?.body?.data?.birthDate ? moment(response?.data?.body?.data?.birthDate).month() : "",
                    birthYear: response?.data?.body?.data?.birthDate ? moment(response?.data?.body?.data?.birthDate).year() : "",
                    birthDay: response?.data?.body?.data?.birthDate ? moment(response?.data?.body?.data?.birthDate).date() : ""
                }
                setFormData(data);
                dispatch(setUserData({ ...data }));
            }
        } else {
            toast.error(result.data.message)
        }
    };


    return (
        <div className="">
            <SimpleBackdrop open={isLoading} />
            <div className="UserDetails">
                <li>

                    <div className="UserDetailsleftcol">
                        <div className="UserDetailsrIcon">
                            <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} src={personalInfo?.profilePic || ""} > {personalInfo?.profilePic ? "" : getProfileAvatarFromName(personalInfo)} </Avatar>
                            {/* {studentData.image ?
                                <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} src={studentData.image ? studentData.image : ""} ></Avatar>
                                :
                            <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} > {studentData.firstName ? Array.from(studentData.firstName)[0] : ""} </Avatar>
                            } */}
                        </div>

                        <div className="UserDetailsDetail">
                            <span className="settings-persone-name"><h1>{personalInfo?.firstName || ""} {personalInfo?.middleName || ""} {personalInfo?.lastName || ""}</h1> <span className="activeLabel">Active</span></span>
                            <p><span>{personalInfo?.role ? toTitleCase(personalInfo.role) : ""}</span><Divider orientation="vertical" sx={{ mb: 0, mx: 0.5, height: '10px', display: 'inline-block', border: ' 1px solid #C6C6D0' }} variant="middle" flexItem /><span>ID: {personalInfo?.userUniqueID}</span></p>
                            {/* {studentData?.firstName && <h1>{`${studentData.firstName} ${studentData.lastName}`}</h1>}
                            {studentData?.email && <p>{studentData.email}</p>} */}
                        </div>
                    </div>

                    {/* {currentTab === 0 && */}
                    <div className="EditUserProfile">
                        <Buttons
                            label="Edit Profile"
                            buttonColor="#fff"
                            border="#E4E8EC 1px solid"
                            color="#004FE0"
                            width=""
                            height="48px"
                            fontSize="14px"
                            // onClick={() => handleEditProfile()}
                            onClick={() => EditDrawerOpen()}
                        />
                    </div>
                    {/* } */}

                </li>

                {/* <div className="UserStatus">
          <div className="UserStatusCol">{totalRunningCourses} Running Courses</div>
          <div className="UserStatusCol">{totalCompletedCourses} Completed Courses</div>
          <div className="UserStatusCol">{totalCirtificates} Certificates</div>
        </div> */}

            </div>

            <div>
                <VerticalTab
                    //title="Introduction to Islamic Economics and Finance"
                    //name="MUNA Academy"
                    link="#"
                    tabData={tabData}
                    tabBody={tabBody}
                    onTabSwitch={handleTabSwitch}
                    activeTab={startTab}
                //onClick={onClick()}
                //ButtonLabel="Start Class"
                />

                <div></div>
            </div>
            {/* <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} /> */}

            {/* ============== profile update drawer ================ */}
            <SideDrawer
                title="Edit Profile"
                cancelButtonLabel="Cancel"
                deleteText="Delete Permanently"
                sideOpen="right"
                open={openEditDrawer}
                handleDrawerClose={EditDrawerClose}
                ButtonLeft="Upload New"
                FooterWithoutTab={true}
                ButtonLabel="Save"
                clickSaveButton={() => handleUpdateProfile(formData)}
                body={
                    <div>
                        <UpdatePersonalInfo personalInfo={formData} setPersonalInfo={setFormData} />
                    </div>
                }
            />
        </div>
    );
}


export default SettingsPage;

