import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import {
  useGetSearchResultMutation,
  useGetUserListQuery,
  useGetSearchByRoleAndTermResultMutation,
  useGetUsersByRoleQuery
} from "../../../redux/features/users/userApi";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
// import { generateTableRows } from './Functions'


const SelectPrimaryTeacher = ({ setTempTeacher, setTeacher, userTypesDatas }) => {

  // const { data, isError, isLoading, error } = useGetUsersByRoleQuery('teacher', { refetchOnMountOrArgChange: true });
  // useProtected(error);
  const [rows, setRows] = useState([]);
  const [tempArray, setTempArray] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTeacher, setSelectedTeacher] = useState(false);

  const [getSearchedUserListByRoleAndTerm, options] = useGetSearchByRoleAndTermResultMutation();

  const [totalItems, setTotalItems] = useState([]);

  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Full Name", width: "40%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "ID #", width: "25%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "User Type", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "", textAlign: "left", },
  ];

  const handleChangeRedio = (event, rowData) => {
    let data = JSON.parse(localStorage.getItem('teachersInfo'));
    let teachersInfoObject = JSON.parse(localStorage.getItem('teachersInfoObject'));
    let teacher = {}
    if (data.length > 0) {

      data.map((item, index) => {

        item.cell[0].selectedValue = (item.cell[0].RedioValue == event.target.value) ? true : false
        item.cell[0].handleChangeRedio = handleChangeRedio

        if (item.cell[0].RedioValue == event.target.value) {
          teacher = { name: item.cell[0].textBold, id: item.cell[0].RedioValue }
          let foundTeacher = teachersInfoObject.find(teacher => teacher._id === event.target.value)
          setTempTeacher(foundTeacher)
        }
      });
      setSelectedTeacher(event.target.value);
      // setTempTeacher(foundTeacher)
      setRows(data);

    }
    // props.onClickRadioIcon(event.target.value);
    //find the selected item from totalItems array
    // let selectedItem = totalItems.find(item => item.id == event.target.value);
    // props.onItemSelected( selectedItem );

  };


  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map(item => ({
        cell: [
          {
            textBold: (item.firstName) ? `${item.firstName} ${item.lastName}` : item.name,
            Radio: true,
            selectedValue: selectedTeacher,
            handleChangeRedio: handleChangeRedio,
            RedioValue: (item._id) ? item._id : item.RedioValue,
            radioName: "primary_teacher",
            Status: true,
            background: item.status === 'active' ? '#EDF7EA' : '#FCE7E9',
            color: item.status === 'active' ? '#66BD50' : '#E63946',
            label: item.status === 'active' ? 'Active' : 'Inactive',
          },
          //{ textBold: `${item.firstName} ${item.lastName}`, Check: true, Status: true, background: '#EDF7EA', color: '#66BD50', label: 'Inactive', },
          { textBold: item?.userUniqueID || "N/A" },
          { text: item?.userType || "N/A" },
          // { text: "Teacher" },
          { text: "" },
        ]
      }))
    }
    else {
      return [];
    }
  }

  // useEffect(() => {
  //   if (!isLoading && !isError) {
  //     setRows(convertDataToCellRows(data.body.data));
  //     setTempArray(data.body.data)
  //   }
  // }, [data, isError, isLoading])
  useEffect(() => {
    if (userTypesDatas) {
      setRows(convertDataToCellRows(userTypesDatas));
      setTempArray(userTypesDatas)
    }
  }, [userTypesDatas])



  useEffect(() => {
    localStorage.setItem('teachersInfo', JSON.stringify(rows));
  }, [rows])

  useEffect(() => {
    localStorage.setItem('teachersInfoObject', JSON.stringify(tempArray));
  }, [tempArray])

  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }

  const handleSearch = async () => {
    const result = await getSearchedUserListByRoleAndTerm({ role: 'teacher', term: searchTerm });
    console.log({ result })
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  }


  // Filter //
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };
  const handleCloseFilterDrawer = () => {
    setOpenFilter(false);
  };



  return (
    <>
      <div className="TableSection"><br />
        <Table
          tableHeader={true}
          headCells={headCells}
          rows={rows}
          onClick={handleFilterDrawerOpen}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          innerHeader={true}
          showSearchBox={true}
          searchBoxPlaceholder="Search Users"
          // isDataLoading={isLoading || options?.isLoading}
          isDataLoading={options?.isLoading}
          RowsPerPage={10}

        />
      </div>

    </>
  );
};

export default SelectPrimaryTeacher;
