import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useGetSearchResultMutation, useGetUserListQuery } from "../../../redux/features/users/userApi";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import AddStudents from './AddStudents';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DraggableDialog from '../../../components/Dialog/Dialog';
import CustomizedSnackbar from '../../../components/hooks/Snackbar';
import EditClassLevel from './UpdateClassesLevel';
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddClassStudentsMutation,
  useGetClassDetailsQuery,
  useGetClassStudentSearchResultMutation,
  useDeleteClassUsersMutation,
  useGetStudentBySchoolYearAndClassMutation,
  useEditClassLevelMutation,
  useDeleteClassLevelMutation
} from "../../../redux/features/classes/classApi";
import { toast } from "react-toastify";
import { useGetSchoolYearListQuery } from "../../../redux/features/school_years/schoolYearApi";
import { validateFormData } from "../../../utils/form";
import { toTitleCase } from "../../../utils/helpers";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";



const ClassesLevelDetails = () => {
  const { class_level_id } = useParams()
  const { data, isLoading, isError, error, refetch } = useGetClassDetailsQuery(class_level_id, {
    manual: true,
  });
  const [editClassLevel, editClassLevelOptions] = useEditClassLevelMutation()
  const [deleteClassLevel, deleteClassLevelOptions] = useDeleteClassLevelMutation()
  const [getSearchedUserList, options] = useGetClassStudentSearchResultMutation();
  const [addClassStudents, addClassStudentsOptions] = useAddClassStudentsMutation()
  const [deleteClassStudents, deleteClassStudentsOptions] = useDeleteClassUsersMutation()
  const [getStudentsByClassAndYear, getStudentBySchoolYearAndClassOptions] = useGetStudentBySchoolYearAndClassMutation()
  const {
    data: schoolYearData,
    isLoading: schoolYearIsLoading,
    isError: schoolYearIsError,
    error: schoolYearError
  } = useGetSchoolYearListQuery()



  // useProtected(error);
  useProtected(options.error);
  useProtected(schoolYearError);
  useProtected(editClassLevelOptions.error);
  useProtected(deleteClassLevelOptions.error);
  useProtected(addClassStudentsOptions.error);
  useProtected(deleteClassStudentsOptions.error);
  useProtected(getStudentBySchoolYearAndClassOptions.error);

  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [classStudents, setClassStudents] = useState([]);
  const [classDetailsInfo, setClassDetailsInfo] = useState({})
  const [schoolYearsInfo, setSchoolYearsInfo] = useState({})
  const [schoolYearsOptions, setSchoolYearOptions] = useState([])
  const [selectedToBlank, setSelectedToBlank] = useState(false)
  const [selectedActiveClassUsers, setSelectedActiveClassUsers] = useState([]);
  const [schoolYear, setSchoolYear] = useState('')
  const [name, setName] = useState('');
  const [studentsToThisClassLevel, setStudentsToThisClassLevel] = useState([])
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({
    name: ''
  });
  const [courseUsersToUpdate, setCourseUsersToUpdate] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      setClassDetailsInfo(data?.body?.data)
    }
  }, [data, isLoading, isError])

  useEffect(() => {
    if (!schoolYearIsLoading && !schoolYearIsError) {
      setSchoolYearsInfo(schoolYearData?.body?.data)
    }
  }, [schoolYearData, schoolYearIsLoading, schoolYearIsError])

  useEffect(() => {
    setName(classDetailsInfo?.name)
    setDescription(classDetailsInfo?.description)
  }, [classDetailsInfo])

  useEffect(() => {
    setSchoolYearOptions(prepareOptions(schoolYearsInfo))
    if (schoolYearsInfo?.length > 0) {
      setSchoolYear(schoolYearsInfo[0]?._id)
      getStudents({ classLevelId: class_level_id, schoolYearId: schoolYearsInfo[0]?._id })

    }

  }, [schoolYearsInfo])

  const navigate = useNavigate()

  const getStudents = async (data) => {
    let result = await getStudentsByClassAndYear(data)
    setRows(convertDataToCellRows(result?.data?.body?.data))
    setStudentsToThisClassLevel(result?.data?.body?.data)
  }

  const prepareOptions = (rows) => {
    if (rows?.length > 0) {
      return rows.map((single_row) => ({
        label: single_row.name,
        value: single_row._id
      }))

    }
    return []
  }

  // user: { name: `${item?.firstName} ${item?.lastName}`, id: item.userUniqueID, email: item?.email, status: item?.status }
  // user_id: item?.userUniqueID,

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr.map((item) => ({
        cell: [
          { Check: true, width: '36px', user_id: item.userUniqueID },
          { textBold: `${item.firstName} ${item.lastName}`, user_id: item.userUniqueID, },
          { text: item?.userUniqueID || "N/A" },
          { text: toTitleCase(item?.role || "N/A") },
          { text: item?.email || "N/A" },
          {
            Status: true,
            background: item.status === 'active' ? '#E1F2E8' : '#FCE7E9',
            color: item.status === 'active' ? '#0B9444' : '#E63946',
            label: item?.status ? toTitleCase(item?.status) : 'N/A',
          },
          {
            Button: true,
            buttonLabel: 'Details',
            buttonTextcolor: '#004FE0',
            buttonLink: `/users/${item.userUniqueID}`,
            buttonTarget: '_blank',
          },
        ]
      }));
    } else {
      return [];
    }

  };


  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", width: "30%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "ID", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "User Type", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Email", width: "30%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Status", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "right", },

  ];



  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setSearchTerm("")
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ schoolYearId: schoolYear, classLevelId: classDetailsInfo._id, term: searchTerm });
    if (result?.data) {
      setRows(convertDataToCellRows(result?.data?.body?.data))
      setSearchTerm("")
    }
    setPageNumberFromOutSide(false)
  }
  // =========== search part end ====================== //

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  // Filter //
  const [openCreateDrawer, setOpenCreate] = useState(false);
  const [openEditDrawer, setOpenEdit] = useState(false);
  const handleCreateDrawerOpen = () => {
    setOpenCreate(true);
  };
  const handleEditDrawerOpen = () => {
    setOpenEdit(true);
  };
  const handleCloseCreateDrawer = () => {
    setClassStudents([])
    setOpenCreate(false);
  };
  const handleCloseEditDrawer = () => {
    setOpenEdit(false);
  };



  const status = [
    { id: 1, label: "In Progress" },
    { id: 2, label: "Dropped" },
    { id: 3, label: "Blocked" },
    { id: 4, label: "Completed" },
    { id: 5, label: "Unavailable" },
  ];
  // Filter //

  const [age, setAge] = React.useState('');
  const SelectHandleChanges = (event) => {
    setAge(event.target.value);
  };

  const selectValue = [
    {
      label: 'QAFYS HIFDH 2023-2024',
      value: 'QAFYS HIFDH 2023-2024',
    },
  ];


  const breadcrumbsData = [
    {
      label: 'Class/Level',
      link: '/classes-level',
      icon: <PersonOutlineRoundedIcon fontSize="small" />,
    },
    {
      label: 'Details',
    },
  ];


  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    if (!isTheActionPermitted('deleteClassLevel')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  //======= Delete dialog ===== //

  //======= Remove dialog ===== //
  const [openDialogRemove, setOpenDialogRemove] = useState(false);
  const handleOpenRemove = () => {
    setOpenDialogRemove(true);
  };
  let selectfdhfedUserId = selectedActiveClassUsers.map((user) => user)
  // console.log(selectfdhfedUserId, selectedActiveClassUsers)

  ///================ delete part from table start ================================///
  const deleteUsersFromClass = async () => {
    setSelectedToBlank(true)
    let selectedUserId = selectedActiveClassUsers.map((user) => user)

    let data = {
      schoolYearId: schoolYear,
      classLevelId: classDetailsInfo._id,
      studentList: selectedUserId
    };
    const result = await deleteClassStudents(data);
    if (result?.data) {
      toast.success(selectedUserId.length > 1 ? 'Student removed' : 'Student removed');
      setOpenDialog(false)
    } else {
      toast.error(result?.data?.message);
    }
    setSelectedToBlank(false)
    setSelectedActiveClassUsers([])
    handleCloseRemoveDialog()
    getStudents({ classLevelId: class_level_id, schoolYearId: schoolYear })
  }
  ///================ delete part from table end ================================///
  const handleCloseRemoveDialog = () => {
    setOpenDialogRemove(false)
  };
  //======= Remove dialog ===== //

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const addStudentsToClass = async () => {
    let data = {
      classLevelId: class_level_id,
      // students: classStudents,
      students: courseUsersToUpdate

    };
    const result = await addClassStudents(data);
    if (result?.data) {
      toast.success((classStudents.length > 1) ? 'Students added to class successfully' : 'Student added to class successfully');
      handleCloseCreateDrawer()
      setClassStudents([])
      getStudents({ classLevelId: class_level_id, schoolYearId: schoolYear })
      setCourseUsersToUpdate([])
    } else {
      toast.error(result?.data?.message);
    }
  }

  //dev note for create Alert Message will be this (Class/Level Created)
  //dev note for delete Alert Message will be this (Class/Level deleted)
  //dev note for remove Alert Message will be this (Student removed)


  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setSelectedActiveClassUsers(prevStudents => [...prevStudents, item[0].user_id]);
    } else {
      // Remove the user from the array
      setSelectedActiveClassUsers(prevStudents => prevStudents.filter(user => user !== item[0].user_id));
    }
  }

  const setSchoolYearHandler = (e) => {
    setSchoolYear(e.target.value)
    getStudents({ classLevelId: class_level_id, schoolYearId: e.target.value })
  }

  const handleEditClassLevel = async () => {
    let classLevelData = {
      name,
      description
    }

    const isValid = validateFormData(classLevelData, setErrors, {
      name: 'Enter class/level name',
      // description: 'Enter description',
    });
    if (!isValid) {
      return;
    }

    let result = null;

    result = await editClassLevel({ ...classLevelData, id: class_level_id });

    if (result?.data) {
      toast.success("Class/Level Edited")
      setName('')
      setDescription('')
    } else {
      toast.error(result?.data?.message);
    }
    handleCloseEditDrawer()
  }
  const deleteClassHandler = async () => {
    let result = await deleteClassLevel(class_level_id)
    if (result?.data) {
      if (result?.data) {
        toast.success("Class/Level deleted")
        navigate(`/classes-level`)
      } else {
        toast.error(result?.data?.message);
      }
    }
  }

  return (
    <>
      <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />

      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Breadcrumbs breadcrumbsData={breadcrumbsData} link="/classes-level" />
        </Grid>

        <Grid item md={12} xs={12}>
          <div className="UserDetails">
            <li>
              <div className="UserDetailsleftcol">
                {/* <div className="UserDetailsrIcon">
              <Avatar
                sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
              ></Avatar>
            </div> */}

                <div className="UserDetailsDetail">
                  <h1>{classDetailsInfo?.name}</h1>
                  <p>{classDetailsInfo?.description}</p>
                </div>
              </div>

              <div className="UserListingAction">
                <div align="left">
                  <div className="EditUserProfile">
                    <div className="ActionButton">
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        ACTIONS <KeyboardArrowDownRoundedIcon />
                      </Button>
                    </div>

                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className="EditDropdown"
                    >
                      <span onClick={handleClose} className="MenuListContainer">
                        <MenuItem onClick={() => handleEditDrawerOpen(true)} sx={{ fontWeight: "600" }}>EDIT</MenuItem>
                      </span>

                      <span onClick={handleClose} className="MenuListContainer">
                        <MenuItem onClick={() => handleOpenDelete(true)} sx={{ fontWeight: "600" }}>DELETE</MenuItem>
                      </span>
                    </Menu>
                  </div>
                </div>
              </div>
            </li>
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <div className="TableSection">
            <Table
              title="Students"
              tableHeader={true}
              addLabel="Add Student"
              icon={<AddIcon />}
              AddButton={true}
              headCells={headCells}
              rows={rows}
              onClick={handleCreateDrawerOpen}
              footer={true}
              handleSearchText={handleSearchText}
              handleSearch={handleSearch}
              setResetSearchTerm={setResetSearchTerm}
              setSearchTerm={setSearchTerm}
              resetsearchTerm={resetsearchTerm}
              pageNumberFromOutSide={pageNumberFromOutSide}
              innerHeader={true}
              showSearchBox={true}
              searchBoxPlaceholder="Search students"
              isDataLoading={isLoading || schoolYearIsLoading || getStudentBySchoolYearAndClassOptions?.isLoading || options?.isLoading}
              FilterButtonDrawer={false}
              //onClickFilterButton={}
              setSelectedToBlank={selectedToBlank}
              HeaderSelectField={true}
              HeaderSelectFieldOptions={schoolYearsOptions}
              HeaderSelectSelectedOption={schoolYear}
              HeaderSelecthandleChanges={(e) => { setSchoolYearHandler(e) }}
              handleRowClick={handleRowClick}
              handleCheckItem={setSelectedActiveClassUsers}
              selectedCheckList={selectedActiveClassUsers}
              customCellObjectId="user_id"
              CheckboxDeleteModal={true}
              CheckboxDelete={true}
              DeleteText={selectedActiveClassUsers.length > 1 ? `REMOVE STUDENTS` : `REMOVE STUDENT`}
              DeleteButtonOnclick={handleOpenRemove}
              emptyTableTitle="No students added yet"
              emptyTableNote="Students of this class/level will be visible here"
              btnLabel="Add Student"
              showButton={true}
              onClickEmptyCard={handleCreateDrawerOpen}
            />
          </div>
        </Grid>
      </Grid>



      {/* Filter */}
      <SideDrawer
        open={openCreateDrawer}
        handleDrawerClose={handleCloseCreateDrawer}
        title="Add Students"
        buttonLabel="Add students"
        sideOpen="right"
        ButtonLabel={classStudents?.length > 1 ? `Add ${classStudents?.length} students` : `Add ${classStudents?.length} student`}
        // ButtonLabel={courseUsersToUpdate?.length > 1 ? `Add ${courseUsersToUpdate?.length} students` : `Add ${courseUsersToUpdate?.length} student`}
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        padding="0"
        // clickSaveButton={(e) => (courseUsersToUpdate?.length > 0) && addStudentsToClass()}
        clickSaveButton={(e) => (classStudents?.length > 0) && addStudentsToClass()}
        body={
          <>
            <AddStudents
              classStudents={classStudents}
              setClassStudents={setClassStudents}
              addClassStudentsOptions={addClassStudentsOptions}
              classDetailsInfo={classDetailsInfo}
              schoolYear={schoolYear}
              setCourseUsersToUpdate={setCourseUsersToUpdate}
              courseUsersToUpdate={courseUsersToUpdate}
              studentsToThisClassLevel={studentsToThisClassLevel} />
          </>
        }
      />

      <SideDrawer
        open={openEditDrawer}
        handleDrawerClose={handleCloseEditDrawer}
        title="Edit Class/Level"
        buttonLabel="Update"
        sideOpen="right"
        ButtonLabel="Update"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={(e) => handleEditClassLevel()}
        body={
          <>
            <EditClassLevel
              name={name}
              setName={setName}
              description={description}
              setDescription={setDescription}
              errors={errors}
              setErrors={setErrors} />
          </>
        }
      />
      {/* <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={deleteClassHandler}
      /> */}

      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={(e) => deleteClassHandler()}
        title="Delete class/level?"
        body={<div>Are you sure you want to delete this class/level?<br /> Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        actionButton="DELETE"
        cancelButton="CANCEL"
      />

      <DraggableDialog
        openDialog={openDialogRemove}
        handleCloseDialog={handleCloseRemoveDialog}
        handleConfirmCloseDialog={(e) => deleteUsersFromClass()}
        title="Remove student from class/level?"
        body="Are you sure you want to this user from the group?"
        ModalFooter={true}
        actionButton="Remove"
      />

    </>
  );
};

export default ClassesLevelDetails;
