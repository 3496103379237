import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import Crown from '../../../assets/imgs/vip-crown-fill.svg';
import { toTitleCase } from '../../../utils/helpers';
import AddUsers from './AddUsers';



import {
  useGetGroupDetailsQuery,
  useAddGroupUsersMutation,
  useDeleteGroupUsersMutation,
  useDeleteGroupMutation,
  useGetUserSearchInGroupResultMutation,
  useLazyGetGroupDetailsQuery
} from '../../../redux/features/groups/groupsApi';


const UsersRole = (props) => {
  const {
    roleUsers,
    setRoleUsers,
    setRoleUsersToUpdate,
    roleUsersToUpdate,
    rolesPermissionsObject,
    setRolesPermissionsObject,
    roleUsersToRemove,
    setRoleUsersToRemove
  } = props

  const onClick = () => {
    alert('onclick working fine!');
  };

  useEffect(() => {
    setRows(convertDataToCellRows(roleUsersToUpdate))
  }, [roleUsersToUpdate])

  const removeUserHandler = (id) => {
    let newUsersToShow = roleUsersToUpdate.filter(user => user.id != id)
    let newUsersToSelected = roleUsers.filter(user_unique_id => user_unique_id != id)
    let foundUser = roleUsersToUpdate?.find(user => user.id == id)
    let foundUserInRemoved = roleUsersToRemove?.find(user => user.id == id)
    setRoleUsers([...newUsersToSelected])
    setRoleUsersToUpdate([...newUsersToShow])

    let newUsersToRemove = [...roleUsersToRemove]
    if (!foundUserInRemoved) {
      newUsersToRemove.push(foundUser)
    }
    setRoleUsersToRemove(newUsersToRemove)

    setRolesPermissionsObject({ ...rolesPermissionsObject, removedUsers: newUsersToRemove })
  }

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr.map((item) => ({
        cell: [
          { textBold: `${item?.firstName} ${item?.lastName}`, },
          { text: item?.userUniqueID || "N/A" },
          { text: item?.role ? toTitleCase(item?.role) : "N/A" },
          {
            Button: true,
            buttonLabel: 'Remove',
            buttonTextcolor: '#004FE0',
            // buttonLink: '#',
            // buttonTarget: '_blank',
            padding: "8px",
            align: "right",
            onClick: (e) => removeUserHandler(item?.id)
          },
        ]
      }));
    } else {
      return [];
    }
  }

  const headCells = [
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name', width: '40%', textAlign: 'left', },
    { id: 'ID', numeric: false, disablePadding: true, label: 'ID', width: '20%', textAlign: 'left', },
    { id: 'userType', numeric: false, disablePadding: true, label: 'User Type', width: '20%', textAlign: 'left', },
    { id: 'Users', numeric: false, disablePadding: true, label: '', width: '20%', textAlign: 'right', },

  ];

  const [rows, setRows] = useState([]);
  const [OpenRole, setOpenRole] = useState(false);
  const handleDrawerOpenRole = () => {
    setOpenRole(true);
  };
  const handleDrawerCloseRole = () => {
    setOpenRole(false);
  };




  //===  Add user == // 

  const [OpenAddUsers, setAddUsers] = useState(false);
  const handleOpenAddUsers = () => setAddUsers(true);
  const handleCloseAddUsers = () => {
    setAddUsers(false)
    setGroupUsers([])
  };

  const [addGroupUsers, addGroupUsersOptions] = useAddGroupUsersMutation();
  const [getSearchedUserList, options] = useGetUserSearchInGroupResultMutation();
  const [deleteGroupUsers, deleteGroupUsersOptions] = useDeleteGroupUsersMutation();




  const [groupUsers, setGroupUsers] = useState([]);
  const [groupUsersToUpdate, setGroupUsersToUpdate] = useState([]);
  const [selectedActiveGroupUsers, setSelectedActiveGroupUsers] = useState([]);
  const [selectedToBlank, setSelectedToBlank] = useState(false)

  const addUsersToGroupHandler = () => {
    handleDrawerCloseRole()


    let newUsersToRemove = [...roleUsersToRemove]

    // Create a set of unique IDs from array2
    const idSet = new Set(roleUsersToUpdate.map(obj => obj.id));

    // Filter array1 to exclude objects with duplicate IDs
    const filteredArray1 = newUsersToRemove.filter(obj => !idSet.has(obj.id));

    setRoleUsersToRemove(filteredArray1)

    setRolesPermissionsObject({ ...rolesPermissionsObject, users: roleUsersToUpdate, removedUsers: filteredArray1 })
  }


  // useEffect(() => {
  //   console.log(roleUsersToUpdate, 328, roleUsers,groupUsersToUpdate)
  // }, [roleUsersToUpdate, roleUsers,groupUsersToUpdate])
  return (
    <>
      <div className="UserRoll">
        <Table
          title={`${rows?.length} users selected`}
          tableHeader={true}
          addLabel="Add users"
          AddButton={true}
          icon={<AddRoundedIcon />}
          headCells={headCells}
          rows={rows}
          onClick={handleDrawerOpenRole}
          footer={roleUsersToUpdate.length === 0 ? false : true}
          RowsPerPage={10}

          emptyTableTitle="No user added yet"
          emptyTableNote="Added users will be visible here"
          btnLabel="Add users"
          showButton={true}
          onClickEmptyCard={handleDrawerOpenRole}
        />
      </div>

      {/* Create Role drawer */}
      <SideDrawer
        open={OpenRole}
        handleDrawerClose={handleDrawerCloseRole}
        title="Add Users"
        sideOpen="right"
        ButtonLabel="Select 5 users"
        cancelButtonLabel="Back"
        FooterWithoutTab={true}
        padding="0"
        ButtonLabel={`Add ${roleUsers?.length || 0} user${roleUsers?.length > 1 ? 's' : ''}`}
        // ButtonLabel={`Add ${roleUsers?.length || 0} users`}
        clickSaveButton={(e) => addUsersToGroupHandler()}
        body={
          <>
            <AddUsers
              groupUsers={roleUsers}
              setGroupUsers={setRoleUsers}
              addGroupUsersOptions={addGroupUsersOptions}
              setGroupUsersToUpdate={setRoleUsersToUpdate}
              groupUsersToUpdate={roleUsersToUpdate}
            />
          </>
        }
      />
    </>
  );
};

export default UsersRole;
