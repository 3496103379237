import React, { useEffect, useState, useCallback } from "react";
import "../../../assets/css/users.css";
import VerticalTab from "../../../components/VerticalTab/VerticalTab";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Button from "@mui/material/Button";
import HomeIcon from '@material-ui/icons/Home'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { toTitleCase } from "../../../utils/helpers/index"

import Overview from "./Overview";
import Applications from "./Applications";
import { RxPerson } from "react-icons/rx";
import { RxCalendar } from "react-icons/rx";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { useParams } from "react-router-dom";
import { useGetUserDetailsByUniqueIdQuery, useGetUserDetailsQuery } from "../../../redux/features/users/userApi";
import { useGetApplicationListByUserIdQuery, useLazyGetApplicationListByUserIdQuery } from "../../../redux/features/applications/applicationApi";
import useProtected from "../../../hooks/useProtected";

import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import GuardiansParents from './GuardiansParents';
import GuardianStudents from './GuardianStudents';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import UsersProfileEdit from "./UsersProfileEdit";
import { toast } from "react-toastify";
import { useUpdateUserMutation } from "../../../redux/features/users/userApi";
import { filterTruthyValues, mapMonth } from "../../../utils/helpers";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/features/authSlice";


import AddRoundedIcon from '@mui/icons-material/AddRounded';

import Buttons from '../../../components/Form/Button/Button';
import MediaLibrarySelectMedia from "../../../components/MediaLibrary/MediaLibrarySelectMedia";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';

import Modals from '../../../components/Form/Modals/Modals';
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';


import img from '../../../assets/imgs/randomPerson.avif';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';


import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";

import { BiSolidIdCard } from 'react-icons/bi';

import Identification from './Identification';
import { useFindGurdianQuery } from "../../../redux/features/users/guardianApi";






const UsersDetailsUnique = (props) => {
    const dispatch = useDispatch();
    const { unique_id } = useParams();

    const [updateUser, updateUserOptions] = useUpdateUserMutation();
    const [personalInfo, setPersonalInfo] = useState([]);
    const [editProfile, setEditProfile] = useState(false);

    const { data, isLoading, isError, error, refetch } = useGetUserDetailsByUniqueIdQuery(unique_id, {
        refetchOnMountOrArgChange: true,
    });



    useEffect(() => {
        if (!isLoading && data && !error) {
            setPersonalInfo(data?.body?.data)
            getApplicationList(data?.body?.data?._id)
        }
    }, [data, error, isLoading])





    const [getApplicationList, {
        data: applicationListResponse,
        isLoading: applicationResponseLoading,
        isError: applicationResponseIsError,
        error: applicationResponseError,
    }] = useLazyGetApplicationListByUserIdQuery(unique_id, {
        refetchOnMountOrArgChange: true,
    });

    useProtected(error);
    useProtected(applicationResponseError);
    const [currentTab, setCurrentTab] = useState(0);
    const [userDetails, setUserDetails] = useState(null);
    const [applicationList, setApplicationList] = useState([]);
    const [userEditDrawer, setUserEditDrawer] = useState(false);
    const [formData, setFormData] = useState(null)
    const [openSideDrawer, setOpenSideDrawer] = useState(false);
    const [birthDate, setBirthDate] = useState(null);
    const [birthMonth, setBirthMonth] = useState(null);
    const [birthYear, setBirthYear] = useState(null);
    const handleTabSwitch = (activeTab) => {
        console.log(activeTab);
        setCurrentTab(activeTab);
    };

    useEffect(() => {
        if (!isLoading && !isError) {
            setUserDetails(data?.body?.data);
        }
    }, [data, isLoading, isError]);

    useEffect(() => {
        if (!applicationResponseLoading && !applicationResponseIsError) {
            setApplicationList(applicationListResponse?.body?.data);
        }
    }, [
        applicationListResponse,
        applicationResponseLoading,
        applicationResponseIsError,
    ]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    useEffect(() => {
        console.log(birthYear, birthMonth, birthDate)
        setPersonalInfo({ ...personalInfo, [birthDate]: new Date(birthYear, birthMonth - 1, birthDate) })
    }, [birthYear, birthMonth, birthDate])

    const handleUpdateProfile = async (profileInfo) => {
        const data = {
            firstName: profileInfo?.firstName,
            middleName: profileInfo?.middleName,
            lastName: profileInfo?.lastName,
            gender: profileInfo?.gender,
            profilePic: profileInfo?.profilePic,
            nysedId: profileInfo?.nysedId,
            country: profileInfo?.country,
            address: profileInfo?.address,
            highestEducation: profileInfo?.highestEducation,
            occupation: profileInfo?.occupation,
            phone: profileInfo?.phone,
            birthDate: new Date(birthYear, birthMonth - 1, birthDate),
            idPicture: profileInfo?.idPicture,
            idPictureName: profileInfo?.idPictureName,
            // birthDate: (profileInfo?.birthMonth && profileInfo?.birthYear) ? new Date(profileInfo?.birthYear, typeof profileInfo?.birthMonth === 'string' ? mapMonth(profileInfo?.birthMonth) : profileInfo?.birthMonth, 1) : profileInfo?.birthDate,
            timezones: profileInfo?.timezones
        }
        const result = await updateUser({ unique_id, ...data });

        if (!result?.data?.error) {
            drawerClose();
            // setFormData(personalInfo);
            const response = await refetch();
            if (!response?.data?.error) {
                const data = { ...response.data.body.data, birthMonth: response.data.body?.birthDate ? moment(response.data.body.birthDate).month() : "", birthYear: response.data.body?.birthDate ? moment(response.data.body.birthDate).year() : "" }
                setFormData(data);
            }
        } else {
            toast.error(result.data.message)
        }
    };

    const drawerClose = () => {
        setEditProfile(false)
        setOpenSideDrawer(false)
    }
    const handleEditProfile = () => {
        setEditProfile(true);
    }
    useEffect(() => {
        // const data = { ...personalInfo, birthMonth: personalInfo?.birthDate ? moment(personalInfo.birthDate).month() : "", birthYear: personalInfo?.birthDate ? moment(personalInfo.birthDate).year() : "" }
        // setFormData(data)

        const dateObj = (personalInfo?.birthDate) ? new Date(personalInfo?.birthDate) : new Date();

        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1; // Adding 1 since getMonth() returns 0-based index
        const day = dateObj.getDate();

        if (!birthDate && personalInfo?.birthDate) setBirthDate(day)
        if (!birthMonth && personalInfo?.birthDate) setBirthMonth(month)
        if (!birthYear && personalInfo?.birthDate) setBirthYear(year)


        // let temp = {...personalInfo}
        // temp.birthMonth = month
        // temp.birthYear = year
        // temp.birthDate = day
        // setPersonalInfo()
    }, [personalInfo])

    const breadcrumbsData = [
        {
            label: "Users",
            link: "/users",
            icon: <PersonOutlineRoundedIcon fontSize="small" />,
        },

        {
            label: "Details",
            link: "#",
        },

    ];

    // <Breadcrumbs
    //   breadcrumbsData={breadcrumbsData}
    // />



    // const [dynamicTabBody, setDynamicTabBody] = useState([])

    const tabAndBodyTogether = [
        {
            icon: <PermIdentityOutlinedIcon />,
            label: "Overview",
            panel: <Overview userDetails={userDetails} isLoading={isLoading} />
        },

        {
            icon: <BiSolidIdCard />,
            label: "Identification",
            panel: <Identification
                handleUpdateProfile={handleUpdateProfile}
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                personalInfo={personalInfo}
                id={unique_id}
                isLoading={isLoading}
                refetch={refetch}
            />
        },
        {
            icon: <PeopleAltRoundedIcon />,
            label: "Guardians/Parents",
            panel: <GuardiansParents
                userUniqueID={userDetails?.userUniqueID}
                userId={userDetails?._id}
                guardianList={userDetails?.gurdians || []} />
        },
        {
            icon: <PeopleAltRoundedIcon />,
            label: "Students",
            panel: <GuardianStudents
                userUniqueID={userDetails?.userUniqueID}
                userId={userDetails?._id}
                guardianList={userDetails?.gurdians || []} />
        }

    ]
    const [tabData, setTabdata] = React.useState([]);


    const [tabBody, setTabBody] = useState([])

    useEffect(() => {
        let tempTabData = []
        let tempTabBody = []
        let i = 0
        for (i; i < tabAndBodyTogether.length; i++) {
            if (userDetails?.role === "student" && tabAndBodyTogether[i].label === "Students") {
                continue
            }
            if (userDetails?.role === "parent" && tabAndBodyTogether[i].label === "Guardians/Parents") {
                continue
            }
            if (userDetails?.role === "parent" && tabAndBodyTogether[i].label === "Identification") {
                continue
            }

            tempTabData.push({
                icon: tabAndBodyTogether[i].icon,
                label: tabAndBodyTogether[i].label
            })
            tempTabBody.push({
                id: tempTabData.length - 1,
                panel: tabAndBodyTogether[i].panel
            })
        }
        setTabdata(tempTabData)
        setTabBody(tempTabBody)

    }, [userDetails, setTabdata, setTabBody])

    return (
        <>
            <Breadcrumbs
                breadcrumbsData={breadcrumbsData}
                link="/users"
            />
            <div className="UserDetails">

                <li>
                    <div className="UserDetailsleftcol">

                        <div className="UserDetailsrIcon" >
                            <Avatar
                                src={userDetails?.profilePic}
                                sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                            //onClick={() => { setShowProfilePhotoSelectionModal(true) }}
                            >{(!userDetails?.profilePic || userDetails?.profilePic == '') && userDetails?.firstName?.charAt(0).toUpperCase()}</Avatar>



                            {/* <div className="UploadedCameIcon" onClick={() => { setShowProfilePhotoSelectionModal(true) }}>
								<div className="alfa"></div>
								<AddAPhotoRoundedIcon />
							</div> */}
                        </div>

                        <div className="UserDetailsDetail">
                            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <span
                                // style={{ display: "inline-block" }}
                                >
                                    <h1>
                                        <b>{userDetails?.firstName} {userDetails?.lastName}</b>
                                    </h1>
                                </span>{" "}
                                <span
                                    // style={{ display: "inline-block", }}
                                    className="status rounded success"
                                >
                                    {userDetails?.status ? userDetails.status.toUpperCase() : "N/A"}
                                </span>
                            </span>
                            <p>
                                {userDetails?.role ? toTitleCase(userDetails.role.toUpperCase()) : "N/A"} |
                                ID: {userDetails?.userUniqueID}
                            </p>
                        </div>
                    </div>

                    <div className="UserListingAction">
                        <div align="left">
                            <div className="EditUserProfile">
                                <div className="ActionButton">
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? "true" : undefined}
                                        onClick={handleClick}
                                    >
                                        Actions <KeyboardArrowDownRoundedIcon />
                                    </Button>
                                </div>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",

                                    }}
                                    className="EditDropdown"
                                >
                                    <span onClick={handleClose} className="MenuListContainer">
                                        <MenuItem onClick={() => handleEditProfile(true)}>EDIT</MenuItem>
                                    </span>
                                    <span onClick={handleClose} className="MenuListContainer">
                                        <MenuItem onClick={() => handleEditProfile(true)}>RESTORE ACCESS</MenuItem>
                                    </span>
                                    {/* <span onClick={handleClose}>
                    				<MenuItem onClick={handleClose}>Revoke Access</MenuItem>
                  					</span> */}
                                    {/* <MenuItem onClick={handleClose}>Delete Customer</MenuItem> */}
                                </Menu>
                            </div>
                        </div>
                    </div>
                </li>

                <VerticalTab
                    tabData={tabData}
                    tabBody={tabBody}
                    onTabSwitch={handleTabSwitch}
                />
            </div>
            <SideDrawer
                title="Edit User"
                cancelButtonLabel="CANCEL"
                deleteText="Delete Permanently"
                sideOpen="right"
                open={openSideDrawer || editProfile}
                handleDrawerClose={drawerClose}
                ButtonLeft="Upload New"
                FooterWithoutTab={true}
                ButtonLabel="SAVE"
                clickSaveButton={() => handleUpdateProfile(personalInfo)}
                body={
                    <div>
                        <UsersProfileEdit personalInfo={personalInfo} setPersonalInfo={setPersonalInfo}
                            birthDate={birthDate}
                            setBirthDate={setBirthDate}
                            birthMonth={birthMonth}
                            setBirthMonth={setBirthMonth}
                            birthYear={birthYear}
                            setBirthYear={setBirthYear} />
                    </div>
                }
            />
        </>
    );
};

export default UsersDetailsUnique;
