import React, { useState } from 'react'
import CourseAttendanceTable from "../../../components/Table/CourseAttendanceTable";
import TableSubHeader from '../../../components/Table/TableSubHeader';
import TableHeader from '../../../components/Table/TableHeader';
import { useEffect } from 'react';
import dayjs from 'dayjs';









const CourseAttendance = (props) => {
    const {
        section_id,
        course_id,
        courseDetails
    } = props
    const [selectedDate, setSelectedDate] = useState('')
    const [selectedDateRaw, setSelectedDateRaw] = useState(dayjs())
    const [filterDate, setFilterDate] = useState(dayjs());

    const onDateChangeHandler = (e) => {
        setSelectedDateRaw(e)
    }
    const goToPreviousDate = (e) => {
        setSelectedDateRaw(selectedDateRaw.subtract(1, 'day'))
    }

    const goToNextDate = (e) => {
        setSelectedDateRaw(selectedDateRaw.add(1, 'day'))
    }

    useEffect(() => {
        if (selectedDateRaw != "") {
            const formattedDate = selectedDateRaw.format('YYYY-MM-DD');
            setSelectedDate(formattedDate);
        } else {
            let today = dayjs()
            const formattedDate = today.format('YYYY-MM-DD');
            setSelectedDate(formattedDate);
        }
    }, [selectedDateRaw])

    return (
        <div>
            <TableHeader
                TableTitle="Attendance"
            />
            <TableSubHeader
                DateField={true}
                Filter={true}
                // FilterOnClick={FilterOnClick}
                Export={true}
                //ExportOnClick={ExportOnClick}
                handleDateFilterChange={(e) => onDateChangeHandler(e)}
                goToPreviousDate={(e) => goToPreviousDate(e)}
                goToNextDate={(e) => goToNextDate(e)}
                dateFilter={selectedDateRaw}
            // handleDateFilterChange={(date) => setFilterDate(date)}
            // goToPreviousDate={(e) => setFilterDate(filterDate.subtract(1, 'day'))}
            // goToNextDate={(e) => setFilterDate(filterDate.add(1, 'day'))}
            />
            <CourseAttendanceTable
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                section_id={section_id}
                course_id={course_id}
                courseDetails={courseDetails}
            />
        </div>
    )
}

export default CourseAttendance;