import { api } from '../../api/apiSlice';

const settingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSettingList: builder.query({
      query: () => ({
        url: '/settings/all',
      }),
      providesTags: ['Settings'],
    }),
    createSetting: builder.mutation({
      query: (data) => ({
        url: '/settings/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Settings', 'SettingId'],
    }),
    getSettingsByGroup: builder.query({
      query: (group) => ({
        url: `/settings/getByGroup/${group}`,
      }),
      providesTags: ['Settings'],
    }),
    getSettingById: builder.query({
      query: (id) => ({
        url: `/settings/getSingle/${id}`,
      }),
      providesTags: ['Settings'],
    }),
    updateSetting: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/settings/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Settings'],
    }),
    deleteSetting: builder.mutation({
      query: (id) => ({
        url: `/settingss/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Courses'],
    })
  }),
});

export const {
  useCreateSettingMutation,
  useUpdateSettingMutation,
  useDeleteSettingMutation,
  useGetSettingListQuery,
  useGetSettingsByGroupQuery,
  useGetSettingByIdQuery,
} = settingApi;
