import { api } from '../../api/apiSlice';

const attendanceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllAttendance: builder.query({
      query: () => ({
        url: `attendance/all`,
      }),
      providesTags: ['Attendances'],
    }),
    addAttendance: builder.mutation({
      query: (data) => ({
        url: `/attendance/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Attendance', 'Attendances'],
    }),
    updateAttendance: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/attendance/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Attendance', 'AttendanceDetails'],
    }),
    getAttendanceByDate: builder.query({
      query: (date) => ({
        url: `/attendance/getAttendanceByDate/${date}`,
      }),
      providesTags: ['Attendance'],
    }),
    getAttendanceDetails: builder.query({
      query: (id) => ({
        url: `/attendance/getSingle/${id}`,
      }),
      providesTags: ['AttendanceDetails']
    }),
    getAttendanceBySearch: builder.mutation({
      query: (data) => ({
        url: `/attendance/search`,
        method: 'POST',
        body: data,
      }),
    }),
    getAttendanceByFilter: builder.mutation({
      query: (data) => ({
        url: `/attendance/searchAttendanceHistory`,
        method: 'POST',
        body: data,
      }),
    }),
    getAttendanceByDateRange: builder.mutation({
      query: (data) => ({
        url: `/attendance/getAttendanceByDateRange`,
        method: 'POST',
        body: data,
      }),
    }),
    getAllAttendanceCodes: builder.query({
      query: () => ({
        url: `/attendance-code/getAll`,
      }),
      providesTags: ['AttendanceCode'],
    }),
    getAttendanceCodeById: builder.query({
      query: (id) => ({
        url: `/attendance-code/getSingle/${id}`,
      }),
    }),
    addAttendanceCode: builder.mutation({
      query: (data) => ({
        url: `/attendance-code/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AttendanceCode'],
    }),
    checkAttendanceCode: builder.mutation({
      query: (data) => ({
        url: `/attendance-code/checkCode`,
        method: 'POST',
        body: data,
      })
    }),
    updateAttendanceCode: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/attendance-code/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['AttendanceCode'],
    }),
    deleteAttendanceCode: builder.mutation({
      query: (id) => ({
        url: `/attendance-code/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AttendanceCode'],
    }),
    exportAttendanceReport: builder.mutation({
      query: (data) => ({
        url: `/attendance/exportAttendance`,
        method: 'POST',
        body: data,
      })
    }),
    addClockInTime: builder.mutation({
      query: (data) => ({
        url: `/attendance/addClockInTime`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AttendanceDetails'],
    }),
    addClockOutTime: builder.mutation({
      query: (data) => ({
        url: `/attendance/addClockOutTime`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AttendanceDetails'],
    }),
    uploadAbsentReasonAttachment: builder.mutation({
      query: (data) => ({
        url: `/attendance/uploadAbsentReasonAttachment`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AttendanceDetails'],
    }),
    deleteAbsentReasonAttachment: builder.mutation({
      query: (data) => ({
        url: `/attendance/deleteAbsentReasonAttachment`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AttendanceDetails'],
    })

  }),
});

export const {
  useGetAllAttendanceQuery,
  useAddAttendanceMutation,
  useUpdateAttendanceMutation,
  useGetAttendanceByDateQuery,
  useGetAttendanceDetailsQuery,
  useGetAttendanceBySearchMutation,
  useGetAttendanceByFilterMutation,
  useGetAttendanceByDateRangeMutation,
  useGetAllAttendanceCodesQuery,
  useGetAttendanceCodeByIdQuery,
  useAddAttendanceCodeMutation,
  useUpdateAttendanceCodeMutation,
  useExportAttendanceReportMutation,
  useAddClockInTimeMutation,
  useAddClockOutTimeMutation,
  useDeleteAttendanceCodeMutation,
  useCheckAttendanceCodeMutation,
  useUploadAbsentReasonAttachmentMutation,
  useDeleteAbsentReasonAttachmentMutation
} = attendanceApi;
