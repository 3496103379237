import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Tables from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import SelectFieldMulticolor from '../../../components/Form/SelectFieldMulticolor/SelectFieldMulticolor';
import { capitalizeFirstLetter } from "../../../components/hooks/HelperFunctions";
import { useReturnAccessMutation, useRevokeAccessMutation } from "../../../redux/features/users/userApi";
import { toast } from "react-toastify";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import { useFetcher } from "react-router-dom";
import moment from "moment-timezone";
import { capitalizeWords } from "../../../hooks/HelperFunctions";


const StudentDetailsTable = ({ _id, userUniqueID, status, nysedId, gender, academicStartDate, academicEndDate, startedAcademicYear, endedAcademicYear }) => {

  const [changeStatus, changeStatusOptions] = useRevokeAccessMutation()
  const [returnAccess, returnAccessOptions] = useReturnAccessMutation()
  const [getStatusData, setGetStatusData] = useState('')

  useProtected(changeStatusOptions.error);
  useProtected(returnAccessOptions.error);
  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: "#FCFCFC",
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========//
  useEffect(() => {
    if (status) {
      setGetStatusData(status)
    }
  }, [status])

  const Statustdata = [
    { value: 1, statuscolor: "#66BD50", select: "Active" },
    { value: 5, statuscolor: "#C44058", select: "Inactive" },
  ];

  const handleChange = (event) => {
    let data = event.target.value
    // setChangeStatusData(data)
    if (data == 5) {
      changeData()
      const result = getDataStatus2(data)
      setGetStatusData(result?.select)
      return result?.value
    }
    if (data == 1) {
      returnAccessHandler()
      const result = getDataStatus2(data)
      setGetStatusData(result?.select)
      return result?.value
    }
    // console.log(status, 388, data)
  };

  const changeData = async () => {
    let result = await changeStatus(_id)
    if (result?.data) {
      // toast.success('User access revoked');
    } else {
      toast.error(result?.data?.message);
    }
  }

  const returnAccessHandler = async () => {
    let result = await returnAccess(_id)
    if (result?.data) {
      // toast.success('User access restored');
    } else {
      toast.error(result?.data?.message);
    }

  }



  //default value
  const getDataStatus = (status) => {
    let data = Statustdata?.find((statusData) => statusData?.select === (status))
    return data?.value
  }

  //default value
  const getDataStatus2 = (valueData) => {
    let data = Statustdata?.find((statusData) => statusData?.value === valueData)
    return data
  }

  const getFullYear = () => {
    const startAcademicYear = new Date(academicStartDate)
    const startedYear = startAcademicYear.getFullYear();

    const endAcademicYear = new Date(academicEndDate)
    const endedYear = endAcademicYear.getFullYear();

    return `${startedYear} - ${endedYear}`;
  }
  const parseISOString = (isoString) => {
    const parts = isoString.split(/[-T:.Z]/);
    parts[1]--; // Adjust month (months are 0-indexed in JavaScript dates)
    return new Date(Date.UTC(...parts));
  };

  const formatAcademicYear = () => {
    const startDate = new Date(startedAcademicYear);
    const endDate = new Date(endedAcademicYear);
    // console.log(moment(startedAcademicYear).month(), moment(startedAcademicYear).year(), moment(startedAcademicYear).date(), 91)

    const startFormatted = startDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    const endFormatted = endDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    if (startFormatted === "Invalid Date" || endFormatted === "Invalid Date") {
      return `Not Found`;
    } else {
      return `${startFormatted} - ${endFormatted}`;
    }

  };


  return (
    <>

      <div className="BoxTable">
        <div className="TableContainer oddEvenTable">
          <div className="TableInnerHeader">
            <h3>Student Details</h3>
          </div>
          <TableContainer component={Paper}>
            <Tables aria-label="customized table" >
              <TableBody>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">Status</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <SelectFieldMulticolor
                      labelTop={false}
                      labelinsideStatus={true}
                      statustData={Statustdata}
                      defaultValue={getDataStatus(capitalizeWords(getStatusData))}
                      handleChange={(e) => handleChange(e)}
                      width="192px"
                      height="28px"
                      Status={true}
                    />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">ID</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">{userUniqueID ? userUniqueID : <span style={{ color: '#919094' }}>No data available</span>}</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">NYSED ID #</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">{nysedId ? nysedId : <span style={{ color: '#919094' }}>No data available</span>}</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">Academic Year</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">{getFullYear()}</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">Class/Level</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo"><a href="#"> 3, Alim</a></StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">Started academic year</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">{formatAcademicYear()}</StyledTableCell>
                  {/* <StyledTableCell align="left" className="TableInfo">{startedAcademicYear}Sep 2023 - Jun 2024{endedAcademicYear}</StyledTableCell> */}
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">Gender</StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">{gender ? gender : <span style={{ color: '#919094' }}>No data available</span>}</StyledTableCell>
                </StyledTableRow>


              </TableBody>
            </Tables>
          </TableContainer>
        </div>
      </div>



    </>
  );
};

export default StudentDetailsTable;


