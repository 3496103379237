import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import {
  useGetSearchResultMutation,
  useGetUserListQuery,
} from '../../../redux/features/users/userApi';
import { useEffect } from 'react';
import useProtected from '../../../hooks/useProtected';
import Filter from '../../filter/Filter';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import AddIcon from '@mui/icons-material/Add';
import { toTitleCase } from '../../../utils/helpers';
import { useGetRoleListQuery } from '../../../redux/features/roles/roleApi';
import { useGetMessageCategoriesQuery } from '../../../redux/features/messages/meassageApi';
import { isTheActionPermitted } from '../../../hooks/HelperFunctionsPermission';
import { toast } from 'react-toastify';
import { useGetUserTypesQuery } from '../../../redux/features/user_types/userTypesApi';

const UsersListing = () => {
  const { data, isError, isLoading, error, refetch } = useGetUserListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: messageCate } = useGetMessageCategoriesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: roleData, isError: roleIsError, isLoading: roleIsLoading, error: roleError } = useGetUserTypesQuery(undefined, {
    refetchOnMountOrArgChange: true
  })

  const [getSearchedUserList, options] = useGetSearchResultMutation();

  useProtected(error);
  useProtected(roleIsError);

  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([])
  const [streams, setStream] = useState([])
  const [endDate, setEndDate] = useState('')
  const [statuses, setStatuses] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [startDate, setStartDate] = useState('')
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchResult, setIsSearchResult] = useState(false)

  const headCells = [
    {

      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '30%',
      textAlign: 'left',
    },
    {
      id: 'ID',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '18%',
      textAlign: 'left',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: true,
      label: 'User type',
      width: '18%',
      textAlign: 'left',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      width: '25%',
      textAlign: 'left',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      width: '20%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '20%',
      textAlign: 'left',

    },
  ];



  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({

        cell: [
          {
            linkText: `${item.firstName} ${item.lastName}`,
            linkTextStatus: true,
            link: `/users/${item?._id}`,
          },
          { text: item?.userUniqueID || 'N/A' },
          { text: item?.userType ? toTitleCase(item?.userType) : 'N/A' },
          { text: item?.email || 'N/A' },
          {
            Status: true,
            background: item.status === 'active' ? '#E1F2E8' : '#FCE7E9',
            color: item.status === 'active' ? '#0B9444' : '#E63946',
            label: item?.status ? toTitleCase(item?.status) : 'N/A',
          },
          {
            Button: true,
            buttonLabel: 'Details',
            buttonTextcolor: '#004FE0',
            buttonBorder: '#C6C6D0 1px solid',
            buttonLink: `/users/${item._id}`,
          },
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    console.log(statuses, streams, userTypes)

  }, [statuses, streams, userTypes])

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
    }
    setIsSearchResult(false)
  }, [data]);


  useEffect(() => {
    if (!roleIsLoading && !roleIsError) {
      setRoles(roleData?.body?.data);
    }
  }, [roleData, roleIsLoading, roleIsError]);

  // const rows = [

  //   {
  //     cell: [
  //       { linkText: "Shayan Mahmud ", linkTextStatus:true, link:"#" ,},
  //       { text: "000022",},
  //       { text: "Student",},
  //       { text: "shayanmahmud@gmail.com",},
  //       { Status: true,background: "#E1F2E8", color: "#0B9444",label: "Active",},
  //       {
  //         Button: true, buttonLabel: "Details",
  //         buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget:"_blank",
  //       },
  //     ],
  //   },

  //   {
  //     cell: [
  //       { linkText: "Shayan Mahmud ", linkTextStatus:true, link:"#" ,},
  //       { text: "000022",},
  //       { text: "Student",},
  //       { text: "shayanmahmud@gmail.com",},
  //       { Status: true, background: "#FCE7E9", color: "#E63946", label: "Inactive", },
  //       { Button: true, buttonLabel: "Details",buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget:"_blank",},
  //     ],
  //   },

  // ];

  const tabData = [
    {
      title: 'Users',
    },
    {
      title: 'Visitors (comin soon)',
    },
  ];

  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: "" });
    searchDataShowing(result)
  };

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: searchTerm });
    searchDataShowing(result)
  };

  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  // =========== search part start ====================== //
  // Filter //
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0];

  const oneMonthsAgo = new Date(currentDate);
  oneMonthsAgo.setMonth(currentDate.getMonth() - 1);
  const formattedOneMonthAgoStartDate = oneMonthsAgo.toISOString().split('T')[0]

  const [openFilterDrawer, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
    setStartDate((formattedOneMonthAgoStartDate))
    setEndDate(new Date(formattedDate))
  };
  const handleCloseFilterDrawer = async () => {
    setOpenFilter(false);
  };

  const handleCancelFilterDrawer = async () => {
    setOpenFilter(false);
    setStatuses([])
    setStream([])
    setUserTypes([])
    setStartDate('')
    setEndDate('')
    const result = await getSearchedUserList({});
    console.log({ result });
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
    console.log('arif : from close drawer');
  }

  const status = [
    { id: 1, label: 'In Progress' },
    { id: 2, label: 'Dropped' },
    { id: 3, label: 'Blocked' },
    { id: 4, label: 'Completed' },
    { id: 5, label: 'Unavailable' },
  ];



  const filterHandler = async () => {

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];


    let startDateToSubmit = startDate
    let endDateToSubmit = endDate

    if (startDateToSubmit === "") startDateToSubmit = formattedDate
    if (endDateToSubmit === "") endDateToSubmit = formattedDate

    let startDateNew = new Date(startDateToSubmit)
    let endDateNew = new Date(endDateToSubmit)

    if (startDateNew > endDateNew) {
      startDateToSubmit = endDateNew.toISOString().split('T')[0]
    } else {
      startDateToSubmit = startDateNew.toISOString().split('T')[0]
    }
    endDateToSubmit = endDateNew.toISOString().split('T')[0]

    setStartDate(startDateToSubmit)
    setEndDate(endDateToSubmit)

    let dataToSubmit = {
      joinStartDateRange: startDateToSubmit,
      joinEndDateRange: endDateToSubmit,
      status: statuses,
      stream: streams,
      userType: userTypes,
    }

    const result = await getSearchedUserList(dataToSubmit);
    console.log({ result });
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
    // handleCloseFilterDrawer()
    setOpenFilter(false);
    // setStatuses([])
    // setStream([])
    // setUserTypes([])
    setStartDate(new Date(formattedOneMonthAgoStartDate))
    setEndDate(new Date(formattedDate))
    setIsSearchResult(false)

  }
  const inviteUserButtonHandler = (e) => {
    if (!isTheActionPermitted('inviteUsers')) {
      toast.error("You don't have access to this feature")
      return false
    }
    return true;
  }
  return (
    <>
      <div className="TableSection">
        <Table
          title="Users "
          tableHeader={true}
          addLabel="Invite User"
          //icon={<AddIcon />}
          AddButton={true}
          headCells={headCells}
          rows={rows}
          filterCountStatus={(statuses?.length + streams?.length + userTypes?.length) > 0}
          filterCountNumber={(statuses?.length + streams?.length + userTypes?.length)}
          //onClick={handleFilterDrawerOpen}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          pageNumberFromOutSide={pageNumberFromOutSide}
          foundResult={rows.length}
          showFoundResult={true}
          isSearchResult={isSearchResult}
          SearchBoxTop={true}
          searchBoxPlaceholder="Search users"
          link={isTheActionPermitted('inviteUsers') ? '/invite-users' : false}
          onClick={(e) => inviteUserButtonHandler(e)}
          isDataLoading={isLoading || options?.isLoading}
          FilterButtonTop={true}
          onClickFilterButton={handleFilterDrawerOpen}
          RowsPerPage={20}

          emptyTableTitle="No users added yet"
          emptyTableNote="No items available"
          btnLabel="ADD USERS"
          showButton={true}
          btnLink="/invite-users"
        />
      </div>

      {/* Filter */}
      <SideDrawer
        open={openFilterDrawer}
        handleDrawerClose={handleCloseFilterDrawer}
        handleDrawerCloseButtonBottom={handleCancelFilterDrawer}
        title="Filter"
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel="Apply"
        cancelButtonLabel="Clear Filters"
        FooterWithoutTab={true}
        clickSaveButton={(e) => filterHandler()}

        body={
          <>
            <Filter label="Status" checkboxesData={status}
              statuses={statuses}
              setStatuses={setStatuses}
              streams={streams}
              setStream={setStream}
              userTypes={userTypes}
              setUserTypes={setUserTypes}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              roles={roles} />
          </>
        }
      />

    </>
  );
};

export default UsersListing;
