import React, { useState, useCallback } from 'react';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '../../../components/Form/TextField/TextField'
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import Buttons from '../../../components/Form/Button/Button';
import MediaLibrarySelectMedia from '../../../components/MediaLibrary/MediaLibrarySelectMedia';
import Externaluse from '../../../components/MediaLibrary/Externaluse';
import ComboBox from '../../../components/Form/ComboBox/ComboBox';
import { getDataMap, getDates, getDates2, getMonths, getYears } from "../../../hooks/HelperFunctions";
import { getProfileAvatarFromName, mapMonth, monthArray, daysArray } from "../../../utils/helpers";
import { countries } from "../../../assets/data/countries";
import PhoneInputField from '../../../components/Form/PhoneInputField/PhoneInputField';
import GooglePlaceField from '../../../components/Form/GooglePlaceField/GooglePlaceField';
import { useMemo } from 'react';
import moment from 'moment-timezone';
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import axios from 'axios';
import config from "../../../config";

import Cropper from 'react-easy-crop';
import getCroppedImg from "../../../utils/extra";

import WebcamComponent from "../../../components/Webcam/WebcamComponent";

import { useParams } from "react-router-dom";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const UsersProfileEdit = (props) => {

    const { id } = useParams();

    const { personalInfo, setPersonalInfo, gradeLevels, ageGroups, timeZones, birthDate,
        setBirthDate,
        birthMonth,
        setBirthMonth,
        birthYear,
        setBirthYear, errors } = props;

    const [selectedValue, setSelectedValue] = React.useState(false);
    const [openSelectMedia, setOpenSelectMedia] = useState(false);
    const [openNewMedia, setOpenNewMedia] = useState(false);
    const [mediaItem, setMediaItem] = useState({});
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);


    const getMediaItem = (item) => {
        // setting the media item
        setMediaItem(item);
    };

    const handleMediaUploadClose = () => {
        setOpenNewMedia(false);
    };

    const handleOpenNewMedia = (status) => {
        setOpenNewMedia(!status);
        handleDrawerOpenSelectMedia(status);
    };

    const handleDrawerOpenSelectMedia = () => {
        setOpenSelectMedia(true);
    };

    const handleDrawerOpenNewMedia = () => {
        console.log("handleDrawerOpenNewMedia");
        setOpenNewMedia(true);
    };

    const handleChanges = (fieldName) => (event) => {
        const fieldData = event.target.value;
        console.log(fieldName, fieldData);
        setPersonalInfo({ ...personalInfo, [fieldName]: fieldData });
        // setDisableSaveBtn(false);
    };

    const clickSelectMediaItem = () => {
        //check if any item is selected
        if (selectedValue) {
            if (mediaItem?.mimeType?.startsWith("image/")) {
                let temp = { ...personalInfo };
                temp.profilePic = mediaItem.url;
                temp.imageName = mediaItem.name;
                setPersonalInfo(temp);
                setOpenSelectMedia(false)
            } else {
                toast.error("Only image file can be accepeted")
            }
        } else {
            alert("Please select any media");
        }
    };


    const handleChangeWithData = (fieldName, fieldData) => {
        console.log(fieldName, fieldData);
        setPersonalInfo({ ...personalInfo, [fieldName]: fieldData });
        // setDisableSaveBtn(false);
    };

    //handleDrawerCloseSelectMedia
    const handleDrawerCloseSelectMedia = () => {
        setOpenSelectMedia(false);
    };

    const highestEducation = [
        { label: 'None', value: 'None' },
        { label: 'Less than high school diploma (or equivalent)', value: 'Less than high school diploma (or equivalent)' },
        { label: 'High school diploma (or equivalent)', value: 'High school diploma (or equivalent)' },
        { label: 'Some college but no degree', value: 'Some college but no degree' },
        { label: 'Associate Degree (e.g., AA, AS)', value: 'Associate Degree (e.g., AA, AS)' },
        { label: "Bachelor's degree (e.g., BA, AB, BS)", value: "Bachelor's degree (e.g., BA, AB, BS)" },
        { label: "Master's degree (e.g., MA, MS, MEng, MEd, MSW, MBA)", value: "Master's degree (e.g., MA, MS, MEng, MEd, MSW, MBA)" },
        { label: "Professional school degree (e.g., MD, DDS, DVM, LLB, JD0", value: "Professional school degree (e.g., MD, DDS, DVM, LLB, JD0" },
        { label: "Doctorate degree (e.g., PhD, EdD)", value: "Doctorate degree (e.g., PhD, EdD)" },
    ]
    useMemo(() => {
        const names = moment.tz.names();
        let offsetTmz = [];

        for (let i in names) {
            offsetTmz = [...offsetTmz, `${names[i]} (GMT${moment.tz(names[i]).format('Z')})`]
        }
        console.log({ offsetTmz })
        setTimeZoneOptions(offsetTmz.map(item => ({ label: item, value: item })))
    }, [])

    const checkJsonStringOrNot = (address) => {

        if (typeof address === 'string') {

            if (address.length == 0) {
                return "";
            }

            if (/^[\],:{}\s]*$/.test(address.replace(/\\["\\\/bfnrtu]/g, '@').
                replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

                return JSON.parse(address);

            } else {

                return {};

            }
        } else {
            return address;
        }

    }


    const [imageFromWebcam, setImageFromWebcam] = useState(null);
    const [imageFromCropper, setImageFromCropper] = useState(null);
    const [imageFromCropperSrc, setImageFromCropperSrc] = useState(null);
    const [imageFromCropperName, setImageFromCropperName] = useState("");


    const [showEditPhotoModal, setShowEditPhotoModal] = useState(false);
    const [showTakePhotoModal, setShowTakePhotoModal] = useState(false);


    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState()
    const [cropSize, setCropSize] = useState({ width: 0, height: 0 })
    const [mediaSize, setMediaSize] = useState({
        width: 0,
        height: 0,
        naturalWidth: 0,
        naturalHeight: 0,
    })
    const onCropComplete = useCallback(
        (croppedArea, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels)
        }, [])

    const setCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageFromWebcam,
                croppedAreaPixels,
                0
            );
            setImageFromWebcam(null);

            setImageFromCropper(croppedImage);
            setImageFromCropperSrc(URL.createObjectURL(croppedImage));

            const currentDate = new Date();
            const datestamp = `${(currentDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}${currentDate
                    .getDate()
                    .toString()
                    .padStart(2, "0")}${currentDate.getFullYear()}`;

            const firstName = personalInfo?.firstName.toLowerCase();

            const lastName = personalInfo?.lastName.toLowerCase();

            const fileName = `${personalInfo?.userUniqueID}-${firstName}-${lastName}-${datestamp}.jpeg`

            setImageFromCropperName(fileName);



            const formData = new FormData();

            formData.append('file', croppedImage, fileName);

            const apiURL = `${config.api_url}/files/uploadProfileImage/${id}`;

            console.log(apiURL);

            axios.post(apiURL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {

                    if (response?.data?.body?.data?.cdnUrl) {
                        if (imageFromCropperSrc) {
                            URL.revokeObjectURL(imageFromCropperSrc)
                            setImageFromCropper(null);
                            setImageFromCropperSrc(null);
                            setImageFromCropperName("");
                        }

                        let temp = { ...personalInfo };
                        temp.profilePic = mediaItem.url;
                        temp.imageName = mediaItem.name;

                        setPersonalInfo(temp);
                    }
                })
                .catch((error) => {
                    console.error('Error uploading image:', error);

                });


            setCrop({ x: 0, y: 0 });
            setZoom(1);



        } catch (e) {
            console.error(e)
        }
    })

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const handleOnClickTakePhoto = () => {
        setShowTakePhotoModal(true);
    }

    // dates
    const [dateOptions, setDateOptions] = useState([]);
    useEffect(() => {
        let dates = [];
        if (birthMonth !== undefined && birthYear !== undefined) {
            let daysInMonth;
            switch (birthMonth) {
                case 1:
                case 3:
                case 5:
                case 7:
                case 8:
                case 10:
                case 12:
                    daysInMonth = 31;
                    break;
                case 4:
                case 6:
                case 9:
                case 11:
                    daysInMonth = 30;
                    break;
                case 2:
                    if ((birthYear % 4 === 0 && birthYear % 100 !== 0) || birthYear % 400 === 0) {
                        daysInMonth = 29;
                    } else {
                        daysInMonth = 28;
                    }
                    break;
                default:
                    daysInMonth = 0; // Invalid month
                    break;
            }

            if (daysInMonth > 0) {
                for (let i = 1; i <= daysInMonth; i++) {
                    dates.push({ value: i, label: i });
                }
            }
        }
        // console.log(dates, 893547685)

        // console.log(54645, birthMonth, birthYear, dates)
        setDateOptions(dates)
    }, [birthMonth, birthYear]);
    useEffect(() => {
        setBirthDate(birthDate);
        // console.log(birthDate)
    }, [birthDate, birthMonth, birthYear]);






    return (
        <>

            {/* <p><b>ID : {id}</b></p> */}

            <Modal
                disableEnforceFocus
                open={showTakePhotoModal}
                onClose={() => { setShowTakePhotoModal(false) }}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 600 }}>
                    <WebcamComponent width={500} height={400}
                        setImageSrc={setImageFromWebcam}
                        onCaptured={() => {
                            setShowEditPhotoModal(true);
                        }}
                    >

                    </WebcamComponent>

                    {imageFromWebcam != null ?

                        <Modal
                            disableEnforceFocus
                            open={imageFromWebcam != null && showEditPhotoModal}
                            onClose={() => { setShowEditPhotoModal(false) }}
                            aria-labelledby="child-modal1-title"
                            aria-describedby="child-modal1-description"
                        >
                            <Box sx={{ ...style, width: 600 }}>
                                <div
                                    style={{ "position": "relative", width: 500, height: 400 }}
                                >
                                    <Cropper
                                        image={imageFromWebcam}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1 / 1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                        setMediaSize={setMediaSize}
                                        setCropSize={setCropSize}
                                    />
                                </div>

                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() => {
                                        setShowEditPhotoModal(false);
                                        setShowTakePhotoModal(false);
                                        setCroppedImage();
                                    }}
                                >
                                    Upload
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() => {
                                        setShowEditPhotoModal(false);
                                        setShowTakePhotoModal(true);
                                        setImageFromWebcam(null);
                                    }}
                                >
                                    Retake
                                </Button>

                            </Box>
                        </Modal>

                        : <></>}


                </Box>
            </Modal>


            <div className="ModalForm">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="First name"
                            required={true}
                            helperText={errors?.firstName ? errors?.firstName : ''}
                            error={errors?.firstName ? errors?.firstName : ''}
                            value={personalInfo?.firstName}
                            handleChange={(val, e) => handleChanges("firstName")(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Middle name"
                            required={false}
                            value={personalInfo?.middleName}
                            handleChange={(val, e) => handleChanges("middleName")(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Last name"
                            required={true}
                            helperText={errors?.lastName ? errors?.lastName : ''}
                            error={errors?.lastName ? errors?.lastName : ''}
                            value={personalInfo?.lastName}
                            handleChange={(val, e) => handleChanges("lastName")(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <div class="FieldLabel">Profile photo</div>

                        <div className="UploadPhotoCard">

                            <div className="UploadPhotoCardImg">
                                <Avatar
                                    sx={{ bgcolor: "deepPurple[300]", width: 96, height: 96 }}
                                    src={personalInfo?.profilePic ? personalInfo?.profilePic : ""}
                                >{getProfileAvatarFromName(personalInfo)}</Avatar>
                            </div>

                            {personalInfo?.profilePic && (
                                <div className="UploadPhotoFiled Edituser">
                                    <div className="UploadPhotoImgCol">
                                        <span><img src={personalInfo?.profilePic ? personalInfo?.profilePic : ""} /></span>
                                        <div className="ImgDetails">Profile Image</div>
                                    </div>
                                    <a className="UploadPhotoFiledDelete">
                                        <DeleteForeverRoundedIcon onClick={() => {
                                            let temp = { ...personalInfo };
                                            temp.profilePic = "";
                                            temp.imageName = "";
                                            setPersonalInfo(temp);
                                        }} />
                                    </a>
                                </div>
                            )}


                            {!personalInfo?.profilePic && (

                                <div className="UplaodPhotoModal">
                                    <div>
                                        <Buttons
                                            label="Add from media library"
                                            buttonColor="transparent"
                                            border="#E4E8EC 1px solid"
                                            icon={<AddRoundedIcon />}
                                            color="#004FE0"
                                            width="100%"
                                            height="70px"
                                            fontSize="14px"
                                            link=""
                                            onClick={() => handleDrawerOpenSelectMedia()}
                                        />

                                        <SideDrawer
                                            title="Media Library"
                                            ButtonLabel="Add"
                                            //clickSaveButton={clickSaveButton}
                                            cancelButtonLabel="Cancel"
                                            sideOpen="right"
                                            open={openSelectMedia}
                                            handleDrawerClose={handleDrawerCloseSelectMedia}
                                            FooterWithoutTab={true}
                                            LeftButton={true}
                                            ButtonLeft="Upload New"
                                            ButtonLeftIcon={<FileUploadOutlinedIcon />}
                                            clickButtonLeft={handleDrawerOpenNewMedia}
                                            clickSaveButton={clickSelectMediaItem}
                                            padding="0"
                                            body={
                                                <MediaLibrarySelectMedia
                                                    onClickRadioIcon={setSelectedValue}
                                                    onItemSelected={getMediaItem}
                                                />
                                            }
                                        />

                                        <Externaluse
                                            open={openNewMedia}
                                            handleCloseButton={handleMediaUploadClose}
                                            handleOpenNewMedia={(handleOpenNewMedia)}
                                        />

                                    </div>
                                </div>
                            )}





                        </div>







                        <div className="UplaodPhotoModal">
                            {/* <FileUplaoder
                                        filePath={`students/profile-images/`}
                                        uploadButton="Upload Photo"
                                        // file={personalInfo.image}
                                        fileType="image"
                                        onUpload={(filePath) => handleChangeWithData("image", filePath)}
                                        setSelectedFile={fileSelectedHandler}
                                        helpertext="Maximum size of 10 MB. JPG, JPEG, or PNG."
                                        showUploadButton={false}
                                        showThumb={true}
                                /> */}
                            {/* <Buttons
                                        label="Add From Media Library"
                                        buttonColor="transparent"
                                        border="#E4E8EC 1px solid"
                                        // icon={<AddRoundedIcon />}
                                        color="#004FE0"
                                        width="100%"
                                        height="70px"
                                        fontSize="14px"
                                        link=""
                                        onClick={() => handleDrawerOpenSelectMedia()}
                                /> */}

                        </div>

                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={6}>
                        <div class="FieldLabel"></div>
                        <Buttons
                            label="Take Photo"
                            buttonColor="transparent"
                            border="#E4E8EC 1px solid"
                            icon={<PhotoCameraRoundedIcon />}
                            //textTransform="initial"
                            color="#004FE0"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            link=""
                            onClick={() => handleOnClickTakePhoto()}
                        />
                    </Grid> */}


                    {(personalInfo?.userType === 'student') &&
                        <Grid item xs={12}>
                            <TextField
                                label="NYSED ID #"
                                required={false}
                                value={personalInfo?.nysedId}
                                handleChange={(val, e) => handleChanges("nysedId")(e)}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12}>
                        <div className="RedioButton">
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label" className="FieldLabel">Gender</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={personalInfo?.gender}
                                    onChange={handleChanges("gender")}
                                >
                                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="Female" control={<Radio />} label="Female" />

                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {/* <SelectField
                                label="Birthday"
                                validation={false}
                                placeholder="Month"
                                options={getMonths()}
                                selectedOption={personalInfo.birthMonth}
                                handleChange={handleChanges("birthMonth")}
                        /> */}

                        <ComboBox
                            label="Birthday"
                            placeholder="Month"
                            validation={false}
                            options={getMonths()}
                            selectedOption={birthMonth && birthMonth - 1}
                            handleChange={(fieldData) => setBirthMonth(fieldData + 1)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>

                        {/* <ComboBox
                            label=""
                            placeholder="Day"
                            validation={false}
                            options={getDates()}
                            selectedOption={birthDate && birthDate}
                            handleChange={(fieldData) => setBirthDate(fieldData)}
                        /> */}
                        {/* <p>BirthDate:  {birthDate}</p> */}
                        <ComboBox
                            label=""
                            placeholder="Day"
                            validation={false}
                            options={dateOptions}
                            selectedOption={birthDate && birthDate}
                            handleChange={(fieldData) => setBirthDate(fieldData)}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {/* <SelectField
                                placeholder="Year"
                                value={personalInfo.birthYear}
                                handleChange={handleChanges("birthYear")}
                                options={getYears()}
                                selectedOption={personalInfo.birthYear? personalInfo.birthYear : "2007"}
                        /> */}
                        <ComboBox
                            placeholder="Year"
                            validation={false}
                            options={getYears()}
                            selectedOption={birthYear}
                            handleChange={(fieldData) => setBirthYear(fieldData)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Email address"
                            required={false}
                            value={personalInfo?.email}
                            handleChange={(val, e) => console.log('not editable')}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <PhoneInputField
                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                            country={'us'}
                            value={personalInfo?.phone}
                            countryCodeEditable={false}
                            onChange={(value, country, e, formattedValue) => handleChangeWithData("phone", value)}
                            onBlur={() => console.log("onBlur")}
                            // onKeyDown={handleChanges("phone")}
                            variant="outlined"
                            onlyCountries={["us", "ca", "bd"]}
                            containerStyle={{}}
                            buttonStyle={{}}
                            inputStyle={{
                                marginLeft: "0px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                            }}
                            containerClass={{}}
                            errorText={"Phone number is required"}
                            errorStyles={{ marginBottom: "5px" }}
                            autoFormat={true}
                            disableDropdown={false}
                            disabled={false}
                            disableCountryCode={false}
                            enableSearch={false}
                            disableSearchIcon={false}
                            placeholder={""}
                            inputProps={{}}
                            specialLabel={'Primary Phone'}
                            // preferredCountries={[]}
                            // excludeCountries={[]}
                            labelFixed="Phone number"
                            LabelStatus={true}
                        //helpertext="Helper text"
                        //error="error"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <ComboBox
                            label="Select country"
                            validation={false}
                            options={countries}
                            selectedOption={personalInfo?.country}
                            handleChange={(fieldData) => handleChangeWithData("country", fieldData)}
                            placeholder="Select Country"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <div className='GoogleField'>
                            <GooglePlaceField
                                label="Address"
                                validation={false}
                                placeholder="Current resident address"
                                value={checkJsonStringOrNot(personalInfo?.address)}
                                handleChange={(address) => handleChangeWithData("address", address)}
                            />
                        </div>

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Apt/Fl/Suite no."
                            required={false}
                            value={personalInfo?.addressLine2}
                            handleChange={(val, e) => handleChanges("addressLine2")(e)}

                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label="City/Town"
                            required={false}
                            value={personalInfo?.city}
                            handleChange={(val, e) => handleChanges("city")(e)}

                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label="State/Province"
                            required={false}
                            value={personalInfo?.state}
                            handleChange={(val, e) => handleChanges("state")(e)}

                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Zip/Postal code"
                            required={false}
                            value={personalInfo?.zip}
                            handleChange={(val, e) => handleChanges("zip")(e)}

                        />
                    </Grid>




                    {(personalInfo.userType !== 'student') && <Grid item xs={12} sm={12} md={12}>
                        <ComboBox
                            label="Highest education"
                            validation={false}
                            options={highestEducation}
                            selectedOption={personalInfo?.highestEducation}
                            handleChange={(fieldData) => handleChangeWithData("highestEducation", fieldData)}

                        />
                    </Grid>}
                    {(personalInfo.userType !== 'student') && <Grid item xs={12} sm={12} md={12}>
                        <div className='UperCase'>
                            <TextField
                                label="Occupation"
                                required={false}
                                value={personalInfo?.occupation}
                                handleChange={(val, e) => handleChanges("occupation")(e)}

                            />
                        </div>
                    </Grid>}

                </Grid>
            </div >
        </>
    )
}

export default UsersProfileEdit;
