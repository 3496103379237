import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";



const GuardiansParents = ({ gurdians }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(convertDataToCellRows(gurdians));
  }, [gurdians])


  const headCells = [
    { id: 'Month', numeric: false, disablePadding: true, label: 'Name', width: '40%', },
    { id: 'Present', numeric: false, disablePadding: true, label: 'ID', width: '15%', },
    { id: 'Absent', numeric: false, disablePadding: true, label: 'Relationship', width: '15%', },
    { id: 'Late', numeric: false, disablePadding: true, label: 'PREFERRED CONTACT', width: '15%', },
    { id: 'Late', numeric: false, disablePadding: true, label: 'Status', width: '15%', textAlign: "left", },
  ];

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr.map(item => ({
        cell: [
          { textBold: item?.name },
          { text: item?.userUniqueID },
          { textBold: item?.relationShip },
          { textBold: item?.email },
          { Status: true, background: '#EDF7EA', color: '#66BD50', label: item?.status, statusLine: true, linebackground: (item?.status !== 'active') ? '#BA1A1A' : '#41AB37', },
        ]
      }))
    }
    else {
      return [];
    }
  }

  // const rows = [
  //   {
  //     cell: [
  //       { textBold: 'Shayan Mahmud ' },
  //       { text: '0131124' },
  //       { textBold: 'Father' },
  //       { textBold: 'shayanmahmud@gmail.com' },
  //       { Status: true, background: '#EDF7EA', color: '#66BD50', label: 'Active', },
  //     ]
  //   },

  //   {
  //     cell: [
  //       { textBold: 'Shayan Mahmud ' },
  //       { text: '0131124' },
  //       { textBold: 'Father' },
  //       { textBold: 'shayanmahmud@gmail.com' },
  //       { Status: true, background: '#FCE7E9', color: '#E63946', label: 'Inactive' },
  //     ]
  //   },

  //   {
  //     cell: [
  //       { textBold: 'Shayan Mahmud ' },
  //       { text: '0131124' },
  //       { textBold: 'Father' },
  //       { textBold: 'shayanmahmud@gmail.com' },
  //       { Status: true, background: '#EDF7EA', color: '#66BD50', label: 'Active', },
  //     ]
  //   },

  //   {
  //     cell: [
  //       { textBold: 'Shayan Mahmud ' },
  //       { text: '0131124' },
  //       { textBold: 'Father' },
  //       { textBold: 'shayanmahmud@gmail.com' },
  //       { Status: true, background: '#FCE7E9', color: '#E63946', label: 'Inactive' },
  //     ]
  //   },
  // ];



  return (
    <>

      <div className="TableSection StudentAdtnTable">
        <Table
          innerTitle="Guardians/Parents"
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          RowsPerPage={10}

          emptyTableTitle="No Monthly attendance added yet"
          emptyTableNote="No items available"
          btnLabel="New Course"
          showButton={true}



        />
      </div>


    </>
  );
};

export default GuardiansParents;


