import React, { useEffect, useState } from 'react';
import '../../../assets/css/TermsForm.css';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SelectField from '../../../components/Form/SelectField/SelectField';
import TextArea from '../../../components/Form/TextArea/TextArea';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import SelectTemplate from './SelectTemplate';

import AddCategories from './AddCategories';
import { toast } from 'react-toastify';


const SMSDuplicate = (props) => {
    const {
        messageDetails,
        messageObject,
        setMessageObject,
        errors
    } = props
    const [openTemplate, setTemplate] = useState(false);
    const handleOpenTemplate = () => setTemplate(true);
    const handleCloseTemplate = () => setTemplate(false);
    const [tempTemplate, setTempTemplate] = useState({})



    const tabData = [
        {
            lebel: "Select individuals",
            //icon: <InsertDriveFileIcon />,
        },

        {
            lebel: "Select groups",
            //icon: <YouTubeIcon />,
        },
    ]



    const panelTab = [
        {
            id: 0,
            panleBody: (
                <></>

            ),

            SaveButtonLabel: 'Send',
            //clickSaveButton: handleAddGuardian,
            CancelButtonLabel: 'Cancel',
        },

        {
            id: 1,
            panleBody: (
                <></>
            ),
            SaveButtonLabel: 'Send Invitation',
            //clickSaveButton: handleInviteUsers,
            CancelButtonLabel: 'Cancel',
        },
    ];




    const backgroundInCount = {
        backgroundColor: '#f2f0f4',
    };



    const [age, setAge] = React.useState('');
    const handleChanges = (event) => {
        setAge(event.target.value);
    };

    const to = [
        {
            label: "Category",
            value: "Category",
        },

        {
            label: "Category",
            value: "Category",
        },

    ]

    const from = [
        {
            label: "Quran Academy for Young Scholars",
            value: "Quran Academy for Young Scholars",
        },

        {
            label: "Quran Academy for Young Scholars",
            value: "Quran Academy for Young Scholars",
        },

    ]


    const [openCategory, setOpenCategory] = useState(false);
    const handleOpenCategory = () => { setOpenCategory(true); };

    const handleCloseCategory = () => {
        setOpenCategory(false)
    };



    const [isActive, setActive] = useState("false");



    const handleCopyCode = () => {
        if (messageDetails?.messageSmsContent) {
            navigator.clipboard.writeText(messageDetails?.messageSmsContent)
                .then(() => {
                    toast.success('Copied');
                })
                .catch((error) => {
                    console.error('Error copying to clipboard: ', error);
                    toast.error('Failed to copy code');
                });
        } else {
            toast.warning('No code to copy');
        }
    };



    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                    <TextArea
                        label="Message"
                        FieldLabel={true}
                        value={messageDetails?.messageSmsContent}
                        disabled
                        helperText={
                            <div className='TextpastSection'>
                                <a className={messageDetails?.messageSmsContent || messageObject?.messageSmsContent?.length > 0 ? `P_Active` : `P_Defaulf`} onClick={handleCopyCode}>Copy message</a>
                            </div>
                        }

                    />
                </Grid>
            </Grid>



        </>
    );
};

export default SMSDuplicate;
