import React, { useEffect, useState } from 'react';

import Table from '../../../components/Table/Table';
import tableImg from '../../../assets/imgs/tableImg.png';


const IdPhotoHistory = ({ setGroupUsers, groupUsers, addGroupUsersOptions, setGroupUsersToUpdate }) => {

  const headCells = [
    { id: 'Photo', numeric: false, disablePadding: true, label: 'Photo', width: '35%', textAlign: 'left', },
    { id: 'uploaded', numeric: false, disablePadding: true, label: 'Uploaded by', width: '25%', textAlign: 'left', },
    { id: 'date', numeric: false, disablePadding: true, label: 'Uploaded date', width: '33%', textAlign: 'left', },
    { id: 'Photo', numeric: false, disablePadding: true, label: '', width: '10%', textAlign: 'left', },

  ];


  const rows = [
    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: '0111050-shayan-mahmud-07202023', },
        { date: 'Shayan Mahmud' },
        { date: '12-Aug-2023, 10:00 AM' },
        { Button: true, buttonLabel: 'Download', buttonTextcolor: '#004FE0', buttonLink: '#', buttonTarget: '_blank', align: 'right' },
      ],

    },
  ];


  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          innerHeader={true}
          RowsPerPage={10}
        />
      </div>
    </>
  );
};

export default IdPhotoHistory;
