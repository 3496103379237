import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import SelectField from '../../../components/Form/SelectField/SelectField';
import { format } from 'crypto-js';
import { useGetUserRolesQuery } from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { useGetAllAttendanceCodesQuery } from '../../../redux/features/attendence/attendanceApi';
import { useGetClassLevelListQuery } from '../../../redux/features/classes/classApi';
import Buttons from '../../../components/Form/Button/Button';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';




const ExportOptions = (props) => {
  const {
    userTypes,
    attendance,
    classLevel,
    userTypesExportEditOption,
    setUserTypesExportEditOption,
    attendenceTypesExportEditOption,
    setAttendenceTypesExportEditOption,
    gradeTypesExportEditOption,
    setGradeTypesExportEditOption,
    userTypesEditExport,
    attendenceTypesEditExport,
    gradeTypesEditExport,
    userTypesEditHandler,
    attendanceTypesEditHandler,
    gradeTypesEditHandler,
    handleConfirm,
    handleClose
  } = props

  const [state, setState] = React.useState({
    checked: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };




  return (
    <div className="ExportOptions">
      <Grid container spacing={3}>

        <Grid item md={12} xs={12}>
          <div className="FieldLabel">User type</div>
          <div className='CheckBoxGrouopContaienr'>
            {userTypes.map((item, i) => (
              <CheckBox
                label={item.label}
                checked={userTypesEditExport.includes(item.value)}
                onChange={(e) => userTypesEditHandler(e, item.value)}
              />
            ))}
          </div>
        </Grid>


        <Grid item md={12} xs={12}>
          <div className="FieldLabel">Attendance</div>
          <div className='CheckBoxGrouopContaienr'>
            {attendance.map((item, i) => (
              <CheckBox
                label={item.label}
                checked={attendenceTypesEditExport.includes(item.value)}
                onChange={(e) => attendanceTypesEditHandler(e, item.value)}
              />
            ))}
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <div className="FieldLabel">Class/Level</div>
          <div className='CheckBoxGrouopContaienr'>
            {classLevel.map((item, i) => (
              <CheckBox
                label={item.label}
                checked={gradeTypesEditExport.includes(item.value)}
                onChange={(e) => gradeTypesEditHandler(e, item.value)}
              />
            ))}
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <Buttons
            label="Save"
            buttonColor="#FFB606"
            border="#FFB606 1px solid"
            color="#000000"
            width="100%"
            height="48px"
            fontSize="14px"
            onClick={handleConfirm}
          />

          <Buttons
            label="Cancel"
            buttonColor="#fff"
            border="#fff 1px solid"
            color="#0450E1"
            width="100%"
            height="48px"
            fontSize="14px"
            onClick={handleClose}
          />
        </Grid>




      </Grid>
    </div>
  );
};

export default ExportOptions;
