import React, { useState, useEffect } from "react";
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import './TimePicker.css';

function TimePickerField(props) {

  const { label, required, validation, error, placeholder, value,
    handleChange, disablePast, helperText, disabled, errorData } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState("");

  useEffect(() => {

    if (required || validation) {

      console.log(value);

      if (!value) {
        setValidationError(true);
        setValidationText("Required");
      } else {
        setValidationError(false);
        setValidationText("");
      }

    }
  }, [value]);

  return (
    <div className="TimePicker">
      {props.TextField &&
        <div className="FieldLabel">{label} {validation && <span>*</span>}</div>
      }
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          slotProps={{
            textField: {
              error: errorData
            },
          }}
          // inputFormat=""
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} error={error || validationError} helperText={helperText || validationText} />}
        // onError={error}
        />
      </LocalizationProvider>
    </div>
  );
}


export default TimePickerField;

