import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Grid from '@mui/material/Grid';

import { useDeleteMessageCategoriesMutation, useGetCategoriesSearchResultMutation, useGetMessageCategoriesQuery } from "../../../redux/features/messages/meassageApi";

import SelectField from '../../../components/Form/SelectField/SelectField';
import TextArea from '../../../components/Form/TextArea/TextArea';
import TextField from "../../../components/Form/TextField/TextField";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';

import '../../../assets/css/TermsForm.css';

import AddCategories from './AddCategories';
import { useRef } from 'react';


const AddTemplate = (props) => {
  const {
    templateName,
    selectedTemplate,
    setTemplateName,
    templateCategory,
    setTemaplateCategory,
    templateMessage,
    setTemplateMessage,
    validationError
  } = props;
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [editEnable, setEditEnable] = useState(false);
  const [name, setName] = useState("");

  // * ===============  Handler for the TextField input =============== * //
  const handleTemplateNameChange = (event) => {
    const newName = event;
    setTemplateName(newName);
    console.log(newName)
  };

  // * =============== Handler for the TextArea input =============== * //
  const [lastSelectionStartSection, setLastSelectionStartSection] = useState(0)
  const handleTemplateMessageChange = (event) => {
    const newMessage = event.target.value;
    setTemplateMessage(newMessage);
  };

  const checkTheSelection = (e, fieldName) => {
    setLastSelectionStartSection(e.target.selectionStart)
  }
  // * =============== selected item =============== * //
  useEffect(() => {
    if (selectedTemplate) {
      console.log(selectedTemplate)
      setTemplateName(selectedTemplate.templateName);
      setTemaplateCategory(selectedTemplate.category);
      setTemplateMessage(selectedTemplate.content);
    }
  }, [selectedTemplate]);



  // * ==================== category field start ======================= * //

  const [openCategory, setOpenCategory] = useState(false); // ? initial state for category
  // const handleOpenCategory = () => { setOpenCategory(true) }; // ? open dropdwon 
  // const handleCloseCategory = () => { setOpenCategory(false) }; // ? close dropdwon

  // * =============== category select list =============== * //

  // * =============== category list fetch =============== * //
  const { data, isError, isLoading, error } = useGetMessageCategoriesQuery(undefined, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (!isLoading && !isError) {
      setCategoryOptions(convertDataToCellRows(data.body.data));
    }
  }, [data, isError, isLoading]);

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({
        label: item.name,
        value: item.name
      }));
    } else {
      return [];
    }
  };

  const handleOptionChange = (e) => {
    setTemaplateCategory(e.target.value)
  };
  // * =============== create category =============== * //
  const handleOpenCategory = (id) => {
    setOpenCategory(true);
  };

  const handleCloseCategory = () => {
    setOpenCategory(false)
  };

  // * ==================== category field end   ======================= * //

  // * ================= insert tag part ====================================  * //

  const addTag = (val) => {
    let str = templateMessage
    const textBeforeCursor = str.slice(0, lastSelectionStartSection);
    const textAfterCursor = str.slice(lastSelectionStartSection);
    const newText = `${textBeforeCursor}${val}${textAfterCursor}`;
    setTemplateMessage(newText)
  }
  // * ================= insert tag end ==================================== *//

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  console.log(templateCategory, "==> category opt")

  return (
    <>
      <Grid container spacing={3}>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Template Name"
            required={true}
            helperText={validationError.templateName ? "Required" : ""}
            error={validationError.templateName ? true : false}
            value={templateName}
            handleChange={handleTemplateNameChange}
            count={30}
            CharAlignMid="CharAlignMid"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <SelectField
            name="category"
            label="Select Category"
            required={true}
            // error={validationError.templateCategory}
            FieldLabel={true}
            options={categoryOptions}
            selectedOption={templateCategory}
            handleChange={handleOptionChange}
            AddNew={true}
            onClickAddNew={() => handleOpenCategory()}
          />
        </Grid>


        <Grid item md={12} xs={12}>
          <TextArea
            label="Message"
            validation={true}
            FieldLabel={true}
            errorMsg={validationError.templateMessage ? "Required" : ""}
            error={validationError.templateMessage ? true : false}
            handleChange={handleTemplateMessageChange}
            onKeyUpHandler={(e) => checkTheSelection(e, e.target.value)}
            onClickHandler={(e) => checkTheSelection(e, e.target.value)}
            value={templateMessage}
            count={320}
            HeaderRightBody={
              <div className='InsertSection'>

                <a onClick={handleToggle}><BookmarkRoundedIcon />Insert tag</a>
                <div className={isActive ? "PricingCell" : "Active"}>
                  <div className='DropDownMenuCustom'>
                    <a onClick={() => addTag('{{FirstName}}')}>First name</a>
                    <a onClick={() => addTag('{{LastName}}')}>Last name</a>
                    <a onClick={() => addTag('{{FullName}}')}>Full name</a>
                  </div>
                  <div className='backdropAlfa' onClick={handleToggle}></div>
                </div>
              </div>
            }
          />
        </Grid>

      </Grid>

      {/* Add Catgory Modal*/}
      <AddCategories
        openModal={openCategory}
        handleClose={handleCloseCategory}
        name={name}
        setName={setName}
      />


    </>
  );
};

export default AddTemplate;
