import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import '../../../assets/css/users.css';
import TextField from '../../../components/Form/TextField/TextField';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import { validateFormData } from '../../../utils/form';

import {
  useGetCourseDetailsQuery,
  useCreateCourseMutation,
  useUpdateClassMutation,
  useDeleteCourseTeachersMutation
} from '../../../redux/features/classes/classApi';



import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import SelectField from '../../../components/Form/SelectField/SelectField';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import SelectPrimaryTeacher from './SelectPrimaryTeacher';
import { IconButton } from '@mui/material';

import MultipleSelet from '../../../components/Form/MultipleSelet/MultipleSelet';
import { useGetUsersByUserTypesMutation } from '../../../redux/features/users/userApi';

const AddSection = (props) => {
  const {
    schoolYearOptions,
    setSchoolYearOptions,
    classLevelOptions,
    setClassLevelOptions,
    schoolYear,
    setSchoolYear,
    classLevel,
    setClassLevel,
    handleClose,
    id,
    type,
    sectionObject,
    setSectionObject,
    sectionErrors,
    schoolYearList,
    editSectionId,
    courseDetails
  } = props

  const [updateCourse, updateCourseOptions] = useUpdateClassMutation();
  const [createCourse, createCourseOptions] = useCreateCourseMutation();
  const [removeCourseTeachers, removeCourseTeachersOptions] = useDeleteCourseTeachersMutation()
  const [getUsersByUserTypes, getUsersByUserTypesOptions] = useGetUsersByUserTypesMutation();

  useProtected(createCourseOptions.error);
  useProtected(updateCourseOptions.error);
  useProtected(removeCourseTeachersOptions.error);


  const [name, setName] = useState('');

  const [section, setSection] = useState('')
  const [room, setRoom] = useState('')
  const [building, setBuilding] = useState('')
  const [classCodePrefix, setClassCodePrefix] = useState('')
  const [classCodeNumber, setClassCodeNumber] = useState('')
  const [primaryTeacher, setPrimaryTeacher] = useState('')
  const [courseTeachers, setCourseTeachers] = useState([])
  const [termOptions, setTermOptions] = useState([])


  const [teacher, setTeacher] = useState({})
  const [tempTeacher, setTempTeacher] = useState({})

  const [userTypesDatas, setUserTypesDatas] = useState([])

  const getUsersByUserTypesData = async () => {
    const result = await getUsersByUserTypes({ userTypes: "faculty,staff,owner", data: "" })
    if (result?.data?.body?.data) {
      setUserTypesDatas(result?.data?.body?.data)
    }
  }

  useEffect(() => {
    let foundSection = courseDetails?.sections?.find(section => section.id === editSectionId)
    if (foundSection && editSectionId != "") {
      setPrimaryTeacher(`${foundSection?.primaryTeacher?.firstName} ${foundSection?.primaryTeacher?.lastName}`)
      setTeacher(foundSection?.primaryTeacher)
      let schoolYearObject = schoolYearList?.body?.data?.find(schoolYearSingle => schoolYearSingle.name === foundSection?.acededemicYear?.name)
      setTermOptions(getPreparedArray(schoolYearObject?.terms || []))

      setvalue(sectionObject?.classLevel?.map(singleClassLevel => singleClassLevel.name))
    }
  }, [editSectionId, courseDetails])




  const [errors, setErrors] = useState({
    name: '',
    schoolYear: '',
    classLevel: '',
    classCodePrefix: '',
    classCodeNumber: ''
  });

  const handleAddClass = async () => {
    let teacherObj = {
      name: `${teacher.firstName} ${teacher.lastName}`,
      id: teacher?._id || '',
      email: teacher?.email || '',
      role: teacher?.role || '',
      teacherType: 'primary_teacher'
    }
    let data = {
      name,
      schoolYear,
      classLevel,
      section,
      room,
      building,
      classCodePrefix,
      classCodeNumber,
      teachers: [teacherObj],
      status: 'draft'
    }
    if (id) delete data.status

    const isValid = validateFormData(data, setErrors, {
      name: 'Enter name',
      schoolYear: 'Enter school year',
      classLevel: 'Enter school grade',
      classCodePrefix: 'Enter class code prefix',
      classCodeNumber: 'Enter class code number',
    });
    console.log(isValid)
    if (!isValid) {
      return;
    }
    let result = null;
    if (id) {

      if ((Object.keys(teacher).length) > 0) {
        // let teachersExceptPrimary = courseDetails?.teachers?.filter(teacher => teacher.teacherType !== 'primary_teacher')

        courseTeachers?.map((teacherSingle) => {
          if (teacherSingle.teacherType !== 'primary_teacher' && teacherSingle.id !== teacherObj?.id) {
            data.teachers.push(teacherSingle)
          }
        })
        // console.log('check', (Object.keys(teacher).length) > 0, courseDetails, data.teachers)

      }
      result = await updateCourse({ ...data, id });

    } else {
      result = await createCourse(data);
    }

    if (!result?.data?.error) {
      if (id) {
        toast.success('Course updated');
      } else {
        toast.success('Course created');
      }

      setName('')
      setSchoolYear('')
      setClassLevel('')
      setSection('')
      setRoom('')
      setBuilding('')
      setClassCodePrefix('')
      setClassCodeNumber('')
      setPrimaryTeacher('')
      handleClose();
    } else {
      toast.error(result?.data?.message);
    }
  };


  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Twenty",
      value: "Twenty",
    },

    {
      label: "Twentydd",
      value: "Twentyd",
    },

  ]

  const setTeacherHandler = () => {
    handleClosePrimaryTeacherDrawer()
    console.log(tempTeacher)
    setTeacher(tempTeacher)
    setPrimaryTeacher(tempTeacher?.name ? tempTeacher?.name : `${tempTeacher?.firstName} ${tempTeacher?.lastName}`)
  }

  // Add Primary Teacher //
  const [openPrimaryTeacherDrawer, setOpenPrimaryTeacher] = useState(false);
  const handlePrimaryTeacherDrawerOpen = () => {
    getUsersByUserTypesData()
    setOpenPrimaryTeacher(true);
  };
  const handleClosePrimaryTeacherDrawer = () => {
    setOpenPrimaryTeacher(false);
  };

  const getDataMap = (dataArray, primaryField, labelField, additionalField = "", separator = " ", conditionalField = "", conditionalValue = "", skipData = false) => {
    const result = [];
    if (typeof dataArray !== 'undefined') {
      dataArray.forEach(element => {

        if (typeof element[labelField] !== 'undefined' && element[labelField] !== null) {
          const label = additionalField === "" ? `${element[labelField]}` : `${element[labelField]}${separator}${element[additionalField]}`

          if (conditionalField?.length > 0) {
            if (skipData) {
              // console.log(conditionalField, element[conditionalField], conditionalValue)
              if (element[conditionalField] != conditionalValue) {
                result.push({ value: `${element[primaryField]}`, label: label });
              }
            } else {
              if (element[conditionalField] == conditionalValue) {
                result.push({ value: `${element[primaryField]}`, label: label });
              }
            }

          } else {
            result.push({ value: `${element[primaryField]}`, label: label });
          }

        }
      });
    }
    // console.log(result);
    return result;
  }

  const getPreparedArray = (data) => {
    return data.map((item) => ({
      value: item.termName,
      label: item.termName,
    }));
  };
  const onChangeHandler = (val, e, fieldName) => {
    if (fieldName === 'acededemicYear') {
      let schoolYearObject = schoolYearList?.body?.data?.find(schoolYearSingle => schoolYearSingle.name === e.target.value)
      setTermOptions(getPreparedArray(schoolYearObject?.terms || []))

    }
    let newObject = { ...sectionObject, [fieldName]: e.target.value }
    setSectionObject(newObject)
  }
  const onChangeHandlerForSecondDepth = (val, e, parentFieldName, fieldName) => {
    let newObject = {
      ...sectionObject,
      [parentFieldName]: {
        ...sectionObject[parentFieldName],
        [fieldName]: e.target.value
      }
    }
    setSectionObject(newObject)
  }

  useEffect(() => {
    let newObject = { ...sectionObject, primaryTeacher: teacher }
    setSectionObject(newObject)
  }, [teacher])


  useEffect(() => {
    console.log('tempTeacher', tempTeacher)
  }, [tempTeacher])


  // === Multi Select == //
  const [value, setvalue] = React.useState([]);
  const handleChange = (event, fieldName) => {
    const {
      target: { value },
    } = event;
    setvalue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    let newObject = { ...sectionObject, [fieldName]: typeof value === 'string' ? value.split(',') : value }
    setSectionObject(newObject)
  };

  useEffect(() => {

    console.log(value, 'value')
  }, [value])
  const home = [
    '1',
    '2',
    '3',
  ];
  const setLabelAsValueForMultiSelect = (classLevelOptions) => {
    let optionArray = classLevelOptions?.map(option => {
      return option.label
    })
    return optionArray
  }

  // === Multi Select End== //


  return (



    <>

      {createCourseOptions.isLoading ||
        updateCourseOptions.isLoading ? (
        <Loader
          height="80px"
          width="80px"
          borderWidth="8px"
          isFullWidth={true}
          isOverlay={true}
        />
      ) : (
        <>


          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Section Name<span>*</span></div>
            <div className='FormFieldCol'>
              <TextField
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                placeholder="Enter section name"
                required={true}
                count={20}
                name={'section'}
                helperText={sectionErrors?.sectionName}
                error={sectionErrors?.sectionName}
                value={sectionObject.sectionName}
                handleChange={(val, e) => onChangeHandler(val, e, 'sectionName')}
              />
            </div>
          </div>


          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Class/Level<span>*</span></div>
            <div className='FormFieldCol'>
              <MultipleSelet
                Placeholder="Select class/level"
                option={setLabelAsValueForMultiSelect(classLevelOptions)}
                handleChange={(e) => handleChange(e, 'classLevel')}
                value={value}
                helperText={sectionErrors?.classLevel}
                error={sectionErrors?.classLevel}
              />
            </div>
          </div>


          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Academic Year<span>*</span></div>
            <div className='FormFieldCol'>
              <SelectField
                label="Select academic year"
                name="acededemicYear"
                options={schoolYearOptions}
                selectedOption={(typeof sectionObject?.acededemicYear == "string") ? sectionObject?.acededemicYear : sectionObject?.acededemicYear?.name}
                helperText={sectionErrors?.acededemicYear}
                error={sectionErrors?.acededemicYear}
                handleChange={(val) => onChangeHandler(val, val, 'acededemicYear')}
              />
            </div>
          </div>

          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Academic Term</div>
            <div className='FormFieldCol'>
              <SelectField
                label="Select term"
                name="acededemicTerm"
                options={termOptions}
                selectedOption={(typeof sectionObject?.acededemicTerm == "string") ? sectionObject?.acededemicTerm : sectionObject?.acededemicTerm?.termName}
                helperText={sectionErrors?.acededemicTerm}
                error={sectionErrors?.acededemicTerm}
                handleChange={(val, e) => onChangeHandler(val, val, 'acededemicTerm')}
              />
            </div>
          </div>

          <div className='FormFieldContainer alignTop'>
            <div className='FloatLabelCol'>Section Code<span>*</span></div>
            <div className='FormFieldCol'>

              <Grid container spacing={2}>

                <Grid item md={6} xs={12}>
                  <TextField
                    CharAlignMid="CharAlignMid"
                    Uppercase="Uppercase"
                    placeholder="Enter code prefix"
                    count={4}
                    name={'code_prefix'}
                    required={true}
                    helperText={sectionErrors?.sectionCode_codePrefix}
                    error={sectionErrors?.sectionCode_codePrefix}
                    value={sectionObject?.sectionCode?.codePrefix}
                    showErrorMsg={false}
                    handleChange={(val, e) => onChangeHandlerForSecondDepth(val, e, 'sectionCode', 'codePrefix')}
                  />

                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    CharAlignMid="CharAlignMid"
                    Uppercase="Uppercase"
                    placeholder="Enter code"
                    count={4}
                    name={'classCodeNumber'}
                    number="number"
                    required={true}
                    helperText={sectionErrors?.sectionCode_code}
                    error={sectionErrors?.sectionCode_code}
                    value={sectionObject?.sectionCode?.code}
                    showErrorMsg={false}
                    handleChange={(val, e) => onChangeHandlerForSecondDepth(val, e, 'sectionCode', 'code')}
                  //value={courseData.Id}
                  //handleChange={handleChanges("Id")}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <div className='PreviewSection'>
                    Course code preview: <span style={{ marginLeft: "4px" }}>{sectionObject?.sectionCode?.codePrefix} {sectionObject?.sectionCode?.code}</span>
                  </div>
                  {(sectionErrors?.sectionCode_codePrefix != "" || sectionErrors?.sectionCode_code != "") && <p style={{ color: "#d32f2f" }}>Required</p>}
                </Grid>

              </Grid>

            </div>
          </div>


          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Primary Teacher</div>
            <div className='FormFieldCol'>
              <div className='PrimaryTeacher' onClick={handlePrimaryTeacherDrawerOpen}>
                <div className='PrimaryTeacherinfo'>
                  <SearchRoundedIcon className='iconSearch' />
                  <input type='text' />
                  <div className='FieldTextBox'>
                    <span>{(editSectionId && Object.keys(teacher).length === 0) && primaryTeacher} {(Object.keys(teacher).length) > 0 && `${teacher?.firstName} ${teacher?.lastName}`}</span> <FiberManualRecordRoundedIcon />
                    {((Object.keys(teacher).length) == 0 && !editSectionId) && <span style={{ color: '#ACAAAF' }}>Select primary teacher</span>}
                    {(!editSectionId || teacher) && teacher?.userUniqueID}
                  </div>
                </div>
                {((Object.keys(teacher).length) > 0) && <IconButton onClick={(e) => setTeacher({})} className='iconClose'><CancelRoundedIcon /></IconButton>}
              </div>
              <div className='FormHeader'>
                {sectionErrors?.primaryTeacher != "" ? <p style={{ color: "#d32f2f" }}>{sectionErrors?.primaryTeacher}</p> : <p>You can add additional team from course details.</p>}
              </div>
            </div>
          </div>

          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Class Capacity<span>*</span></div>
            <div className='FormFieldCol'>
              <TextField
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                placeholder="Enter max number of students"
                number="number"
                name={'classRoomSize'}
                required={true}
                helperText={sectionErrors?.classRoomSize}
                error={sectionErrors?.classRoomSize}
                value={sectionObject?.classRoomSize}
                handleChange={(val, e) => onChangeHandler(val, e, 'classRoomSize')}
              //value={courseData.Id}
              //handleChange={handleChanges("Id")}
              />
            </div>
          </div>




        </>
      )}
















      {/* Primary TeacherDrawer */}
      <SideDrawer
        open={openPrimaryTeacherDrawer}
        handleDrawerClose={handleClosePrimaryTeacherDrawer}
        title="Select Primary Teacher"
        sideOpen="right"
        ButtonLabel="Select"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={(e) => { setTeacherHandler() }}
        padding="0"
        body={
          <>
            <SelectPrimaryTeacher setTeacher={setTeacher} setTempTeacher={setTempTeacher} userTypesDatas={userTypesDatas} />
          </>
        }
      />

      <DrawerFooterActions
        isLoading={
          createCourseOptions.isLoading ||
          updateCourseOptions.isLoading
        }
        handleClose={handleClose}
        handleAction={handleAddClass}
        closeBtnLabel={'Cancel'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={id ? 'Update' : 'Add section'}
      />
    </>
  );
};

export default AddSection;
