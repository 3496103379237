import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import {
  useGetSearchResultMutation,
  useGetUsersByRoleQuery,
  useGetSearchByRoleAndTermResultMutation,
  useGetUsersByUserTypesMutation
} from "../../../redux/features/users/userApi";

const AddTeachers = ({ setCourseUsers, courseUsers, addCourseUsersOptions, setCourseUsersToUpdate, courseUsersToUpdate, userTypesDatas, allTeachers, primaryTeacher }) => {

  // const { data, isError, isLoading, error } = useGetUsersByRoleQuery("faculty,teacher", { refetchOnMountOrArgChange: true });
  // useProtected(error);


  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [teachers, setTeachers] = useState([])
  const [getSearchedUserListByRoleAndTerm, options] = useGetSearchByRoleAndTermResultMutation();



  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Full Name", width: "40%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "ID #", width: "30%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "User Type", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "", textAlign: "left", },


  ];

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map(item => ({
        cell: [
          { Check: true, width: "48px", user_id: item._id, user: { name: `${item?.firstName} ${item?.lastName}`, id: item._id, email: item?.email, role: 'teacher', teacherType: 'teaching_assistant', userUniqueId: item?.userUniqueID } },
          { textBold: `${item.firstName} ${item.lastName}`, user: { name: `${item?.firstName} ${item?.lastName}`, id: item._id, email: item?.email, role: 'teacher', teacherType: 'teaching_assistant', userUniqueId: item?.userUniqueID }, Status: true, background: '#EDF7EA', color: '#66BD50', label: 'Active', },
          //{ textBold: `${item.firstName} ${item.lastName}`, Check: true, Status: true, background: '#EDF7EA', color: '#66BD50', label: 'Inactive', },
          { text: item?.userUniqueID || "N/A" },
          { text: item?.userType ? `${item?.userType.charAt(0).toUpperCase()}${item.userType?.slice(1)}` : "N/A" },
          { text: "" },
        ]
      }))
    }
    else {
      return [];
    }
  }

  useEffect(() => {
    if (userTypesDatas) {
      let usersToShow = userTypesDatas?.filter((singleUser) => {
        let userFound = allTeachers?.find((teacher) => teacher.userUniqueID === singleUser.userUniqueID)
        return userFound ? false : true
      })
      let userToShowWithoutPrimaryTeacher = usersToShow?.filter((teachers) => teachers?.userUniqueID !== primaryTeacher?.userUniqueID)
      setRows(convertDataToCellRows(userToShowWithoutPrimaryTeacher));
      setTeachers(userTypesDatas)
    }
  }, [userTypesDatas])



  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }

  const handleSearch = async () => {
    const result = await getSearchedUserListByRoleAndTerm({ role: 'teacher', term: searchTerm });
    console.log({ result })
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  }

  // Filter //
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };
  const handleCloseFilterDrawer = () => {
    setOpenFilter(false);
  };

  useEffect(() => {
    let addTeachers = []
    courseUsers?.map((users) => {
      const findTeachers = teachers?.find((teachersId) => teachersId?._id === users)
      // console.log(findTeachers, 95)
      if (findTeachers) {
        addTeachers.push({
          id: findTeachers?._id,
          name: `${findTeachers?.firstName} ${findTeachers?.lastName}`,
          email: findTeachers?.email,
          role: findTeachers?.role,
          status: findTeachers?.status,
          userType: findTeachers?.userType,
          userUniqueID: findTeachers?.userUniqueID,
          teacherType: findTeachers?.userType
        })
      }
    })
    setCourseUsersToUpdate(addTeachers)
  }, [courseUsers])

  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setCourseUsers(prevUsers => [...prevUsers, item[0].user]);
      // setCourseUsersToUpdate(prevUsers => [...prevUsers, item[0].user]);
    } else {
      // Remove the user from the array
      setCourseUsers(prevUsers => prevUsers.filter(user => user._id !== item[0].user._id));
      // setCourseUsersToUpdate(prevUsers => prevUsers.filter(user => user.id !== item[0].user.id));
    }
  }


  return (
    <>
      <div className="TableSection">
        <Table
          tableHeader={true}
          headCells={headCells}
          rows={rows}
          onClick={handleFilterDrawerOpen}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          innerHeader={true}
          showSearchBox={true}
          handleRowClick={handleRowClick}
          searchBoxPlaceholder="Search Users"
          handleCheckItem={setCourseUsers}
          selectedCheckList={courseUsers}
          customCellObjectId="user_id"
          // selectAllHander={selectAllHander}
          // isDataLoading={isLoading || options?.isLoading || addCourseUsersOptions?.isLoading}
          isDataLoading={addCourseUsersOptions?.isLoading}
          RowsPerPage={15}
          CheckboxDelete={true}

        />
      </div>

    </>
  );
};

export default AddTeachers;
