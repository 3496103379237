import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import { Button } from '@mui/material';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import tableImg from '../../../assets/imgs/tableImg.png';
import CustomAccordion from '../../../components/CustomAccordion/CustomAccordion';
import AddGuardiansParents from './AddGuardiansParents';
import AddNewGuardiansParents from './AddNewGuardiansParents';
import GuardiansDetails from './GuardiansDetails';
import { useEffect } from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { toTitleCase } from '../../../utils/helpers';
import { validateFormData } from '../../../utils/form';
import { toast } from 'react-toastify';
import useProtected from '../../../hooks/useProtected';
import {
    useAddGuardianMutation,
    useUpdateGuardianMutation,
} from '../../../redux/features/users/guardianApi';
import { useAddEmergencyContactMutation, useCreateInviteUserMutation, useDeleteEmergencyContactMutation, useUpdateEmergencyContactMutation } from '../../../redux/features/users/userApi';
import { useSelector } from 'react-redux';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import DraggableDialog from '../../../components/hooks/Dialog';
import EmergencyContactsDrawer from './EmergencyContactsDrawer';
import { isValidEmail } from '../../../hooks/HelperFunctions';

const EmergencyContacts = (props) => {
    const { userUniqueID, userId, emergencyContacts, isLoading } = props;

    const [addEmergencyContact, addEmergencyContactOptions] = useAddEmergencyContactMutation()
    const [updateEmergencyContact, updateEmergencyContactOptions] = useUpdateEmergencyContactMutation()
    const [deleteEmergencyContact, deleteEmergencyContactOptions] = useDeleteEmergencyContactMutation()

    useProtected(addEmergencyContactOptions.error)
    useProtected(updateEmergencyContactOptions.error)
    useProtected(deleteEmergencyContactOptions.error)

    let emergencyContactObject = {
        firstName: "",
        middleName: "",
        lastName: "",
        phone: "",
        status: "",
        email: "",
        relationship: "",
        address: ""
    }

    const [rows, setRows] = useState([]);
    const [emergencyContact, setEmergencyContact] = useState(emergencyContactObject);
    const [phoneToDelete, setPhoneToDelete] = useState('')
    const [phoneToEdit, setPhoneToEdit] = useState('')
    const [editEmergencyContact, setEditEmergencyContact] = useState(null)

    const [errors, setErrors] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        phone: "",
        status: "",
        email: "",
        relationship: "",
        address: ""
    })
    // * ==================== Drawer start ==================== * // 
    const [emergencyContactDrawer, setEmergencyContactDrawer] = useState(false);
    const EmergencyContactDrawerOpen = () => setEmergencyContactDrawer(true);
    const EmergencyContactDrawerClose = () => {
        setEmergencyContact(emergencyContactObject)
        setPhoneToEdit('')
        setEditEmergencyContact(null)
        setEmergencyContact(emergencyContactObject)
        setEmergencyContactDrawer(false);
    }
    // * ==================== Drawer end  ==================== * //

    // * ==================== Delete start ==================== * // 
    const [emergencyContactDelete, setEmergencyContactDelete] = useState(false);
    const EmergencyContactDeleteOpen = () => setEmergencyContactDelete(true);
    const EmergencyContactDeleteClose = () => setEmergencyContactDelete(false);
    // * ==================== Delete end  ==================== * //

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
            width: '25%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Email',
            width: '30%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Phone',
            width: '20%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Status',
            width: '20%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: '',
            width: '5%',
            textAlign: 'left',
        },
    ];

    useEffect(() => {
        setRows(convertDataToCellRows(emergencyContacts))
    }, [emergencyContacts])
    const emergencyContactDeleteHandler = (phone) => {
        setPhoneToDelete(phone)
        EmergencyContactDeleteOpen()
    }
    const deleteEmergencyContactHandler = async () => {

        let result = await deleteEmergencyContact({ ...{ phone: phoneToDelete }, id: userId })
        toast.success("Emergency contact deleted")
        EmergencyContactDeleteClose()
    }
    const emergencyContactEditHandler = (phone) => {
        setPhoneToEdit(phone)
        let foundContact = emergencyContacts.find(contact => contact.phone == phone)
        if (foundContact) {
            setEditEmergencyContact(foundContact)
            setEmergencyContact(foundContact)
        } else {
            setEditEmergencyContact(null)
        }
        EmergencyContactDrawerOpen()
    }
    const convertDataToCellRows = (arr) => {
        if (arr.length > 0) {
            return arr.map((item) => ({
                cell: [
                    { textBold: `${item?.firstName} ${item?.middleName} ${item?.lastName}` },
                    { text: item?.email || "N/A" },
                    { text: item?.phone || "N/A" },
                    {
                        Status: true,
                        background: (item?.status == 'active') ? '#EDF7EA' : '#FCE7E9',
                        color: (item?.status == 'active') ? '#66BD50' : '#E63946',
                        label: (item?.status == 'active') ? 'Active' : 'Inactive'
                    },
                    { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', },
                ],
                //======= Action Date Start===== //
                action: [
                    { label: 'DETAILS', onClick: (e) => emergencyContactEditHandler(item?.phone) },
                    { label: 'DELETE', onClick: (e) => emergencyContactDeleteHandler(item?.phone) },
                ],
                //======= Action Date End===== //
            }));
        } else {
            return [];
        }
    };


    const checkEmergencyContact = async () => {
        let errorNumber = 0
        let errorObject = { ...errors }
        if (emergencyContact.firstName == "") {
            errorObject = { ...errorObject, firstName: "Required" }
            errorNumber++
        } else {
            errorObject = { ...errorObject, firstName: "" }
        }

        if (emergencyContact.lastName == "") {
            errorObject = { ...errorObject, lastName: "Required" }
            errorNumber++
        } else {
            errorObject = { ...errorObject, lastName: "" }
        }

        if (emergencyContact.email != "" && !isValidEmail(emergencyContact.email)) {
            errorObject = { ...errorObject, email: "Enter a valid email address" }
            errorNumber++
        } else {
            errorObject = { ...errorObject, email: "" }
        }

        if (emergencyContact.status == "") {
            errorObject = { ...errorObject, status: "Required" }
            errorNumber++
        } else {
            errorObject = { ...errorObject, status: "" }
        }


        if (emergencyContact.phone == "") {
            errorObject = { ...errorObject, phone: "Required" }
            errorNumber++
        } else {
            errorObject = { ...errorObject, phone: "" }
        }

        if (emergencyContact.relationship == "") {
            errorObject = { ...errorObject, relationship: "Required" }
            errorNumber++
        } else {
            errorObject = { ...errorObject, relationship: "" }
        }

        if (emergencyContact.address == "" || emergencyContact.address == {}) {
            errorObject = { ...errorObject, address: "Required" }
            errorNumber++
        } else {
            errorObject = { ...errorObject, address: "" }
        }


        setErrors({ ...errors, ...errorObject })

        return (errorNumber > 0) ? false : true

    }
    const handleAddUpdateEmergencyContact = async () => {

        const isValid = await checkEmergencyContact()

        if (!isValid) {
            return false
        }
        if (phoneToEdit != "") {
            let result = await updateEmergencyContact({ id: userId, ...emergencyContact })

            if (result?.data) {
                toast.success("Emergency contact updated")

            } else {
                toast.error(result?.data?.message);
            }
        } else {

            let result = await addEmergencyContact({ id: userId, ...emergencyContact })

            if (result?.data) {
                toast.success("Emergency contact added")

            } else {
                toast.error(result?.data?.message);
            }
        }

        setPhoneToEdit('')
        setEditEmergencyContact(null)
        setEmergencyContact(emergencyContactObject)

        EmergencyContactDrawerClose()
    }

    return (
        <>
            <div className="TableSection">
                <Table
                    innerTitle="Emergency Contacts"
                    headCells={headCells}
                    rows={rows}
                    footer={true}
                    innerHeader={true}
                    isDataLoading={isLoading}
                    AddButtonInner={true}
                    addLabelInner="New emergency contact"
                    InnerButtonColor="#FFB606"
                    InnerButtonTextColor="#000"
                    InnderButtonIcon={<AddRoundedIcon />}
                    onClickInnerButton={EmergencyContactDrawerOpen}
                    emptyTableTitle="No emergency contact added yet"
                    emptyTableNote="Users of this group will be visible here"
                    btnLabel="New emergency contact"
                    showButton={true}
                    onClickEmptyCard={EmergencyContactDrawerOpen} />
            </div>


            {/* Details Guardians/Parents */}
            <SideDrawer
                open={emergencyContactDrawer}
                handleDrawerClose={EmergencyContactDrawerClose}
                title={(phoneToEdit != "") ? "Emergency Contact Details" : "Add Emergency Contact"}
                buttonLabel="Update"
                // buttonLink={}
                sideOpen="right"
                ButtonLabel={(phoneToEdit != "") ? "SAVE" : "ADD NOW"}
                cancelButtonLabel="CANCEL"
                FooterWithoutTab={true}
                clickSaveButton={handleAddUpdateEmergencyContact}

                body={
                    <EmergencyContactsDrawer
                        emergencyContact={emergencyContact}
                        setEmergencyContact={setEmergencyContact}
                        errors={errors}
                        setErrors={setErrors}
                        editEmergencyContact={editEmergencyContact} />
                }
            />

            {/* ==================== Delete modal =========================== */}
            <DraggableDialog
                openDialog={emergencyContactDelete}
                handleCloseDialog={EmergencyContactDeleteClose}
                handleConfirmCloseDialog={deleteEmergencyContactHandler}
                title="Delete emergency contact?"
                body={<div>Are you sure you want to delete this emergency contact?<br />Once you delete it, you can't get it back.</div>}
                ModalFooter={true}
                actionButton="DELETE"
                cancelButton="CANCEL"
            />
        </>
    );
};

export default EmergencyContacts;
