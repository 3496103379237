import { Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import TextField from '../../../../components/Form/TextField/TextField';
import PhoneInputField from '../../../../components/Form/PhoneInputField/PhoneInputField';
import ComboBox from '../../../../components/Form/ComboBox/ComboBox';
import { countries } from "../../../../assets/data/countries";
import moment from "moment-timezone";
import { currencies } from '../../../../assets/data/currencies';
import GooglePlaceField from '../../../../components/Form/GooglePlaceField/GooglePlaceField';

const EditAccountDetails = (props) => {
    const {
        schoolInfo,
        setFormFieldsValue,
        formFieldsValue,
        setEinNumberError,
        einNumberError,
        nameError,
        setNameError,
        dbaError,
        setDbaError,
        emailError,
        setEmailError,
        countryError,
        setCountryError,
        checkRequiredNumber,
    } = props

    const [countrySelect, setCountrySelect] = useState('');
    const [name, setName] = useState(schoolInfo?.name);
    const [dba, setDba] = useState(schoolInfo?.dba);
    const [einNumber, setEinNumber] = useState(schoolInfo?.einNumber);
    const [email, setEmail] = useState(schoolInfo?.email);
    const [phone, setPhone] = useState(schoolInfo?.phone);
    const [phoneExt, setPhoneExt] = useState(schoolInfo?.phoneExt);
    const [country, setCountry] = useState(schoolInfo?.country);
    const [address, setAddress] = useState(schoolInfo?.address);
    const [currency, setCurrency] = useState(schoolInfo?.currency);
    const [timezone, setTimezone] = useState(schoolInfo?.timezone);


    const handleFieldChange = (fieldName, fieldValue) => {
        setFormFieldsValue({
            ...formFieldsValue,
            [fieldName]: fieldValue
        });
    };
    const prepareEinNumberValue = (val) => {
        val = val.replace(/[^0-9]/g, '')
        if (val.length > 9) {
            val = val.slice(0, -1)
        }
        return val
    }
    const handleChanges = (val, event, setMethod, fieldName) => {
        if (fieldName === 'einNumber') {

            val = prepareEinNumberValue(val)
        }
        setMethod(val)
        handleFieldChange(fieldName, val)
    };

    // ================= Time zone ====================
    const [selectedTimeZone, setSelectedTimeZone] = useState('')
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);

    useMemo(() => {
        const names = moment.tz.names();
        let offsetTmz = [];
        const uniqueOffsets = new Set();

        for (let i in names) {
            if (names[i].startsWith('America/') || names[i].startsWith('US') || names[i].startsWith('Canada')) {
                let offSet = moment.tz(names[i]).format('Z');
                // console.log(offSet)
                // if (!uniqueOffsets.has(offSet)) {
                //     uniqueOffsets.add(offSet);
                //     offsetTmz = [...offsetTmz, `${names[i]} (GMT${offSet})`]
                // }
                offsetTmz = [...offsetTmz, `${names[i]} (GMT${offSet})`]
            }
        }
        console.log({ offsetTmz })
        setTimeZoneOptions(offsetTmz.map(item => ({ label: item, value: item })))
    }, [])

    const handleChangeWithData = (fieldName, fieldData, setMethod) => {
        setMethod(fieldData)
        handleFieldChange(fieldName, fieldData)
    };
    // =================================================

    const checkValidEinNumber = (event) => {
        if (event.target.value.length < 9) {
            checkRequiredNumber(event, 'einNumber')
            setEinNumberError("Enter a valid EIN number")
        } else {
            setEinNumberError("")
        }
    }
    const checkJsonStringOrNot = (address) => {

        if (typeof address === 'string') {

            if (address.length == 0) {
                return "";
            }

            if (/^[\],:{}\s]*$/.test(address.replace(/\\["\\\/bfnrtu]/g, '@')
                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                .replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

                return JSON.parse(address);

            } else {

                return {};

            }
        } else {
            return address;
        }

    }
    return (
        <>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            validate={true}
                            label="School Full Name"
                            required={true}
                            value={name}
                            handleChange={(val, e) => handleChanges(val, e, setName, 'name')}
                            onBlur={(e) => checkRequiredNumber(e, 'name')}
                            error={(nameError != "")}
                            helperText={(nameError != "") ? nameError : ''}
                            count={50}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            validate={true}
                            label="DBA/Short Name"
                            required={true}
                            value={dba}
                            handleChange={(val, e) => handleChanges(val, e, setDba, 'dba')}
                            onBlur={(e) => checkRequiredNumber(e, 'dba')}
                            error={(dbaError != "")}
                            helperText={(dbaError != "") ? dbaError : ''}
                            count={50}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            validate={true}
                            label="EIN"
                            required={true}
                            value={einNumber}
                            handleChange={(val, e) => handleChanges(val, e, setEinNumber, 'einNumber')}
                            onBlur={(e) => checkValidEinNumber(e)}
                            error={(einNumberError != "")}
                            helperText={(einNumberError != "") ? einNumberError : ''}
                            // count={9}
                            number="number"


                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            label="Email Address"
                            required={true}
                            email={true}
                            value={email}
                            handleChange={(val, e) => handleChanges(val, e, setEmail, 'email')}
                            onBlur={(e) => checkRequiredNumber(e, 'email')}
                            error={(emailError != "")}
                            helperText={(emailError != "") ? emailError : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <PhoneInputField
                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                            country={'us'}
                            value={phone}
                            countryCodeEditable={false}
                            onChange={(value, country, e, formattedValue) => handleChangeWithData("phone", formattedValue, setPhone)}
                            onBlur={() => console.log("onBlur")}

                            variant="outlined"
                            onlyCountries={["us", "ca", "bd"]}
                            containerStyle={{}}
                            buttonStyle={{}}
                            inputStyle={{
                                marginLeft: "0px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                            }}
                            containerClass={{}}
                            errorText={"Phone number is required"}
                            errorStyles={{ marginBottom: "5px" }}
                            autoFormat={true}
                            disableDropdown={false}
                            disabled={false}
                            disableCountryCode={false}
                            enableSearch={false}
                            disableSearchIcon={false}
                            placeholder={""}
                            inputProps={{}}
                            specialLabel={'Primary Phone'}
                            // preferredCountries={[]}
                            // excludeCountries={[]}
                            labelFixed="Phone Number"
                            LabelStatus={true}
                        //helpertext="Helper text"
                        //error="error"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <TextField
                            label="Ext."
                            required={false}
                            email={true}
                            value={phoneExt}
                            count={4}
                            number="number"
                            handleChange={(val, e) => {
                                val = val.replace(/[^0-9]/g, '')
                                if (val.length > 4) {
                                    val = val.slice(0, -1)
                                }
                                handleChanges(val, e, setPhoneExt, 'phoneExt')
                            }
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <ComboBox
                            label="Select Country"
                            validation={true}
                            options={countries}
                            selectedOption={country}
                            handleChange={(val, e) => handleChanges(val, e, setCountry, 'country')}
                            onBlur={(e) => checkRequiredNumber(e, 'country')}
                            error={(countryError != "")}
                            helperText={(countryError != "") ? countryError : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <GooglePlaceField
                            label="Address"
                            placeholder="Your current resident address"
                            value={checkJsonStringOrNot(address)}
                            handleChange={(address) => handleChangeWithData("address", address, setAddress)}

                        />
                        {/* <TextField
                            label="Street Address"
                            required={true}
                            placeholder="Current resident address"
                            value={addressLine1}
                            handleChange={(val, e) => handleChanges(val, e, setAddressLine1, 'addressLine1')}
                            onBlur={(e) => checkRequiredNumber(e, 'addressLine1')}
                            error={(addressLine1Error != "")}
                            helperText={(addressLine1Error != "") ? addressLine1Error : ''}
                        /> */}
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={5}>
                        <TextField
                            label="Fl/Suite No.(Optional)"
                            required={false}
                        // value={schoolInfo?.firstName}
                        // handleChange={(val, e) => handleChanges("firstName")(e)}
                        />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={12} md={4}>
                        <TextField
                            label="City/Town"
                            required={false}
                            value={city}
                            handleChange={(val, e) => handleChanges(val, e, setCity, 'city')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <TextField
                            label="State/Province"
                            required={false}
                            value={state}
                            handleChange={(val, e) => handleChanges(val, e, setState, 'state')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <TextField
                            label="Zip/Postal Code"
                            required={false}
                            value={zip}
                            handleChange={(val, e) => handleChanges(val, e, setZip, 'zip')}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12}>
                        <ComboBox
                            label="Currency"
                            validation={false}
                            options={currencies}
                            selectedOption={currency}
                            handleChange={(val, e) => handleChanges(val, e, setCurrency, 'currency')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <ComboBox
                            label="Select Timezone"
                            validation={false}
                            options={timeZoneOptions}
                            selectedOption={timezone}
                            handleChange={(val, e) => handleChanges(val, e, setTimezone, 'timezone')}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default EditAccountDetails