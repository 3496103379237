import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import SelectField from '../../../components/Form/SelectField/SelectField';
import useProtected from '../../../hooks/useProtected';
import { useGetClassLevelListQuery } from '../../../redux/features/classes/classApi';



const Export = (props) => {
  const { data: classesData, isLoading: classesIsLoading, isError: classesIsError, error: classesError } = useGetClassLevelListQuery()

  const { userTypesHandler,
    gradeTypesHandler,
    handleExportType,
    handleDateRange,
    exportType,
    dateRange,
    userTypesExport,
    gradeTypesExport,
    setUserTypesExport,
    genderTypeHandler,
    genderTypesExport,
    classLevel,
    setClassLevel,
    setGradeTypesExport } = props;

  const [state, setState] = React.useState({
    checkedA: true,
  });

  useProtected(classesError);

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };


  const [classes, setClasses] = useState([])
  const [userTypeAll, setUserTypeAll] = useState(false)
  const [gradeTypeAll, setGradeTypeAll] = useState(false)




  //============== user status part start ===================================//
  const userTypesData = [
    {
      label: 'Inactive',
      value: 'inactive',
    },

    {
      label: 'Active',
      value: 'active',
    },
  ];

  useEffect(() => {
    if (userTypeAll) {
      let val_array = []
      userTypesData.map((user) => {
        val_array.push(user?.label?.toLowerCase())
      })
      setUserTypesExport(val_array)
    } else {
      setUserTypesExport([])
    }
  }, [userTypeAll])
  //============== user status part end ===================================//

  //============== gender part start ===================================//
  const gender = [
    {
      label: 'Male',
      value: 'male',
    },

    {
      label: 'Female',
      value: 'female',
    },
  ];
  //============== gender part end ===================================//

  //============== class_level part start ===================================//
  useEffect(() => {
    if (!classesIsLoading && !classesIsError) {
      setClasses(classesData?.body?.data)
    }
  }, [classesData, classesIsLoading, classesIsError])

  useEffect(() => {
    if (gradeTypeAll) {
      let val_array = []
      classes.map((cls) => {
        val_array.push(cls?.name?.toLowerCase())
      })
      setGradeTypesExport(val_array)
    } else {
      setGradeTypesExport([])
    }
  }, [gradeTypeAll])


  useEffect(() => {
    if (classes?.length > 0) {
      let allClasses = []
      let val_array = []
      classes.map((cls) => {
        allClasses.push({ label: cls?.name?.replace(/(^|\s)\w/g, (match) => match.toUpperCase()), value: cls?.name?.toLowerCase() })
        val_array.push(cls?.name?.toLowerCase())
      })
      setClassLevel(allClasses)
      // setGradeTypesExport(val_array)
    }
  }, [classes])


  //============== class_level part end ===================================//

  const format = [
    {
      label: 'Excel',
      value: 'excel',
    },

    {
      label: 'CSV',
      value: 'csv',
    },
  ];

  const dataRange = [
    {
      label: 'Last 30 days',
      value: '30_days',
    },

    {
      label: 'Last 3 months',
      value: '3_months',
    },

    {
      label: 'Last 6 months',
      value: '6_months',
    },

    {
      label: 'Last 12 months',
      value: '12_months',
    },

    {
      label: 'All',
      value: 'all',
    },
  ];

  return (
    <div className="Export">
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <SelectField
            FieldLabel={true}
            label="Select format"
            selectedOption={exportType}
            options={format}
            handleChange={(e) => handleExportType(e)}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <SelectField
            FieldLabel={true}
            label="Select date range"
            selectedOption={dateRange}
            options={dataRange}
            handleChange={(e) => handleDateRange(e)}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <div className='ExportOPtions'>
            <h1>Export options</h1>
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <div className="FieldLabel">Status</div>
                  <CheckBox
                    label="All"
                    checked={userTypeAll}
                    onChange={(e) => setUserTypeAll(e.target.checked)}
                  />
                  {userTypesData?.map((item, i) => (
                    <CheckBox
                      label={item.label}
                      checked={userTypesExport.includes(item.value)}
                      onChange={(e) => userTypesHandler(e, item.value)}
                    />
                  ))}
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="FieldLabel">Gender</div>
                  {gender?.map((item, i) => (
                    <CheckBox
                      label={item.label}
                      checked={genderTypesExport.includes(item.value)}
                      onChange={(e) => genderTypeHandler(e, item.value)}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <div className="FieldLabel">Class/Level</div>
              <CheckBox
                label="All"
                checked={gradeTypeAll}
                onChange={(e) => setGradeTypeAll(e.target.checked)}
              />
              {classLevel.map((item, i) => (
                <CheckBox
                  label={item.label}
                  checked={gradeTypesExport.includes(item.value)}
                  onChange={(e) => gradeTypesHandler(e, item.value)}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Export;
