import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import { useGetAttendanceOfStudentInSectionMutation } from "../../../redux/features/classes/classApi";
import useProtected from "../../../hooks/useProtected";
import { getMonths } from "../../../hooks/HelperFunctions";


const Attendance = (props) => {

  const { section_id, student_id } = props

  const [getAttendancesOfStudnet, getAttendancesOfStudnetOptions] = useGetAttendanceOfStudentInSectionMutation()

  useProtected(getAttendancesOfStudnetOptions)

  let initialAttendnaceStatusesNumberObject = {
    present: 0,
    absent: 0,
    late: 0
  }
  const [attendances, setAttendances] = useState([])
  const [attendanceStatusesNumber, setAttendanceStatusesNumber] = useState(initialAttendnaceStatusesNumberObject)
  const [attendancesForAllMonths, setAttendancesForAllMonths] = useState([])
  const [rows, setRows] = useState([])

  const headCells = [
    { id: 'Month', numeric: false, disablePadding: true, label: 'Month', width: '35%', },
    { id: 'Present', numeric: false, disablePadding: true, label: 'Present', width: '20%', },
    { id: 'Absent', numeric: false, disablePadding: true, label: 'Absent', width: '20%', },
    { id: 'Late', numeric: false, disablePadding: true, label: 'Late', width: '20%', },
    { id: '', numeric: false, disablePadding: true, label: '', width: '0%', },
  ];

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      const sortedData = arr.sort((a, b) => {
        const dateA = new Date(`01-${a.month_year}`);
        const dateB = new Date(`01-${b.month_year}`);
        return dateB - dateA;
      });
      let months = getMonths()
      return sortedData.map((item) => {
        const [month, year] = item?.month_year.split('-')
        let monthInText = months.find((singleMonth) => singleMonth?.value == month)
        return {
          cell: [
            { textBold: `${monthInText.label} ${year}` || 'N/A' },
            { textBold: <div className="AdtStatus"><CheckCircleRoundedIcon className="GreenIcon" />{item?.present}</div> },
            { textBold: <div className="AdtStatus"><CancelRoundedIcon className="RedIcon" />{item?.absent}</div> },
            { textBold: <div className="AdtStatus"><WatchLaterRoundedIcon className="YellowIcon" />{item?.late}</div> },
            { textBold: "" },
          ]
        }
      }

      )
    }
    else {
      return [];
    }
  }

  //   {
  //     cell: [
  //       { textBold: 'January 2023' },
  //       { textBold: <div className="AdtStatus"><CheckCircleRoundedIcon className="GreenIcon" />3</div> },
  //       { textBold: <div className="AdtStatus"><CancelRoundedIcon className="RedIcon" />3</div> },
  //       { textBold: <div className="AdtStatus"><WatchLaterRoundedIcon className="YellowIcon" />3</div> },
  //       { textBold: "" },
  //     ]
  //   },

  //   {
  //     cell: [
  //       { textBold: 'January 2023' },
  //       { textBold: <div className="AdtStatus"><CheckCircleRoundedIcon className="GreenIcon" />3</div> },
  //       { textBold: <div className="AdtStatus"><CancelRoundedIcon className="RedIcon" />3</div> },
  //       { textBold: <div className="AdtStatus"><WatchLaterRoundedIcon className="YellowIcon" />3</div> },
  //       { textBold: "" },
  //     ]
  //   },

  //   {
  //     cell: [
  //       { textBold: 'January 2023' },
  //       { textBold: <div className="AdtStatus"><CheckCircleRoundedIcon className="GreenIcon" />3</div> },
  //       { textBold: <div className="AdtStatus"><CancelRoundedIcon className="RedIcon" />3</div> },
  //       { textBold: <div className="AdtStatus"><WatchLaterRoundedIcon className="YellowIcon" />3</div> },
  //       { textBold: "" },
  //     ]
  //   },

  //   {
  //     cell: [
  //       { textBold: 'January 2023' },
  //       { textBold: <div className="AdtStatus"><CheckCircleRoundedIcon className="GreenIcon" />3</div> },
  //       { textBold: <div className="AdtStatus"><CancelRoundedIcon className="RedIcon" />3</div> },
  //       { textBold: <div className="AdtStatus"><WatchLaterRoundedIcon className="YellowIcon" />3</div> },
  //       { textBold: "" },
  //     ]
  //   },
  // ];

  useEffect(() => {
    if (section_id != "" && student_id != "") {
      let data = {
        sectionId: section_id,
        studentId: student_id
      }
      showStudentAttendance(data)

    }
  }, [section_id, student_id])




  const getStatusNumber = (status) => {
    return attendances?.filter(attendance => attendance?.attendance == status)?.length
  }

  const showStudentAttendance = async (data) => {

    let result = await getAttendancesOfStudnet({ ...data })
    if (result?.data?.body?.data) {
      setAttendances(result?.data?.body?.data)
    }

  }
  const getAllMonthsAndYears = () => {
    let monthsAndYears = []

    attendances.map((attendance) => {
      let date = new Date(attendance?.date)
      const monthYearKey = `${date?.getMonth()}-${date?.getFullYear()}`;

      if (!monthsAndYears?.includes(monthYearKey)) {
        monthsAndYears.push(monthYearKey)
      }

    })
    return monthsAndYears

  }
  const getAllMonthsAndYearsData = (monthsAndYears) => {
    let allMonthsAndYearsData = []
    monthsAndYears?.map(monthYear => {

      let presentNumber = attendances?.filter((attendance) => {
        let attendanceDate = new Date(attendance?.date)
        return (monthYear == `${attendanceDate.getMonth()}-${attendanceDate.getFullYear()}` && attendance?.attendance == 'P')
      })

      let absentNumber = attendances?.filter((attendance) => {
        let attendanceDate = new Date(attendance?.date)
        return (monthYear == `${attendanceDate.getMonth()}-${attendanceDate.getFullYear()}` && attendance?.attendance == 'A')
      })
      let lateNumber = attendances?.filter((attendance) => {
        let attendanceDate = new Date(attendance?.date)
        return (monthYear == `${attendanceDate.getMonth()}-${attendanceDate.getFullYear()}` && attendance?.attendance == 'L')
      })

      let singleMonthYearData = {
        month_year: monthYear,
        present: presentNumber?.length || 0,
        absent: absentNumber?.length || 0,
        late: lateNumber?.length || 0
      }
      allMonthsAndYearsData.push(singleMonthYearData)
    })
    return allMonthsAndYearsData

  }
  useEffect(() => {
    if (attendances?.length > 0) {
      let presentNumber = getStatusNumber('P')
      let absentNumber = getStatusNumber('A')
      let lateNumber = getStatusNumber('L')
      setAttendanceStatusesNumber({
        ...attendanceStatusesNumber,
        present: presentNumber,
        absent: absentNumber,
        late: lateNumber
      })
      let allMonthsAndYears = getAllMonthsAndYears()
      if (allMonthsAndYears.length > 0) {
        let allMonthsAndYearsData = getAllMonthsAndYearsData(allMonthsAndYears)
        setAttendancesForAllMonths(allMonthsAndYearsData)
      }

    }

  }, [attendances])

  useEffect(() => {
    setRows(convertDataToCellRows(attendancesForAllMonths))
  }, [attendancesForAllMonths])
  return (
    <>

      <div className="AttendanceStatus">
        <li>
          <div className="AttendanceStatusIcon green">
            <CheckCircleRoundedIcon />
          </div>
          <div className="AttendanceStatusInfo">
            <p>Present</p>
            <h2>{attendanceStatusesNumber?.present}</h2>
          </div>
        </li>

        <li>
          <div className="AttendanceStatusIcon red">
            <CancelRoundedIcon />
          </div>
          <div className="AttendanceStatusInfo">
            <p>Absent</p>
            <h2>{attendanceStatusesNumber?.absent}</h2>
          </div>
        </li>

        <li>
          <div className="AttendanceStatusIcon yellow">
            <WatchLaterRoundedIcon />
          </div>
          <div className="AttendanceStatusInfo">
            <p>Late</p>
            <h2>{attendanceStatusesNumber?.late}</h2>
          </div>
        </li>
      </div>

      <div className="TableSection StudentAdtnTable">
        <Table
          innerTitle="Monthly attendance"
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          RowsPerPage={10}

          emptyTableTitle="No Monthly attendance added yet"
          emptyTableNote="No items available"
          btnLabel="New Course"
          showButton={true}



        />
      </div>


    </>
  );
};

export default Attendance;


