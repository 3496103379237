import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import {
  useGetSearchResultMutation,
  useGetUsersByRoleQuery,
  useGetSearchByRoleAndTermResultMutation
} from "../../../redux/features/users/userApi";


const AddStudents = ({ setCourseUsers, courseUsers, addCourseUsersOptions, setCourseUsersToUpdate, courseUsersToUpdate, alreadyAddedStudents }) => {

  const { data, isError, isLoading, error } = useGetUsersByRoleQuery('student', { refetchOnMountOrArgChange: true });
  useProtected(error);

  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [students, setStudents] = useState([])
  const [getSearchedUserListByRoleAndTerm, options] = useGetSearchByRoleAndTermResultMutation();

  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Full Name", width: "40%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "ID #", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "User Type", width: "25%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Class/Level", width: "15%", textAlign: "left", },

  ];

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map(item => ({
        cell: [
          { Check: true, width: "34px", user_id: item._id, user: { name: `${item?.firstName} ${item?.lastName}`, id: item._id, email: item?.email, status: item?.status } },
          {
            textBold: `${item.firstName} ${item.lastName}`,
            user: {
              name: `${item?.firstName} ${item?.lastName}`,
              id: item._id,
              email: item?.email,
              status: item?.status
            },
            ...((item?.status === 'active') ?
              {
                Status: true,
                background: '#EDF7EA',
                color: '#66BD50',
                label: 'Active',
              } :
              {
                Status: true,
                background: '#FCE7E9',
                color: '#E63946',
                label: 'Inactive',
              }
            )
          },
          // {
          //   textBold: `${item.firstName} ${item.lastName}`, user: { name: `${item?.firstName} ${item?.lastName}`, id: item._id, email: item?.email, status: item?.status },
          //   Status: true, background: '#EDF7EA', color: '#66BD50', label: 'Active',
          // },
          //{ textBold: `${item.firstName} ${item.lastName}`, Check: true, Status: true, background: '#EDF7EA', color: '#66BD50', label: 'Inactive', },
          { text: item?.userUniqueID || "N/A" },
          { text: item?.role || "N/A" },
          // { text: item?.grade.join(', ') || "N/A" },
        ]
      }))
    }
    else {
      return [];
    }
  }

  useEffect(() => {
    let toShow = []
    if (!isLoading && !isError) {
      data?.body?.data?.map((user) => {
        let newStudent = alreadyAddedStudents?.find((singleStudent) => singleStudent?.id === user._id)
        if (!newStudent) toShow.push(user)
      })
      setRows(convertDataToCellRows(toShow));
      setStudents(toShow)
    }
  }, [data, alreadyAddedStudents])



  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }

  const handleSearch = async () => {
    const result = await getSearchedUserListByRoleAndTerm({ role: 'student', term: searchTerm });
    console.log({ result })
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  }


  // Filter //
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };
  const handleCloseFilterDrawer = () => {
    setOpenFilter(false);
  };


  useEffect(() => {
    let addSudents = []
    courseUsers?.map((users) => {
      const findStudents = students?.find((studentId) => studentId?._id === users)
      if (findStudents) {
        addSudents.push({
          id: findStudents?._id,
          name: `${findStudents?.firstName} ${findStudents?.lastName}`,
          email: findStudents?.email,
          role: findStudents?.role,
          status: findStudents?.status,
          userUniqueId: findStudents?.userUniqueID,
          classLevel: findStudents.classLevel,
          gender: findStudents.gender
        })
      }
    })
    setCourseUsersToUpdate(addSudents)
  }, [courseUsers])

  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setCourseUsers(prevUsers => [...prevUsers, item[0].user]);
      // setCourseUsersToUpdate(prevUsers => [...prevUsers, item[0].user]);
    } else {
      // Remove the user from the array
      setCourseUsers(prevUsers => prevUsers.filter(user => user._id !== item[0].user._id));
      // setCourseUsersToUpdate(prevUsers => prevUsers.filter(user => user.id !== item[0].user.id));
    }

  }

  return (
    <>
      <div className="TableSection">
        <Table
          tableHeader={true}
          headCells={headCells}
          rows={rows}
          onClick={handleFilterDrawerOpen}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          innerHeader={true}
          showSearchBox={true}
          handleRowClick={handleRowClick}
          searchBoxPlaceholder="Search Users"
          handleCheckItem={setCourseUsers}
          selectedCheckList={courseUsers}
          customCellObjectId="user_id"
          isDataLoading={isLoading || options?.isLoading || addCourseUsersOptions?.isLoading}
          RowsPerPage={15}
          CheckboxDelete={true}

        />
      </div>

    </>
  );
};

export default AddStudents;
