import React from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { withStyles } from '@material-ui/core/styles';



import './SwitchButtonIOS.css';



{/* <SwitchButtonIOS
  title="Graded exam certificate"
  subtitle="Students can earn certificate submitting exam"
  BorderLine={true}
  ComingSoon={true}
  handleChange={handleChange}
  disabled
  checked={state.checkedB }
/> */}


function SwitchButtonIOS(props) {

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <div className="SwitchButtonIOS">
        <div className="SwitchLeft">
          <h1>{props.title} {props.ComingSoon && <span>Coming Soon</span>}</h1>
          <p>{props.subtitle}</p>
        </div>

        <Switch
          checked={props.checked}
          onChange={props.handleChange}
          color="primary"
          name={props.name}
          disabled={props.disabled}
          inputProps={{ 'aria-label': 'primary checkbox' }}

        />
        {props.label &&
          <p className="IOSSwitchLabel">{props.label}</p>
        }
      </div>
    </>
  );
}


export default SwitchButtonIOS;

