import React from 'react';

import EditAbleTable from '../../../components/Table/EditAbleTable';
import { useEffect } from 'react';
import { useState } from 'react';
import { capitalizeFirstLetter } from '../../../components/hooks/HelperFunctions';
import { isValidEmail } from '../../../hooks/HelperFunctions';
import { useCheckEmailMutation, useCreateInviteUserMutation } from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import { useFetcher, useNavigate } from 'react-router-dom';
import { Button, Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from 'react-redux';
import DraggableDialog from '../../../components/hooks/Dialog';
import { isTheActionPermitted } from '../../../hooks/HelperFunctionsPermission';




function InviteNewUser(props) {

  const { setMatchColumns, setInviteUserButtonEnableDisable, roles, setActiveTab, importedUsers, setImportedUsers, selectedUserType, emailIndex, firstNameIndex, middleNameIndex, LastNameIndex, setSelectedUserType } = props


  const schoolInfo = useSelector(state => state.auth);

  const [checkEmail, checkEmailOptions] = useCheckEmailMutation();
  const [createUser, createUserOptions] = useCreateInviteUserMutation();

  useProtected(checkEmailOptions.error)
  useProtected(createUserOptions.error)
  useEffect(() => {
    setInviteUserButtonEnableDisable(true)
  }, [])




  //======= Leave this page dialog ===== //
  const [leaveDialog, setOpenDialogleave] = useState(false);
  const handleOpenleave = () => {
    setOpenDialogleave(true);
  };
  const handleCloseDialogleave = () => {
    setOpenDialogleave(false)
  };
  //======= Leave this page dialog ===== //
  const [age, setAge] = React.useState('');
  const SelectHandleChanges = (event) => {
    setAge(event.target.value);
  };
  const navigate = useNavigate()

  const onChangeHandler = (e, fieldName, index) => {
    const updatedUsersData = [...usersData];
    updatedUsersData[index][fieldName] = e.target.value;
    if (fieldName === 'type') {
      updatedUsersData[index]['role'] = (e.target.value === 'parent' || e.target.value === 'student') ? '' : updatedUsersData[index]['role']
    }
    setUsersData(updatedUsersData);
  }





  const handleremove = (index) => {
    const updatedUsersData = usersData?.filter((user, i) => {
      return i !== index
    })
    setUsersData(updatedUsersData);
  }

  let initialObject = {
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    type: '',
    emailError: '',
    firstNameError: '',
    lastNameError: '',
    typeError: ''
  }
  const addNewUser = () => {
    const updatedUsersData = [...usersData, initialObject];
    setUsersData(updatedUsersData);
  }



  const [middleNameStatus, setMiddleNameStatus] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState('');
  const [emailError, setEmailError] = useState('')
  const [rolesOptions, setRolesOptions] = useState([])
  const [rows, setRows] = useState([])
  const [alertOptions, setAlertOptions] = useState({
    msg: '',
    type: 'error',
  })
  const [usersData, setUsersData] = useState([initialObject]);

  // useEffect(() => {
  //   if (importedUsers?.length > 0) {
  //     let newUsersData = []
  //     importedUsers?.map((user) => {
  //       console.log(user[0], 483984934938493839)
  //       let newObj = { ...initialObject }

  //       newObj.email = user[0] || ''
  //       newObj.firstName = user[1] || ''
  //       newObj.lastName = user[2] || ''
  //       newObj.middleName = user[3] || ''
  //       newUsersData.push(newObj)

  //     })
  //     setUsersData(newUsersData);
  //   }
  // }, [importedUsers])

  useEffect(() => {
    if (importedUsers?.length > 0) {
      let newUsersData = []
      importedUsers?.map((user) => {
        let newObj = { ...initialObject, type: selectedUserType }
        newObj.email = user[emailIndex] || ''
        newObj.firstName = user[firstNameIndex] || ''
        newObj.middleName = user[middleNameIndex] || ''
        newObj.lastName = user[LastNameIndex] || ''
        newUsersData.push(newObj)
      })
      setUsersData(newUsersData);
    }
  }, [importedUsers])


  useEffect(() => {
    setRows(converDataToCell(usersData))
  }, [usersData])

  useEffect(() => {
    setRolesOptions(getPreparedArray(roles))
  }, [roles])

  const getPreparedArray = (data) => {
    return data?.map((item) => ({
      value: item?.name,
      label: capitalizeFirstLetter(item?.name)
    }));
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const checkEmailHandler = async (event, obj, index) => {
    let error = 0
    if (!isValidEmail(event.target.value) || event.target.value.trim() === '') {
      obj.emailError = "Enter a valid email address"
      error++
    }
    let result = await checkEmail({ email: (event.target.value === '') ? '***' : event.target.value })
    if (result?.data && result?.data?.error) {
      obj.emailError = "Email already exists"
      error++
    }
    if (error === 0) obj.emailError = ""

    let newUserData = usersData.map((userObj, i) => {
      if (i === index) {
        return obj
      } else {
        return userObj
      }
    })
    setUsersData(newUserData)
  }

  const checkEmailOnSubmit = async (val, obj, index) => {
    let emailError = ""
    if (!isValidEmail(val) || val.trim() === '') {
      emailError = "Enter a valid email address"
      return emailError;
    }
    let result = await checkEmail({ email: (val === '') ? '***' : val })
    if (result?.data && result?.data?.error) {
      emailError = "Email already exists"
      return emailError;
    }
    return emailError;
  }


  const converDataToCell = (data) => {
    if (data?.length > 0) {
      let cells = data?.map((singleData, index) => {
        //selectedUserType from import users
        if (selectedUserType) {
          singleData.type = selectedUserType
        }
        // console.log(singleData.emailError, 90)
        return {
          cell: [
            {
              TextField: true,
              onChange: (e) => onChangeHandler(e, 'email', index),
              TextFieldValue: singleData?.email,
              placeholder: "e.g. abdullah@gmail.com",
              onBlur: (e) => checkEmailHandler(e, singleData, index),
              textTransform: "lowercase",
              error: (singleData?.email.length === 0 || singleData.emailError != "" ? singleData.emailError != "" ? "error" : "" : ""),

              // error: (singleData.emailError != "") ? "error" : "",
              errorTooltip: (singleData.emailError != "") ? true : false,
              tooltipMessage: (singleData.emailError != "") ? singleData.emailError : ""
            },
            {
              TextField: true,
              onChange: (e) => onChangeHandler(e, 'firstName', index),
              TextFieldValue: singleData?.firstName,
              placeholder: "First name",
              textTransform: "capitalize",
              error: (singleData?.firstName.length === 0 ? singleData.firstNameError != "" ? "error" : "" : ""),

            },
            {
              TextField: true,
              onChange: (e) => onChangeHandler(e, 'middleName', index),
              TextFieldValue: singleData?.middleName,
              placeholder: "Middle name",
              textTransform: "capitalize"
            },
            {
              TextField: true,
              onChange: (e) => onChangeHandler(e, 'lastName', index),
              TextFieldValue: singleData?.lastName,
              placeholder: "Last name",
              textTransform: "capitalize",
              error: (singleData?.lastName.length === 0 ? singleData.lastNameError != "" ? "error" : "" : ""),

            },
            {
              SelectFiled: true,
              options: options,
              // options: (singleData?.role == '') ? options : options.filter(option => (option.value !== 'parent' && option.value !== 'student')),
              SelectHandleChanges: (e) => onChangeHandler(e, 'type', index),
              selectedOption: singleData.type,
              // selectedOption: selectedUserTypeData,
              placeholder: "Select user type",
              error: (singleData?.type.length === 0 ? singleData.typeError != "" ? "error" : "" : ""),
              // error: (singleData.typeError != "") ? "error" : "",
            },
            { RemoveButton: true, index }

          ]
        }
      }
      )
      setSelectedUserType('')
      return cells
    } else {
      return [];
    }
  }

  const options = [
    {
      label: "Parent",
      value: "parent",
    },

    {
      label: "Student",
      value: "student",
    },
    {
      label: "Faculty",
      value: "faculty",
    },
    {
      label: "Staff",
      value: "staff",
    }
  ]


  const headCells = [
    { label: "Email", width: "25%", },
    { label: "First Name", width: "15%" },
    { label: "Middle Name", width: "15%" },
    { label: "Last Name", width: "15%" },
    { label: "User Type", width: "15%" },
    { label: "", width: "15%", },
  ];

  const isDataValidated = (data) => {
    // if (!data.schoolId) return false;
    for (const item of data.users) {
      for (const [key, val] of Object.entries(item)) {
        if ((key === 'email' || key === 'firstName' || key === 'lastName' || key === 'type') && !val) return false;
        // if ((key === 'email' || key === 'role') && !val) return false;
      }
    }
    return true;
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOptions({
      msg: '',
      type: '',
    });
  };

  const checkAll = async () => {
    let errorNumber = 0

    let newUsersArray = [...usersData]

    for (let i = 0; i < newUsersArray.length; i++) {
      let isEmailValid = await checkEmailOnSubmit(newUsersArray[i].email, newUsersArray, i)
      if (isEmailValid != "") {
        newUsersArray[i].emailError = isEmailValid
        errorNumber++;
      } else {

        newUsersArray[i].emailError = ""
      }
      if (newUsersArray[i].firstName === "") {
        newUsersArray[i].firstNameError = "Required"
        errorNumber++;
      } else {
        newUsersArray[i].firstNameError = ""
      }
      if (newUsersArray[i].lastName === "") {
        newUsersArray[i].lastNameError = "Required"
        errorNumber++;
      } else {
        newUsersArray[i].lastNameError = ""
      }
      if (newUsersArray[i].type === "") {
        newUsersArray[i].typeError = "Required"
        errorNumber++;
      } else {
        newUsersArray[i].typeError = ""
      }
    }
    setUsersData(newUsersArray)

    return (errorNumber > 0) ? false : true
  }

  const handleAddInviteUser = async () => {
    if (!isTheActionPermitted('inviteUsers')) {
      toast.error("You don't have access to this feature")
      return false
    }
    let isValid = await checkAll();

    let usersNewData = usersData?.map(({ email, name, firstName, lastName, type }) => ({ email, name, firstName, lastName, userType: type }));

    try {
      const data = {
        schoolId: schoolInfo?.user?.schoolId,
        invitedBy: schoolInfo?.user?._id,
        users: usersNewData
      };

      if (!isValid) {
        toast.error("Please fill all the required fields")
        return;
      }

      const result = await createUser(data);
      if (!result?.data?.error) {
        toast.success("Invitation sent successfully");
        setActiveTab(0)
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };


  return (
    <div className="InviteNewUser">

      <EditAbleTable
        headCells={headCells}
        rows={rows}
        SubmitButtonLabel="Send invitation"
        handleAddNew={addNewUser}
        handleremove={handleremove}
        SubmitOnClick={handleAddInviteUser}
        usersData={usersData}

      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={checkEmailOptions.isLoading}
      // onClick={() => setPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


      {/* Delete Group*/}
      <DraggableDialog
        openDialog={leaveDialog}
        handleCloseDialog={handleCloseDialogleave}
        //handleConfirmCloseDialog={deleteUserHandler}
        title="Leave this page?"
        body={<>It looks like you’re in the middle of inviting users. <br /> You will loose these info if you leave.</>}
        ModalFooter={true}
        actionButton="Leave"
        cancelButton="Stay"
      />

    </div>


  );
}

export default InviteNewUser;
