import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useGetSearchResultMutation, useGetUserListQuery } from "../../../redux/features/users/userApi";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import CreateSchoolYear from './CreateClassesLevel';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddIcon from '@mui/icons-material/Add';
import { useGetClassLevelListQuery, useAddClassLevelMutation, useGetClassSearchResultMutation } from "../../../redux/features/classes/classApi";
import { toast } from "react-toastify";
import { validateFormData } from "../../../utils/form";
import { toUpperCase } from "../../../utils/helpers";
import { useCheckClassLevelNameMutation } from '../../../redux/features/classes/classApi';
import EmptyCard from "../../../components/EmptyCard/EmptyCard";

const ClassesLevel = () => {
  const { data, isError, isLoading, error } = useGetClassLevelListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [addClassLevel, addClassLevelOptions] = useAddClassLevelMutation()
  const [getSearchedClassList, options] = useGetClassSearchResultMutation();
  const [checkClassLevelName, checkClassLevelNameOptions] = useCheckClassLevelNameMutation()

  useProtected(error);
  useProtected(addClassLevelOptions.error);
  useProtected(checkClassLevelNameOptions.error);

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
    }
  }, [data, isLoading, isError])


  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({
    name: ''
  });


  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr.map((item) => ({
        cell: [
          { textBold: item?.name ? <b>{toUpperCase(item?.name)}</b> : 'N/A' },
          { text: item?.description ? item?.description : 'N/A' },
          { textBold: "", },
          {
            Button: true,
            buttonLabel: 'Details',
            buttonTextcolor: '#004FE0',
            buttonLink: `/classes-level-details/${item?._id}`,
          },
        ],
      }));
    } else {
      return [];
    }

  };


  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Description", width: "60%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "left", },

  ];


  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedClassList({ term: "" });
    searchDataShowing(result)
  };
  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedClassList({ term: searchTerm });
    searchDataShowing(result)
  }
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  // =========== search part start ====================== //

  // Filter //
  const [openCreateDrawer, setOpenCreate] = useState(false);
  const handleCreateDrawerOpen = () => {
    setOpenCreate(true);
  };
  const handleCloseCreateDrawer = () => {
    setName('')
    setDescription('')
    setErrors(' ')
    setOpenCreate(false);
  };



  const status = [
    { id: 1, label: "In Progress" },
    { id: 2, label: "Dropped" },
    { id: 3, label: "Blocked" },
    { id: 4, label: "Completed" },
    { id: 5, label: "Unavailable" },
  ];
  // Filter //


  const handleAddClassLevel = async () => {
    let classLevelData = {
      name,
      description
    }


    const isValid = validateFormData(classLevelData, setErrors, {
      name: 'Enter class/level name'
    });
    if (!isValid) {
      return;
    }

    let checkResult = await checkClassLevelName({ name })

    if (checkResult?.data && checkResult?.data?.error) {
      setErrors({
        'name': 'Name already exist'
      })
      return false
    }

    let result = null;

    result = await addClassLevel(classLevelData)


    if (result?.data) {
      toast.success("Class/Level created")
      setName('')
      setDescription('')

    } else {
      toast.error(result?.data?.message);
    }

    handleCloseCreateDrawer()
  }

  return (
    <>
      <div className="TableSection">
        {
          rows?.length > 0 ?
            <Table
              title="Class/Level"
              tableHeader={true}
              addLabel="New Class/Level"
              icon={<AddIcon />}
              AddButton={true}
              headCells={headCells}
              rows={rows}
              onClick={handleCreateDrawerOpen}
              footer={true}
              handleSearchText={handleSearchText}
              handleSearch={handleSearch}
              setResetSearchTerm={setResetSearchTerm}
              setSearchTerm={setSearchTerm}
              resetsearchTerm={resetsearchTerm}
              pageNumberFromOutSide={pageNumberFromOutSide}
              innerHeader={true}
              SearchBoxTop={true}
              searchBoxPlaceholder="Search classes/levels"
              isDataLoading={isLoading || options?.isLoading}
              RowsPerPage={20}

              emptyTableTitle="No class/level added yet"
              emptyTableNote="No items available"
              btnLabel="New Class/Level"
              showButton={true}
              onClickEmptyCard={handleCreateDrawerOpen}
            />
            :
            <EmptyCard
              TableTitleStatus={true}
              TableTitle="Class/Level"
              title="No class/level added yet"
              description="Added class/level will be visible here"
              showButton={true}
              btnLabel="New Subject area"
              onClick={handleCreateDrawerOpen}
            // btnLink={btnLink} 
            />
        }
      </div>

      {/* Filter */}
      <SideDrawer
        open={openCreateDrawer}
        handleDrawerClose={handleCloseCreateDrawer}
        title="Create Class/Level"
        buttonLabel="Create Now"
        sideOpen="right"
        ButtonLabel="Create Now"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={(e) => handleAddClassLevel()}
        body={
          <>
            <CreateSchoolYear
              name={name}
              setName={setName}
              description={description}
              setDescription={setDescription}
              errors={errors}
              setErrors={setErrors} />
          </>
        }
      />

    </>
  );
};

export default ClassesLevel;
