import React, { useState } from "react";
import Grid from '@mui/material/Grid';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SwitchButtonIOS from '../../../components/Form/SwitchButtonIOS/SwitchButtonIOS';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lime, purple, lightGreen } from '@mui/material/colors';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import dayjs from "dayjs";

// const theme = createTheme({
//   palette: {
//     success: lightGreen,
//   },
// });

const theme = createTheme({
  palette: {
    success: {
      main: '#0fa711',
    },
  },
});



function Communication(props) {
  let {
    communicationAbsentStudentActivity,
    setCommunicationAbsentStudentActivity,
    communicationAbsentStudentEmail,
    setCommunicationAbsentStudentEmail,
    communicationAbsentStudentMessage,
    setCommunicationAbsentStudentMessage,
    communicationAbsentStudentTime,
    setCommunicationAbsentStudentTime
  } = props;

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };


  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>

        <Grid item md={12} xs={12}>
          <div className="ABsentSwitchCol">
            <div className="AbsLeft">
              <h1>Absent students</h1>
              <p>Send notification to the guardian/parents of absent students.</p>
            </div>
            <SwitchButtonIOS
              label={(communicationAbsentStudentActivity === "true") ? "Enabled" : "Disabled"}
              BorderLine={true}
              handleChange={(e) => setCommunicationAbsentStudentActivity((e.target.checked) ? 'true' : 'false')}
              checked={(communicationAbsentStudentActivity === "true") ? true : false}
              name="checkedB"
            />
          </div>

        </Grid>

        {(communicationAbsentStudentActivity === "true") && <Grid item md={12} xs={12}>
          <Typography className="AccTitle">
            Method
          </Typography>

          <CheckBox
            label="Email"
            color="success"
            checked={(communicationAbsentStudentEmail === "true") ? true : false}
            onChange={(e) => setCommunicationAbsentStudentEmail((e.target.checked) ? 'true' : 'false')}
          />

          <CheckBox
            label="Message"
            // sx={{ "& .MuiButtonBase-root.MuiCheckbox-root": { color: '#10A711 !important' } }}
            color="success"
            checked={(communicationAbsentStudentMessage === "true") ? true : false}
            onChange={(e) => setCommunicationAbsentStudentMessage((e.target.checked) ? 'true' : 'false')}
          />

          {((communicationAbsentStudentEmail === "false" && communicationAbsentStudentMessage === "false") && communicationAbsentStudentActivity === "true") &&
            <Typography className="AccTitle"  >
              <span>Please select an option.</span>
            </Typography>}
        </Grid>}




        {(communicationAbsentStudentActivity === "true") &&
          <Grid item md={6} xs={12}>
            <div className="AccTitleCont" style={{ marginTop: "-10px" }}>
              <div className="FieldLabel AccTitleSelect">Select time</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['TimePicker']}>
                  <TimePicker defaultValue={dayjs(`2022-04-17T${communicationAbsentStudentTime}`)} onChange={(e) => setCommunicationAbsentStudentTime(`${e.$H}:${e.$m}`)} />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </Grid>}

        {(communicationAbsentStudentActivity === "true") &&
          <Grid item md={12} xs={12}>
            <div className='MediaLibPanelInfoText'><InfoOutlinedIcon /> Email will be sent only on school days.</div>
          </Grid>}





      </Grid >

    </ThemeProvider>

  );
};

export default Communication;
