import React, { useEffect, useState, useCallback } from "react";
import "../../../assets/css/users.css";
import VerticalTab from "../../../components/VerticalTab/VerticalTab";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { RiContactsBookFill } from "react-icons/ri"
import Button from "@mui/material/Button";
import HomeIcon from '@material-ui/icons/Home'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { toTitleCase } from "../../../utils/helpers/index"

import Overview from "./Overview";
import Applications from "./Applications";
import { RxPerson } from "react-icons/rx";
import { RxCalendar } from "react-icons/rx";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { useParams } from "react-router-dom";
import { useGetUserDetailsQuery, useReturnAccessMutation, useRevokeAccessMutation } from "../../../redux/features/users/userApi";
import { useGetApplicationListByUserIdQuery } from "../../../redux/features/applications/applicationApi";
import useProtected from "../../../hooks/useProtected";

import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import GuardiansParents from './GuardiansParents';
import GuardianStudents from './GuardianStudents';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import UsersProfileEdit from "./UsersProfileEdit";
import { toast } from "react-toastify";
import { useUpdateUserMutation } from "../../../redux/features/users/userApi";
import { filterTruthyValues, mapMonth } from "../../../utils/helpers";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/features/authSlice";


import AddRoundedIcon from '@mui/icons-material/AddRounded';

import Buttons from '../../../components/Form/Button/Button';
import MediaLibrarySelectMedia from "../../../components/MediaLibrary/MediaLibrarySelectMedia";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';

import Modals from '../../../components/Form/Modals/Modals';
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';


import img from '../../../assets/imgs/randomPerson.avif';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';


import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";

import { BiSolidIdCard } from 'react-icons/bi';

import Identification from './Identification';
import DraggableDialog from "../../../components/hooks/Dialog";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import EmergencyContacts from "./EmergencyContacts";
import { useFindGurdianMutation, useFindGurdianQuery } from "../../../redux/features/users/guardianApi";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";






const UsersDetails = (props) => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const [updateUser, updateUserOptions] = useUpdateUserMutation();
	const [revokeAccess, revokeAccessOptions] = useRevokeAccessMutation()
	const [returnAccess, returnAccessOptions] = useReturnAccessMutation()

	const { data, isLoading, isError, error, refetch } = useGetUserDetailsQuery(id, {
		refetchOnMountOrArgChange: true,
	});
	const {
		data: applicationListResponse,
		isLoading: applicationResponseLoading,
		isError: applicationResponseIsError,
		error: applicationResponseError,
	} = useGetApplicationListByUserIdQuery(id, {
		refetchOnMountOrArgChange: true,
	});

	useProtected(error);
	useProtected(applicationResponseError);
	useProtected(revokeAccessOptions.error);
	useProtected(returnAccessOptions.error);

	const [personalInfo, setPersonalInfo] = useState([]);

	const [editProfile, setEditProfile] = useState(false);
	const [errors, setErrors] = useState({
		firstNameError: "",
		lastNameError: ""
	})

	useEffect(() => {
		if (!isLoading && data && !error) {
			setPersonalInfo(data?.body?.data)
		}
	}, [data, error, isLoading])

	// ========== find gurdian =============================== //
	const [userUniqueIdData, setUserUniqueIdData] = useState('');

	useEffect(() => {
		setUserUniqueIdData(personalInfo?.userUniqueID)
	}, [personalInfo])
	// console.log(userUniqueIdData)






	const [currentTab, setCurrentTab] = useState(0);
	const [userDetails, setUserDetails] = useState(null);
	const [applicationList, setApplicationList] = useState([]);
	const [userEditDrawer, setUserEditDrawer] = useState(false);
	const [formData, setFormData] = useState(null)
	const [openSideDrawer, setOpenSideDrawer] = useState(false);
	const [birthDate, setBirthDate] = useState(null);
	const [birthMonth, setBirthMonth] = useState(null);
	const [birthYear, setBirthYear] = useState(null);
	const handleTabSwitch = (activeTab) => {
		console.log(activeTab);
		setCurrentTab(activeTab);
	};

	const [openDialogRevokeUser, setOpenRevokeUser] = useState(false);
	const handleOpenRevokeUser = () => {
		if (!isTheActionPermitted('editRevokeUsers')) {
			toast.error("You don't have access to this feature")
			return false
		}
		setOpenRevokeUser(true);
	};
	const handleCloseRevokeUser = () => {
		setOpenRevokeUser(false);
	};

	const [openDialogReturnUser, setOpenReturnUser] = useState(false);
	const handleOpenReturnUser = () => {
		setOpenReturnUser(true);
	};
	const handleCloseReturnUser = () => {
		setOpenReturnUser(false);
	};


	useEffect(() => {
		if (!isLoading && !isError) {
			setUserDetails(data?.body?.data);
		}
	}, [data, isLoading, isError]);

	useEffect(() => {
		if (!applicationResponseLoading && !applicationResponseIsError) {
			setApplicationList(applicationListResponse.body.data);
		}
	}, [
		applicationListResponse,
		applicationResponseLoading,
		applicationResponseIsError,
	]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};



	useEffect(() => {
		console.log(birthYear, birthMonth, birthDate)
		setPersonalInfo({ ...personalInfo, [birthDate]: new Date(birthYear, birthMonth - 1, birthDate) })
	}, [birthYear, birthMonth, birthDate])

	const handleUpdateProfile = async (profileInfo) => {
		const data = {
			firstName: profileInfo?.firstName,
			middleName: profileInfo?.middleName,
			lastName: profileInfo?.lastName,
			gender: profileInfo?.gender,
			profilePic: profileInfo?.profilePic,
			nysedId: profileInfo?.nysedId,
			country: profileInfo?.country,
			address: profileInfo?.address,
			addressLine2: profileInfo?.addressLine2,
			city: profileInfo?.city,
			state: profileInfo?.state,
			zip: profileInfo?.zip,
			highestEducation: profileInfo?.highestEducation,
			occupation: profileInfo?.occupation,
			phone: profileInfo?.phone,
			birthDate: new Date(birthYear, birthMonth - 1, birthDate),
			idPicture: profileInfo?.idPicture,
			idPictureName: profileInfo?.idPictureName,
			// birthDate: (profileInfo?.birthMonth && profileInfo?.birthYear) ? new Date(profileInfo?.birthYear, typeof profileInfo?.birthMonth === 'string' ? mapMonth(profileInfo?.birthMonth) : profileInfo?.birthMonth, 1) : profileInfo?.birthDate,
			timezones: profileInfo?.timezones
		}

		//validation start
		if (data?.firstName === "" && data?.lastName === "") {
			setErrors({ ...errors, firstName: "Required", lastName: "Required" })
			return
		} else {
			setErrors({ ...errors, firstName: "", lastName: "" })
		}
		if (data?.firstName === "") {
			setErrors({ ...errors, firstName: "Required" })
			return
		} else {
			setErrors({ ...errors, firstName: "" })
		}
		if (data?.lastName === "") {
			setErrors({ ...errors, lastName: "Required" })
			return
		} else {
			setErrors({ ...errors, lastName: "" })
		}
		//validation end 


		const result = await updateUser({ id, ...data });

		if (!result?.data?.error) {
			// setFormData(personalInfo);
			const response = await refetch();
			if (!response?.data?.error) {
				const data = { ...response.data.body.data, birthMonth: response.data.body?.birthDate ? moment(response.data.body.birthDate).month() : "", birthYear: response.data.body?.birthDate ? moment(response.data.body.birthDate).year() : "" }
				setFormData(data);
			}
			toast.success("User information updated")
		} else {
			toast.error(result.data.message)
		}
		drawerClose();
	};

	const drawerClose = () => {
		setEditProfile(false)
		setOpenSideDrawer(false)
	}
	const handleEditProfile = () => {
		setEditProfile(true);
	}
	useEffect(() => {
		// const data = { ...personalInfo, birthMonth: personalInfo?.birthDate ? moment(personalInfo.birthDate).month() : "", birthYear: personalInfo?.birthDate ? moment(personalInfo.birthDate).year() : "" }
		// setFormData(data)

		const dateObj = (personalInfo?.birthDate) ? new Date(personalInfo?.birthDate) : new Date();

		const year = dateObj.getFullYear();
		const month = dateObj.getMonth() + 1; // Adding 1 since getMonth() returns 0-based index
		const day = dateObj.getDate();

		if (!birthDate && personalInfo?.birthDate) setBirthDate(day)
		if (!birthMonth && personalInfo?.birthDate) setBirthMonth(month)
		if (!birthYear && personalInfo?.birthDate) setBirthYear(year)


		// let temp = {...personalInfo}
		// temp.birthMonth = month
		// temp.birthYear = year
		// temp.birthDate = day
		// setPersonalInfo()
	}, [personalInfo])

	const breadcrumbsData = [
		{
			label: "Users",
			link: "/users",
			// icon: <PersonOutlineRoundedIcon fontSize="small" />,
		},

		{
			label: "Details",
			link: "#",
		},

	];

	// <Breadcrumbs
	//   breadcrumbsData={breadcrumbsData}
	// />



	// const [dynamicTabBody, setDynamicTabBody] = useState([])
	const [guardianListData, setGuardianListData] = useState([])


	let tabAndBodyTogether = [
		{
			icon: <PermIdentityOutlinedIcon />,
			label: "Overview",
			panel: <Overview userDetails={userDetails} isLoading={isLoading} />
		},

		{
			icon: <BiSolidIdCard />,
			label: "Identification",
			panel: <Identification
				handleUpdateProfile={handleUpdateProfile}
				userDetails={userDetails}
				setUserDetails={setUserDetails}
				personalInfo={personalInfo}
				id={id}
				isLoading={isLoading}
				refetch={refetch}
			/>
		},
		{
			icon: <PeopleAltRoundedIcon />,
			label: "Students",
			panel: <GuardianStudents
				userUniqueID={userDetails?.userUniqueID}
				userId={userDetails?._id}
				guardianList={userDetails?.gurdians || []} />
		},
		{
			icon: <PeopleAltRoundedIcon />,
			label: "Guardians/Parents",
			panel: <GuardiansParents
				userUniqueID={userDetails?.userUniqueID}
				userId={userDetails?._id}
				guardianList={userDetails?.gurdians || []}
				userDetails={userDetails || []} />
		},
		{
			icon: <RiContactsBookFill />,
			label: "Emergency Contacts",
			panel: <EmergencyContacts
				userUniqueID={userDetails?.userUniqueID}
				userId={userDetails?._id}
				emergencyContacts={userDetails?.emergencyContacts || []}
				isLoading={isLoading} />
		},
	]

	const [tabData, setTabdata] = React.useState([]);


	const [tabBody, setTabBody] = useState([])

	useEffect(() => {
		let tempTabData = []
		let tempTabBody = []
		let i = 0
		for (i; i < tabAndBodyTogether?.length; i++) {
			if (userDetails?.userType === "student" && tabAndBodyTogether[i].label === "Students") {
				continue
			}
			if ((userDetails?.userType === "parent" || userDetails?.userType === "faculty") && tabAndBodyTogether[i].label === "Guardians/Parents") {
				continue
			}
			if (tabAndBodyTogether[i].label === "Guardians/Parents" && !isTheActionPermitted('manageGuardiansParents')) {
				continue
			}
			// if (userDetails?.userType === "parent" && tabAndBodyTogether[i].label === "Identification") {
			// 	continue
			// }
			if ((userDetails?.userType === "parent" || userDetails?.userType === "faculty" || userDetails?.userType === "staff" || userDetails?.userType === "teacher") && tabAndBodyTogether[i].label === "Emergency Contacts") {
				continue
			}
			if (tabAndBodyTogether[i].label === "Emergency Contacts" && !isTheActionPermitted('manageEmergencyContacts')) {
				continue
			}
			if (tabAndBodyTogether[i].label === "Identification" && !isTheActionPermitted('manageIdentification')) {
				continue
			}



			tempTabData.push({
				icon: tabAndBodyTogether[i].icon,
				label: tabAndBodyTogether[i].label
			})
			tempTabBody.push({
				id: tempTabData.length - 1,
				panel: tabAndBodyTogether[i].panel
			})
		}
		setTabdata(tempTabData)
		setTabBody(tempTabBody)

	}, [userDetails, setTabdata, setTabBody])

	const revokeAccessHandler = async () => {
		let result = await revokeAccess(id)
		if (result?.data) {
			toast.success('User access revoked');
		} else {
			toast.error(result?.data?.message);
		}
		handleCloseRevokeUser()
	}

	const returnAccessHandler = async () => {
		let result = await returnAccess(id)
		if (result?.data) {
			toast.success('User access restored');
		} else {
			toast.error(result?.data?.message);
		}
		handleCloseReturnUser()
	}

	const editProfileHandler = () => {
		if (!isTheActionPermitted('editRevokeUsers')) {
			toast.error("You don't have access to this feature")
			return false
		}
		handleEditProfile(true)
	}

	return (
		<>
			<Breadcrumbs
				breadcrumbsData={breadcrumbsData}
				link="/users"
			/>
			<div className="UserDetails">

				<li>
					<div className="UserDetailsleftcol">

						<div className="UserDetailsrIcon" >
							<Avatar
								src={userDetails?.profilePic}
								sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
							//onClick={() => { setShowProfilePhotoSelectionModal(true) }}
							>{(!userDetails?.profilePic || userDetails?.profilePic == '') && userDetails?.firstName?.charAt(0).toUpperCase()}</Avatar>



							{/* <div className="UploadedCameIcon" onClick={() => { setShowProfilePhotoSelectionModal(true) }}>
								<div className="alfa"></div>
								<AddAPhotoRoundedIcon />
							</div> */}
						</div>

						<div className="UserDetailsDetail">
							<span style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
								<span
								// style={{ display: "inline-block" }}
								>
									<h1>
										<b>{userDetails?.firstName} {userDetails?.lastName}</b>
									</h1>
								</span>{" "}
								{
									userDetails?.status !== "inactive" ? <span className="status rounded success" >
										{userDetails?.status ? userDetails.status.toUpperCase() : "N/A"} </span>
										:
										<span className="status rounded error" >
											{userDetails?.status ? userDetails.status.toUpperCase() : "N/A"}
										</span>
								}
							</span>
							<p>
								{userDetails?.userType ? toTitleCase(userDetails?.userType?.toUpperCase()) : "N/A"} |
								ID: {userDetails?.userUniqueID}
							</p>
						</div>
					</div>

					<div className="UserListingAction">
						<div align="left">
							<div className="EditUserProfile">
								<div className="ActionButton">
									<Button
										id="basic-button"
										aria-controls={open ? "basic-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={open ? "true" : undefined}
										onClick={handleClick}
									>
										ACTIONS <KeyboardArrowDownRoundedIcon />
									</Button>
								</div>

								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										"aria-labelledby": "basic-button",

									}}
									className="EditDropdown"
								>
									<span onClick={handleClose} className="MenuListContainer">
										<MenuItem onClick={() => editProfileHandler()}>EDIT</MenuItem>
									</span>
									{(personalInfo?.status == 'active') &&
										<span onClick={handleClose} className="MenuListContainer">
											<MenuItem onClick={() => handleOpenRevokeUser()}>REVOKE ACCESS</MenuItem>
										</span>}
									{(personalInfo?.status != 'active') &&
										<span onClick={handleClose} className="MenuListContainer">
											<MenuItem onClick={() => handleOpenReturnUser()}>RESTORE ACCESS</MenuItem>
										</span>}
									{/* <span onClick={handleClose}>
                    				<MenuItem onClick={handleClose}>Revoke Access</MenuItem>
                  					</span> */}
									{/* <MenuItem onClick={handleClose}>Delete Customer</MenuItem> */}
								</Menu>
							</div>
						</div>
					</div>
				</li>

				<VerticalTab
					tabData={tabData}
					tabBody={tabBody}
					onTabSwitch={handleTabSwitch}
				/>
			</div>
			<SideDrawer
				title="Edit User"
				cancelButtonLabel="CANCEL"
				deleteText="Delete Permanently"
				sideOpen="right"
				open={openSideDrawer || editProfile}
				handleDrawerClose={drawerClose}
				ButtonLeft="Upload New"
				FooterWithoutTab={true}
				ButtonLabel="SAVE"
				clickSaveButton={() => handleUpdateProfile(personalInfo)}
				body={
					<div>
						<UsersProfileEdit personalInfo={personalInfo} setPersonalInfo={setPersonalInfo}
							birthDate={birthDate}
							setBirthDate={setBirthDate}
							birthMonth={birthMonth}
							setBirthMonth={setBirthMonth}
							birthYear={birthYear}
							setBirthYear={setBirthYear}
							errors={errors} />
					</div>
				}
			/>

			<DraggableDialog
				openDialog={openDialogRevokeUser}
				handleCloseDialog={handleCloseRevokeUser}
				handleConfirmCloseDialog={revokeAccessHandler}
				title="Revoke user access?"
				body="Are you sure you want to revoke access of this user?"
				ModalFooter={true}
				actionButton="Revoke"
			/>

			<DraggableDialog
				openDialog={openDialogReturnUser}
				handleCloseDialog={handleCloseReturnUser}
				handleConfirmCloseDialog={returnAccessHandler}
				title="Restore user access?"
				body="Are you sure you want to restore access of this user?"
				ModalFooter={true}
				actionButton="Restore"
			/>
		</>
	);
};

export default UsersDetails;
