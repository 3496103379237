import React, { useEffect, useState } from "react";
import ComingSoon from '../../../components/ComingSoon/ComingSoon';
import Status from '../../../components/Status/Status';


const Home = (props) => {


  const staticStatustData = [
    {
      Label: "Section Status",
      SubLabel: "Active",
      Color: "#41AB37",
      line: true,
    },

    {
      Label: "Section Code",
      SubLabel: "MATH 0001",
      Color: "#7D8597",
    },

    {
      Label: "Primary Teacher",
      SubLabel: "Shayan Mahmud",
      Color: "#7D8597",
    },
  ];

  return (

    <div>

      <Status
        MainTitle={true}
        Title="Boys"
        BackLinkLabel="Mathematics 1"
        Backlink="#"
        StaticStatus={true}
        StatusLine={false}
        BottomLine={true}
        staticStatustData={staticStatustData}

      />
      <ComingSoon />
    </div>

  );
};

export default Home;
