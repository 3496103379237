import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useGetSearchResultMutation, useGetUserDetailsQuery, useGetUserListQuery, useGetUsersByUserTypesMutation, useLazyGetUserDetailsQuery } from "../../../redux/features/users/userApi";
import {
  useGetCourseDetailsQuery,

  useDeleteGroupUsersMutation,
  useGetCourseStudentsSearchResultMutation,
  useDeleteCourseStudentsMutation,
  useAddCourseStudentsMutation,
  useGetSectionStudentDataByFilterMutation,

} from '../../../redux/features/classes/classApi';
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import AddStudents from './AddStudents';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddIcon from '@mui/icons-material/Add';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DraggableDialog from "../../../components/hooks/Dialog";
import Export from './Export';


import StudentDetails from './StudentDetails';
import { useAddCourseToStudentsMutation } from "../../../redux/features/classes/courseStudentApi";
import axios from "axios";
import StudentsFilter from "./StudentsFilter";

const Students = ({ section_id, courseDetails, }) => {
  const { course_id } = useParams()
  const { data, isLoading, isError, error } = useGetCourseDetailsQuery(course_id, { refetchOnMountOrArgChange: true });


  //  Students Details//
  const [OpenStuendDetails, setOpenStuendDetails] = useState(false);
  const [selectedId, setselectedId] = useState('');
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [allUsers, setAllUsers] = useState([]);
  const [singleStudentDetails, setSingleStudentDetails] = useState({});
  const [searchedStatus, setSearchedStatus] = useState(false)
  const [getSingleStudentDetail, { data: singleStudentDetail, isLoading: singleStudentDetailIsLoading, error: singleStudentDetailError }] = useLazyGetUserDetailsQuery(selectedId, {
    refetchOnMountOrArgChange: true,
  });

  const handleOpenStuendDetails = (id) => {
    setselectedId(id)
    getSingleStudentDetailData(id)
    setOpenStuendDetails(true);
  };
  const handleCloseStuendDetails = () => {
    setOpenStuendDetails(false);
  };

  const getSingleStudentDetailData = async (id) => {
    const result = await getSingleStudentDetail(id)
    if (result?.data?.body?.data) {
      setSingleStudentDetails(result?.data?.body?.data);
    }
  }




  const [getSearchedUserList, options] = useGetCourseStudentsSearchResultMutation();
  const [addCourseStudents, addCourseStudentsOptions] = useAddCourseStudentsMutation();
  const [getStudentByFilter, getStudentByFilterOptions] = useGetSectionStudentDataByFilterMutation();
  // const { addCourseToStudents, addCourseToStudentsOptions } = useAddCourseToStudentsMutation();
  const [deleteCourseStudents, deleteCourseStudentsOptions] = useDeleteCourseStudentsMutation();

  useProtected(error);
  useProtected(options.error);
  useProtected(addCourseStudentsOptions?.error);
  // useProtected(addCourseToStudentsOptions?.error);
  useProtected(deleteCourseStudentsOptions.error);
  useProtected(getStudentByFilterOptions.error);


  const handleOpenAddUsers = () => {
    setAddUsers(true)
    // getUsersByUserTypesData()
  };
  const handleCloseAddUsers = () => setAddUsers(false);

  const [rows, setRows] = useState([]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [courseUsers, setCourseUsers] = useState([]);
  const [OpenAddUsers, setAddUsers] = useState(false);
  const [selectedToBlank, setSelectedToBlank] = useState(false)
  const [openDialogRemove, setOpenDialogRemove] = useState(false);
  const [addedUsersToUpdate, setAddedUsersToUpdate] = useState([]);
  const [courseUsersToUpdate, setCourseUsersToUpdate] = useState([]);
  const [alreadyAddedStudents, setAlreadyAddedStudents] = useState([]);
  const [academicStartDate, setAcademicStartDate] = useState('')
  const [academicEndDate, setAcademicEndDate] = useState('')
  const [deletedId, setdeletedId] = useState('')


  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", width: "33%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "ID", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Class/Level", width: "30%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Status", width: "15%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "right", },

  ];

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr.map(item => ({
        cell: [
          { textBold: item?.name || 'N/A', Check: true, user_id: item.id, user: { name: `${item?.name}`, id: item.id, email: item?.email, status: item?.status } },
          { textBold: item?.userUniqueId || 'N/A' },
          { textBold: '3', TextWithLinkText: "+2 more", link: "", tooltipText: <div><li>3,</li><li>Pre-Kindergarten</li></div> },
          (item?.status === 'active') ? { Status: true, background: '#EDF7EA', statusLine: true, linebackground: "#41AB37", color: '#66BD50', label: 'Active', } : {
            Status: true, background: '#FCE7E9',
            color: '#E63946', label: 'Inactive', statusLine: true, linebackground: (item?.status == 'active') ? '#41AB37' : '#BA1A1A',
          },
          //{ Status: true, background: '#EDF7EA', color: '#66BD50', label: 'Active', },


          { ActionButtonDotIcon: true, },
        ],

        //======= Action Date Start===== //
        action: [
          { label: 'View Details', onClick: () => handleOpenStuendDetails(item.id) },
          { label: 'Unenroll', onClick: () => handleOpenDelete(item.id) },

        ],
        //======= Action Date End===== //
      }))
    }
    else {
      return [];
    }
  }

  useEffect(() => {
    const section_idData = courseDetails?.sections?.find((dataId) => dataId?.id === section_id)
    setAcademicStartDate(section_idData?.acededemicTerm?.startDate)
    setAcademicEndDate(section_idData?.acededemicTerm?.endDate)
    setRows(convertDataToCellRows(section_idData?.students));
    setAllUsers(section_idData?.students)

  }, [courseDetails])

  useEffect(() => {
    if (allUsers) {
      setRows(convertDataToCellRows(allUsers));
    }
  }, [allUsers])


  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];


  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = (id) => {
    setdeletedId(id)
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  const confirmMessageDelete = async () => {
    let data = {
      id: deletedId,
    };
    const result = await deleteCourseStudents({ ...data, courseId: course_id, sectionId: section_id });
    if (result?.data?.body) {

      toast.success("Student removed");
      // setOpenDialog(false)
    } else {
      toast.error(result?.data?.message);
    }

    handleCloseDeleteDialog()
  }
  //======= Delete dialog ===== //
  // ========================= search part start ==================================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setSearchTerm("")
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }

  const handleSearch = async () => {
    let data = {
      term: searchTerm
    }
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ ...data, courseId: course_id, sectionId: section_id });
    // console.log({ result })
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
      setSearchTerm("")
      if (result.data.body.data.length === 0) {
        setSearchedStatus(true)
      } else {
        setSearchedStatus(false)
      }
    }
    setPageNumberFromOutSide(false)
  }
  // ====================================== search part end =================================== //
  // Add Students //
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };
  const handleCloseFilterDrawer = () => {
    setOpenFilter(false);
  };
  const [getUsersByUserTypes, getUsersByUserTypesOptions] = useGetUsersByUserTypesMutation();
  const [userTypesDatas, setUserTypesDatas] = useState([])
  const getUsersByUserTypesData = async () => {
    const result = await getUsersByUserTypes({ userTypes: "student", data: "" })
    if (result?.data?.body?.data) {
      setUserTypesDatas(result?.data?.body?.data)
    }


  }

  // ========== add student part start ==============//
  const addUsersToCourse = async () => {
    let data = {
      students: courseUsersToUpdate
    };

    const result = await addCourseStudents({ ...data, courseId: course_id, sectionId: section_id });
    // const result = await addCourseToStudents(data);
    if (result?.data?.body) {
      toast.success('Users added to cousrse successfully');
      setAddUsers(false)
      setCourseUsers([])
      setCourseUsersToUpdate([])
    } else {
      toast.error(result?.data?.message);
    }
  }
  // ========== add student part end ==============//
  const handleCloseOrCancel = () => {
    handleCloseAddUsers()
    setCourseUsers([])
    setCourseUsersToUpdate([])
  }


  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setAddedUsers(prevUsers => [...prevUsers, item[0].user]);
      setAddedUsersToUpdate(prevUsers => [...prevUsers, item[0].user]);
    } else {
      // Remove the user from the array
      setAddedUsers(prevUsers => prevUsers.filter(user => user.id !== item[0].user.id));
      setAddedUsersToUpdate(prevUsers => prevUsers.filter(user => user.id !== item[0].user.id));
    }
  }
  const handleOpenRemove = () => {
    setOpenDialogRemove(true);
  };
  const handleCloseRemoveDialog = () => {
    setOpenDialogRemove(false)
  };
  //======================== delete course part start ===========================//
  const deleteUsersFromClass = async () => {
    setSelectedToBlank(true)
    let text = addedUsers?.length > 1 ? "Students removed" : "Student removed"
    let data = {
      id: addedUsers,
    };
    const result = await deleteCourseStudents({ ...data, courseId: course_id, sectionId: section_id });
    if (result?.data?.body) {
      toast.success(text);
      // setOpenDialog(false)
    } else {
      toast.error(result?.data?.message);
    }
    setSelectedToBlank(false)
    setAddedUsers([])
    setAddedUsersToUpdate([])
    handleCloseRemoveDialog()
    // getStudents({ classLevelId: class_level_id, schoolYearId: schoolYear })
  }
  //======================== delete course part end ===========================//
  //================================ Export Section part start ==========================//
  const [exportType, setExportType] = useState('excel')
  const [dateRange, setDateRange] = useState('30_days')
  const [classLevel, setClassLevel] = useState([])
  const [gradeTypesExport, setGradeTypesExport] = useState([])
  const [genderTypesExport, setGenderTypesExport] = useState([])
  const [userTypesExport, setUserTypesExport] = useState([])
  const [startDateExport, setStartDateExport] = useState('')
  const [endDateExport, setEndDateExport] = useState('')
  const [OpenExport, setOpenExport] = useState(false);
  const handleDrawerOpenExport = () => {
    setClassLevel([])
    setGenderTypesExport([])
    setOpenExport(true);
  };
  const handleDrawerCloseExport = () => {
    setOpenExport(false);
  };
  const handleExportType = (e) => {
    setExportType(e.target.value.toLowerCase())
  }

  const exportTypes = [
    {
      "type": "csv",
      "file": "students.csv"
    },
    {
      "type": "excel",
      "file": "students.xlsx"
    }
  ]

  const handleDateRange = (e) => {
    setDateRange(e.target.value)
    // Get the current date
    const currentDate = new Date();

    // Format the date to YYYY-MM-DD
    let formattedEndDate = currentDate.toISOString().split('T')[0];
    let formattedStartDate = '';


    if (e.target.value === "30_days") {
      // Subtract 30 days
      const thirtyDaysAgo = new Date(currentDate);
      thirtyDaysAgo.setDate(currentDate.getDate() - 30);
      formattedStartDate = thirtyDaysAgo.toISOString().split('T')[0]

    } else if (e.target.value === "3_months") {
      // Subtract 3 months
      const threeMonthsAgo = new Date(currentDate);
      threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
      formattedStartDate = threeMonthsAgo.toISOString().split('T')[0]

    } else if (e.target.value === "6_months") {
      // Subtract 6 months
      const sixMonthsAgo = new Date(currentDate);
      sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
      formattedStartDate = sixMonthsAgo.toISOString().split('T')[0]

    } else if (e.target.value === "12_months") {
      // Subtract 12 months
      const twelveMonthsAgo = new Date(currentDate);
      twelveMonthsAgo.setMonth(currentDate.getMonth() - 12);
      formattedStartDate = twelveMonthsAgo.toISOString().split('T')[0]

    } else {
      // Subtract 5 years
      const fiveYearsAgo = new Date(currentDate);
      fiveYearsAgo.setFullYear(currentDate.getFullYear() - 5);
      formattedStartDate = fiveYearsAgo.toISOString().split('T')[0];
    }

    setStartDateExport(formattedStartDate)
    setEndDateExport(formattedEndDate)

  }

  const userTypesHandler = (e, value) => {
    let userTypes = [...userTypesExport]
    if (e.target.checked) {
      userTypes.push(value)
    } else {
      userTypes = userTypes.filter(item => item !== value)
    }
    setUserTypesExport(userTypes)
  }
  const genderTypeHandler = (e, value) => {
    let genderTypes = [...genderTypesExport]
    if (e.target.checked) {
      genderTypes.push(value)
    } else {
      genderTypes = genderTypes?.filter(item => item !== value)
    }
    setGenderTypesExport(genderTypes)
  }
  const gradeTypesHandler = (e, value) => {
    let gradeTypes = [...gradeTypesExport]
    if (e.target.checked) {
      gradeTypes.push(value)
    } else {
      gradeTypes = gradeTypes.filter(item => item !== value)
    }
    setGradeTypesExport(gradeTypes)
  }

  const handleExportStudentAttendance = async () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const user = localStorage.getItem('user');
    const userToken = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;


    const currentDate = new Date();
    let formattedEndDate = currentDate.toISOString().split('T')[0];
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    let formattedStartDate = thirtyDaysAgo.toISOString().split('T')[0]

    // let data = {
    //   "exportType": exportType,
    //   "startDate": (startDateExport === "") ? formattedStartDate : startDateExport,
    //   "endDate": (endDateExport === "") ? formattedEndDate : endDateExport,
    //   "userTypes": userTypesExport,
    //   "genderTypes": genderTypesExport,
    //   "gradeTypes": gradeTypesExport
    // }
    let data = {
      "exportType": exportType,
      "courseId": course_id,
      "sectionId": section_id,
      "status": userTypesExport,
      "gender": genderTypesExport
    }
    try {
      const response = await axios.post(baseUrl + '/courses/exportStudents', data, {
        // headers: {
        //     'Authorization': `Bearer YOUR_AUTH_TOKEN` // replace with your actual token
        // },
        responseType: 'blob' // Important to set this
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      let activeType = exportTypes.find(singleExportType => singleExportType.type === exportType)
      link.setAttribute('download', activeType.file);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
    handleDrawerCloseExport()
  }

  // useEffect(() => {
  //   console.log(gradeTypesExport, 328)
  // }, [gradeTypesExport])
  // useEffect(() => {
  //   console.log(userTypesExport, 328)
  // }, [userTypesExport])
  // useEffect(() => {
  //   console.log(genderTypesExport, 328)
  // }, [genderTypesExport])
  //================================ Export Section part end ==========================//


  //======================== filter part start ========================================//
  const handleCloseDrawer = () => {
    setFilterDrawer(false)
    setRows(convertDataToCellRows(allUsers));
    setGradeTypesExport([])
    setGenderTypesExport([])
    setUserTypesExport([])
  }

  const handleFilterStudentAttendance = async () => {
    const result = await getStudentByFilter({ gender: genderTypesExport, status: userTypesExport, sectionId: section_id })
    if (result?.data?.body?.data) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    }
    setFilterDrawer(false)
  }
  //======================== filter part start ========================================//
  return (
    <>
      <div className="TableSection">
        <Table
          title="Students"
          tableHeader={true}
          addLabel="Add Student"
          //icon={<AddIcon />}
          AddButton={true}
          FilterButtonTop={true}
          onClickFilterButton={() => setFilterDrawer(true)}
          SearchBoxTop={true}
          searchBoxPlaceholder="Search users"
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          headCells={headCells}
          rows={rows}
          onClick={handleOpenAddUsers}
          footer={true}

          CheckboxDelete={false}
          pageNumberFromOutSide={pageNumberFromOutSide}
          innerHeader={true}
          emptyTableTitle={searchedStatus ? "No students found" : "No students added yet"}
          emptyTableNote="Add students to this course"
          btnLabel="Add Student"
          showButton={true}
          onClickEmptyCard={handleOpenAddUsers}
          setSelectedToBlank={selectedToBlank}
          handleCheckItem={setAddedUsers}
          selectedCheckList={addedUsers}
          handleRowClick={handleRowClick}
          customCellObjectId="user_id"
          CheckboxDeleteModal={true}
          DeleteText="REMOVE STUDENT"
          DeleteButtonOnclick={handleOpenRemove}

          isDataLoading={isLoading || options?.isLoading}
          ExportButtonInner={true}
          OnclickExport={handleDrawerOpenExport}
        />
      </div>


      {/* Add Students */}
      <SideDrawer
        open={OpenAddUsers}
        handleDrawerClose={handleCloseOrCancel}
        title="Add Students"
        sideOpen="right"
        ButtonLabel={`Add ${courseUsers?.length || 0} ${courseUsers?.length > 1 ? 'Students' : 'Student'}`}
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={(e) => (courseUsers?.length > 0) && addUsersToCourse()}
        padding="0"
        body={
          <>
            <AddStudents
              courseUsers={courseUsers}
              courseUsersToUpdate={courseUsersToUpdate}
              setCourseUsers={setCourseUsers}
              addCourseUsersOptions={addCourseStudentsOptions}
              // addCourseUsersOptions={addCourseToStudentsOptions}
              setCourseUsersToUpdate={setCourseUsersToUpdate}
              alreadyAddedStudents={alreadyAddedStudents}
            />
          </>
        }
      />

      {/* Students Details */}
      <SideDrawer
        open={OpenStuendDetails}
        handleDrawerClose={handleCloseStuendDetails}
        title="Student Details"
        sideOpen="right"
        ButtonLabel="View More details"
        cancelButtonLabel="Close"
        FooterWithoutTab={true}
        clickSaveButton={(e) => (courseUsers?.length > 0) && addUsersToCourse()}
        padding="0"
        body={
          <>
            <StudentDetails
              singleStudentDetails={singleStudentDetails}
              academicStartDate={academicStartDate}
              academicEndDate={academicEndDate}
              section_id={section_id}
            />
          </>
        }
      />


      {/* Export Section */}
      <SideDrawer
        open={OpenExport}
        handleDrawerClose={handleDrawerCloseExport}
        title="Export"
        sideOpen="right"
        ButtonLabel="Export"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={() => handleExportStudentAttendance()}
        body={
          <>
            <Export
              gradeTypesHandler={gradeTypesHandler}
              handleDateRange={handleDateRange}
              handleExportType={handleExportType}
              exportType={exportType}
              dateRange={dateRange}
              setGradeTypesExport={setGradeTypesExport}
              gradeTypesExport={gradeTypesExport}
              setClassLevel={setClassLevel}
              classLevel={classLevel}
              userTypesHandler={userTypesHandler}
              userTypesExport={userTypesExport}
              setUserTypesExport={setUserTypesExport}
              genderTypeHandler={genderTypeHandler}
              setGenderTypesExport={setGenderTypesExport}
              genderTypesExport={genderTypesExport}
            />
          </>
        }
      />

      {/* Filter Drawer */}
      <SideDrawer
        open={filterDrawer}
        handleDrawerClose={handleCloseDrawer}
        title="Filter"
        sideOpen="right"
        ButtonLabel="Filter"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={() => handleFilterStudentAttendance()}
        body={
          <>
            <StudentsFilter
              gradeTypesHandler={gradeTypesHandler}
              setGradeTypesExport={setGradeTypesExport}
              gradeTypesExport={gradeTypesExport}
              setClassLevel={setClassLevel}
              classLevel={classLevel}
              userTypesHandler={userTypesHandler}
              userTypesExport={userTypesExport}
              setUserTypesExport={setUserTypesExport}
              genderTypeHandler={genderTypeHandler}
              setGenderTypesExport={setGenderTypesExport}
              genderTypesExport={genderTypesExport}
            />
          </>
        }
      />

      <DraggableDialog
        openDialog={openDialogRemove}
        handleCloseDialog={handleCloseRemoveDialog}
        handleConfirmCloseDialog={(e) => deleteUsersFromClass()}
        title="Remove student from course?"
        body="Are you sure you want to this student from the course?"
        ModalFooter={true}
        actionButton="Remove"
      />

      {/* Delete Modal*/}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={confirmMessageDelete}
        title="Unenroll student?"
        body={<div>Are you sure you want to unenroll this student from the course?</div>}
        ModalFooter={true}
        actionButton="Unenroll"
      />

    </>
  );
};

export default Students;
