import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
// import img from '../../../assets/imgs/User.png';
// import Buttons from '../../../components/Form/Button/Button';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, grey } from '@mui/material/colors';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Buttons from '../../../../components/Form/Button/Button';
import Tooltip from '../../../../components/Form/Tooltip/Tooltip';
import SideDrawer from '../../../../components/SideDrawer/SideDrawer';
import MediaLibrarySelectMedia from '../../../../components/MediaLibrary/MediaLibrarySelectMedia';
import Externaluse from '../../../../components/MediaLibrary/Externaluse';
import { useDeleteSchoolLogoIconMutation, useDeleteSchoolLogoMutation, useGetSchoolListQuery, useGetSchoolQuery, useUpdateSchoolMutation } from '../../../../redux/features/schools/schoolApi';
import { useSelector } from 'react-redux';
import useProtected from '../../../../hooks/useProtected';
import { toast } from 'react-toastify';
import DraggableDialog from '../../../../components/hooks/Dialog';



// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F8F8F8',
        },
    },
}))(TableRow);
// ================= Table OD and EVEN Code ========//



const BrandingNew = () => {
    const { schoolId } = useSelector(state => state.auth.user);

    const { data, isError, isLoading, error } = useGetSchoolQuery(schoolId)

    const [updateSchool, updateSchoolOptions] = useUpdateSchoolMutation();
    const [deleteSchoolLogo, deleteSchoolLogoOptions] = useDeleteSchoolLogoMutation()
    const [deleteSchoolLogoIcon, deleteSchoolLogoIconOptions] = useDeleteSchoolLogoIconMutation()

    useProtected(error);
    useProtected(updateSchoolOptions.error);
    useProtected(deleteSchoolLogoOptions.error);
    useProtected(deleteSchoolLogoIconOptions.error);


    const [schoolInfo, setSchoolInfo] = useState({})
    const [openSelectMedia, setOpenSelectMedia] = useState(false);
    const [openSelectMedia2, setOpenSelectMedia2] = useState(false);
    const [openNewMedia, setOpenNewMedia] = useState(false);
    const [openNewMedia2, setOpenNewMedia2] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState(false);
    const [selectedValue2, setSelectedValue2] = React.useState(false);
    const [mediaItem, setMediaItem] = useState({});
    const [mediaItem2, setMediaItem2] = useState({});
    const [imageFromCropper, setImageFromCropper] = useState(null);
    const [imageFromCropperSrc, setImageFromCropperSrc] = useState(null);
    const [imageFromCropperName, setImageFromCropperName] = useState("");

    const [schoolLogoIcon, setSchoolLogoIcon] = useState('');
    const [schoolLogoIconName, setSchoolLogoIconName] = useState('');
    const [schoolLogo, setSchoolLogo] = useState('');
    const [schoolLogoName, setSchoolLogoName] = useState('');


    //handleDrawerCloseSelectMedia
    const handleDrawerCloseSelectMedia = () => {
        setSelectedValue(false)
        setMediaItem({})
        setOpenSelectMedia(false);
    };
    const handleDrawerCloseSelectMedia2 = () => {
        setSelectedValue2(false)
        setMediaItem2({})
        setOpenSelectMedia2(false);
    };


    useEffect(() => {
        if (!isLoading && data && !error) {
            setSchoolInfo(data?.body?.data)
        }
    }, [data, error, isLoading])


    useEffect(() => {
        setSchoolLogoIcon(schoolInfo?.schoolIcon || '')
        setSchoolLogoIconName(schoolInfo?.schoolIconName || '')
        setSchoolLogo(schoolInfo?.schoolLogo || '')
        setSchoolLogoName(schoolInfo?.schoolLogoName || '')
    }, [schoolInfo])


    const clickSelectMediaItem = async () => {
        console.log(mediaItem, selectedValue)
        //check if any item is selected
        if (selectedValue) {
            let data = {
                schoolIcon: mediaItem.url,
                schoolIconName: mediaItem.name,
            }
            let result = await updateSchool({ ...data, id: schoolInfo._id })

            if (result?.data) {
                toast.success('Logo icon uploaded successfully');
                setSelectedValue(false)
                setMediaItem({})
                setOpenSelectMedia(false);
            } else {
                toast.error(result?.data?.message);
            }
        } else {
            alert("Please select any media");
        }
    };

    const clickSelectMediaItem2 = async () => {
        console.log(mediaItem2, selectedValue2)
        //check if any item is selected
        if (selectedValue2) {
            let data = {
                schoolLogo: mediaItem2.url,
                schoolLogoName: mediaItem2.name,
            }
            let result = await updateSchool({ ...data, id: schoolInfo._id })

            if (result?.data) {
                toast.success('Logo uploaded successfully');
                setSelectedValue2(false)
                setMediaItem2({})
                setOpenSelectMedia2(false);
            } else {
                toast.error(result?.data?.message);
            }
        } else {
            alert("Please select any media");
        }
    };

    const getMediaItem = (item) => {
        // setting the media item
        setMediaItem(item);
    };
    const getMediaItem2 = (item) => {
        // setting the media item
        setMediaItem2(item);
    };

    const handleMediaUploadClose = () => {
        setOpenSelectMedia(true)
        setOpenNewMedia(false);
    };
    const handleMediaUploadClose2 = () => {
        setOpenNewMedia2(false);
    };

    const handleOpenNewMedia = (status) => {
        setOpenNewMedia(!status);
        handleDrawerOpenSelectMedia(status);
        setOpenSelectMedia(true)
    };
    const handleOpenNewMedia2 = (status) => {
        setOpenNewMedia2(!status);
        handleDrawerOpenSelectMedia2(status);
    };

    const handleDrawerOpenSelectMedia = () => {
        setOpenSelectMedia(true);
        setActiveLogoIcon(!isActiveLogoIcon);
    };
    const handleDrawerOpenSelectMedia2 = () => {
        setOpenSelectMedia2(true);
        setActiveLogo(!isActiveLogo)
    };

    const handleDrawerOpenNewMedia = () => {
        setOpenNewMedia(true);
    };

    const handleDrawerOpenNewMedia2 = () => {
        setOpenNewMedia2(true);
    };

    // * ============================== Media lib ============================== 

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const [isActiveLogoIcon, setActiveLogoIcon] = useState(true);
    const [isActiveLogo, setActiveLogo] = useState(true);
    const [openLogoIconDialog, setOpenLogoIconDialog] = useState(false);
    const [openLogoDialog, setOpenLogoDialog] = useState(false);


    const handleToggleLogoIcon = () => {
        setActiveLogoIcon(!isActiveLogoIcon);
    };
    const handleToggleLogo = () => {
        setActiveLogo(!isActiveLogo);
    };


    const handleDeleteSchoolLogoIcon = () => {
        setOpenLogoIconDialog(true)
        setActiveLogoIcon(!isActiveLogoIcon);
    }
    const handleDeleteSchoolLogo = () => {
        setOpenLogoDialog(true)
        setActiveLogo(!isActiveLogo);
    }




    const deleteSchoolLogoIconOperation = async () => {
        let result = await deleteSchoolLogoIcon(schoolId)
        if (result?.data) {
            toast.success("Logo Icon deleted")
        }
        setOpenLogoIconDialog(false)
    }
    const deleteSchoolLogoOperation = async () => {
        let result = await deleteSchoolLogo(schoolId)
        if (result?.data) {
            toast.success("Logo deleted")
        }
        setOpenLogoDialog(false)
    }


    const handleCloseDeleteDialogLogoIcon = () => setOpenLogoIconDialog(false)
    const handleCloseDeleteDialogLogo = () => setOpenLogoDialog(false)


    return (
        <>
            <div className="OddTabble">
                <div className="TableContainer oddEvenTable" style={{ border: '1px solid #E8EAED' }}>
                    <div className="TableInnerHeader" style={{ padding: '0 0.8rem', margin: '14px 0' }}>
                        <h3>Branding</h3>
                    </div>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableBody>

                                <StyledTableRow>
                                    <StyledTableCell align="left" className="TableLabel" style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                        Logo Icon
                                        <span style={{ marginTop: '10px' }}>
                                            <Tooltip
                                                TooltipText="A smaller representation of your logo (like a favicon)"
                                                Tooltip={<InfoRoundedIcon />}
                                            />
                                        </span>
                                    </StyledTableCell>

                                    <StyledTableCell align="left" className="TableLabel">
                                        <div className='TableUserAvatar' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <Avatar sx={{ bgcolor: grey[500] }} variant="rounded" src={(schoolLogoIcon != '') ? schoolLogoIcon : ''}>
                                                <ImageRoundedIcon />
                                            </Avatar> {schoolLogoIcon != "" ? schoolLogoIconName : 'Upload logo icon'}
                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell align="right" className="TableInfo">
                                        <div className='AlignRight'>

                                            {/* ========= Media lib drawer start ============ */}

                                            <SideDrawer
                                                title="Media Library"
                                                ButtonLabel="Add"
                                                //clickSaveButton={clickSaveButton}
                                                cancelButtonLabel="Cancel"
                                                sideOpen="right"
                                                open={openSelectMedia}
                                                handleDrawerClose={handleDrawerCloseSelectMedia}
                                                FooterWithoutTab={true}
                                                LeftButton={true}
                                                ButtonLeft="Upload New"
                                                ButtonLeftIcon={<FileUploadOutlinedIcon />}
                                                clickButtonLeft={handleDrawerOpenNewMedia}
                                                clickSaveButton={clickSelectMediaItem}
                                                padding="0"
                                                body={
                                                    <MediaLibrarySelectMedia
                                                        onClickRadioIcon={setSelectedValue}
                                                        onItemSelected={getMediaItem}

                                                    />
                                                }
                                            />

                                            <Externaluse
                                                open={openNewMedia}
                                                handleCloseButton={() => handleMediaUploadClose()}
                                                handleOpenNewMedia={() => handleOpenNewMedia(openNewMedia)}
                                            />

                                            {(schoolLogoIcon == '') ?
                                                <Buttons
                                                    label="Add from media library"
                                                    buttonColor="transparent"
                                                    border="#E4E8EC 1px solid"
                                                    icon={<AddRoundedIcon />}
                                                    color="#004FE0"
                                                    width="100%"
                                                    height="48px"
                                                    fontSize="14px"
                                                    link=""
                                                    onClick={() => handleDrawerOpenSelectMedia()}
                                                /> : <div className="ActionMenu">

                                                    <div onClick={handleToggleLogoIcon} className="ActionMenuButton"><MoreVertIcon /></div>


                                                    <div className={isActiveLogoIcon ? "PricingCell" : "Active"}>

                                                        <div className='DropDownMenuCustom'>
                                                            <a onClick={() => handleDrawerOpenSelectMedia()}>Upload New</a>
                                                            <a onClick={handleDeleteSchoolLogoIcon}>Delete</a>
                                                        </div>
                                                        <div className='backdropAlfa' onClick={handleToggleLogoIcon}></div>
                                                    </div>

                                                </div>}

                                            {/* ========= Media lib drawer end ============ */}

                                            {/* <div className="ActionMenu">

                                                <div onClick={handleToggle} className="ActionMenuButton"><MoreVertIcon /></div>


                                                <div className={isActiveLogoIcon ? "PricingCell" : "Active"}>

                                                    <div className='DropDownMenuCustom'>
                                                        <a onClick={handleToggle}>Upload New Photo</a>
                                                        <a onClick={handleToggle}>Delete Photo</a>
                                                    </div>

                                                </div>

                                            </div> */}


                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>


                                <StyledTableRow>
                                    <StyledTableCell align="left" className="TableLabel" style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                        Logo
                                        <span style={{ marginTop: '10px' }}>
                                            <Tooltip
                                                TooltipText="The full-sized version of your logo"
                                                Tooltip={<InfoRoundedIcon />}
                                            />
                                        </span>
                                    </StyledTableCell>

                                    <StyledTableCell align="left" className="TableLabel">
                                        <div className='TableUserAvatar' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <Avatar sx={{ bgcolor: grey[500] }} variant="rounded" src={(schoolLogo != '') ? schoolLogo : ''}>
                                                <ImageRoundedIcon />
                                            </Avatar> {schoolLogo != "" ? schoolLogoName : 'Upload logo'}
                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell align="right" className="TableInfo">
                                        <div className='AlignRight'>

                                            {/* ========= Media lib drawer start ============ */}

                                            <SideDrawer
                                                title="Media Library"
                                                ButtonLabel="Add"
                                                //clickSaveButton={clickSaveButton}
                                                cancelButtonLabel="Cancel"
                                                sideOpen="right"
                                                open={openSelectMedia2}
                                                handleDrawerClose={handleDrawerCloseSelectMedia2}
                                                FooterWithoutTab={true}
                                                LeftButton={true}
                                                ButtonLeft="Upload New"
                                                ButtonLeftIcon={<FileUploadOutlinedIcon />}
                                                clickButtonLeft={handleDrawerOpenNewMedia2}
                                                clickSaveButton={clickSelectMediaItem2}
                                                padding="0"
                                                body={
                                                    <MediaLibrarySelectMedia
                                                        onClickRadioIcon={setSelectedValue2}
                                                        onItemSelected={getMediaItem2}
                                                    />
                                                }
                                            />

                                            <Externaluse
                                                open={openNewMedia2}
                                                handleCloseButton={handleMediaUploadClose2}
                                                handleOpenNewMedia={(handleOpenNewMedia2)}
                                            />

                                            {(schoolLogo == '') ?
                                                <Buttons
                                                    label="Add from media library"
                                                    buttonColor="transparent"
                                                    border="#E4E8EC 1px solid"
                                                    icon={<AddRoundedIcon />}
                                                    color="#004FE0"
                                                    width="100%"
                                                    height="48px"
                                                    fontSize="14px"
                                                    link=""
                                                    onClick={() => handleDrawerOpenSelectMedia2()}
                                                /> : <div className="ActionMenu">

                                                    <div onClick={handleToggleLogo} className="ActionMenuButton"><MoreVertIcon /></div>


                                                    <div className={isActiveLogo ? "PricingCell" : "Active"}>

                                                        <div className='DropDownMenuCustom'>
                                                            <a onClick={() => handleDrawerOpenSelectMedia2()}>Upload New</a>
                                                            <a onClick={handleDeleteSchoolLogo}>Delete</a>
                                                        </div>
                                                        <div className='backdropAlfa' onClick={() => handleToggleLogo()}></div>
                                                    </div>

                                                </div>}

                                            {/* ========= Media lib drawer end ============ */}

                                            {/* <div className="ActionMenu">

                                                <div onClick={handleToggle} className="ActionMenuButton"><MoreVertIcon /></div>


                                                <div className={isActiveLogoIcon ? "PricingCell" : "Active"}>

                                                    <div className='DropDownMenuCustom'>
                                                        <a onClick={handleToggle}>Upload New Photo</a>
                                                        <a onClick={handleToggle}>Delete Photo</a>
                                                    </div>

                                                </div>

                                            </div> */}


                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>

            {/* Delete Group*/}
            <DraggableDialog
                openDialog={openLogoIconDialog}
                handleCloseDialog={handleCloseDeleteDialogLogoIcon}
                handleConfirmCloseDialog={deleteSchoolLogoIconOperation}
                title="Delete logo icon?"
                body={<>Are you sure you want to delete this logo icon?<br />Once you delete it, you can't get it back.</>}
                ModalFooter={true}
                actionButton="DELETE"
                cancelButton="CANCEL"
            />

            {/* Delete Group*/}
            <DraggableDialog
                openDialog={openLogoDialog}
                handleCloseDialog={handleCloseDeleteDialogLogo}
                handleConfirmCloseDialog={deleteSchoolLogoOperation}
                title="Delete logo?"
                body={<>Are you sure you want to delete this logo?<br />Once you delete it, you can't get it back.</>}
                ModalFooter={true}
                actionButton="DELETE"
                cancelButton="CANCEL"
            />

        </>
    );
};

export default BrandingNew;
