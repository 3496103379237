import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import SelectField from '../../../components/Form/SelectField/SelectField';
import { format } from 'crypto-js';
import { useGetUserRolesQuery } from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { useGetAllAttendanceCodesQuery } from '../../../redux/features/attendence/attendanceApi';
import { useGetClassLevelListQuery } from '../../../redux/features/classes/classApi';
import Buttons from '../../../components/Form/Button/Button';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ExportOptions from './ExportOptions';

import Modals from '../../../components/Form/Modals/Modals';
import { useGetUserTypesQuery } from '../../../redux/features/user_types/userTypesApi';


const Export = (props) => {
  const { data: attendanceCodesData, isLoading: attendanceCodesIsLoading, isError: attendanceCodesIsError, error: attendanceCodesError } = useGetAllAttendanceCodesQuery()
  const { data: rolesData, isLoading: rolesIsLoading, isError: rolesIsError, error: rolesError } = useGetUserTypesQuery()
  const { data: classesData, isLoading: classesIsLoading, isError: classesIsError, error: classesError } = useGetClassLevelListQuery()

  const { userTypesHandler,
    attendanceTypesHandler,
    gradeTypesHandler,
    handleExportType,
    handleDateRange,
    exportType,
    dateRange,
    userTypesExport,
    attendenceTypesExport,
    gradeTypesExport,
    setUserTypesExport,
    setAttendenceTypesExport,
    setGradeTypesExport,
    userTypesExportEditOption,
    setUserTypesExportEditOption,
    attendenceTypesExportEditOption,
    setAttendenceTypesExportEditOption,
    gradeTypesExportEditOption,
    setGradeTypesExportEditOption,
    userTypesEditHandler,
    attendanceTypesEditHandler,
    gradeTypesEditHandler,
    userTypesEditExport,
    attendenceTypesEditExport,
    gradeTypesEditExport,
    setUserTypesEditExport,
    setAttendenceTypesEditExport,
    setGradeTypesEditExport,
  } = props;

  const [state, setState] = React.useState({
    checkedA: true,
  });

  useProtected(attendanceCodesError);
  useProtected(rolesError);
  useProtected(classesError);

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [roles, setRoles] = useState([])
  const [userTypes, setUserTypes] = useState([])

  const [codes, setCodes] = useState([])
  const [attendance, setAttendance] = useState([])

  const [classes, setClasses] = useState([])
  const [classLevel, setClassLevel] = useState([])

  const [userTypeAll, setUserTypeAll] = useState(true)
  const [attendanceTypeAll, setAttendanceTypeAll] = useState(true)
  const [gradeTypeAll, setGradeTypeAll] = useState(true)


  useEffect(() => {
    if (!rolesIsLoading && !rolesIsError) {
      setRoles(rolesData?.body?.data)
    }
  }, [rolesData, rolesIsLoading, rolesIsError])

  useEffect(() => {
    if (!attendanceCodesIsLoading && !attendanceCodesIsError) {
      setCodes(attendanceCodesData?.body?.data)
    }
  }, [attendanceCodesData, attendanceCodesIsLoading, attendanceCodesIsError])

  useEffect(() => {
    if (!classesIsLoading && !classesIsError) {
      setClasses(classesData?.body?.data)
    }
  }, [classesData, classesIsLoading, classesIsError])

  useEffect(() => {
    if (userTypeAll) {
      let val_array = []
      roles.map((role) => {
        val_array.push(role?.name?.toLowerCase())
      })
      setUserTypesExport(val_array)
    } else {
      setUserTypesExport([])
    }
  }, [userTypeAll])

  useEffect(() => {
    if (attendanceTypeAll) {
      let val_array = []
      codes.map((code) => {
        val_array.push(code?.code)
      })
      setAttendenceTypesExport(val_array)
    } else {
      setAttendenceTypesExport([])
    }
  }, [attendanceTypeAll])

  useEffect(() => {
    if (gradeTypeAll) {
      let val_array = []
      classes.map((cls) => {
        val_array.push(cls?.name?.toLowerCase())
      })
      setGradeTypesExport(val_array)
    } else {
      setGradeTypesExport([])
    }
  }, [gradeTypeAll])

  useEffect(() => {
    if (roles?.length > 0) {
      let roleTypes = []
      let val_array = []
      roles.map((role) => {
        roleTypes.push({ label: role?.name?.replace(/(^|\s)\w/g, (match) => match.toUpperCase()), value: role?.name?.toLowerCase() })
        val_array.push(role?.name?.toLowerCase())
      })
      setUserTypes(roleTypes)
      setUserTypesExport(val_array)
    }
  }, [roles])

  useEffect(() => {
    if (codes?.length > 0) {
      let attCodes = []
      let val_array = []
      codes.map((code) => {
        attCodes.push({ label: code.description, value: code.code })
        val_array.push(code.code)
      })
      setAttendance(attCodes)
      setAttendenceTypesExport(val_array)
    }
  }, [codes])


  useEffect(() => {
    if (classes?.length > 0) {
      let allClasses = []
      let val_array = []
      classes.map((cls) => {
        allClasses.push({ label: cls?.name?.replace(/(^|\s)\w/g, (match) => match.toUpperCase()), value: cls?.name?.toLowerCase() })
        val_array.push(cls?.name?.toLowerCase())
      })
      setClassLevel(allClasses)
      setGradeTypesExport(val_array)
    }
  }, [classes])

  const format = [
    {
      label: 'Excel',
      value: 'excel',
    },

    {
      label: 'CSV',
      value: 'csv',
    },
  ];

  const dataRange = [
    {
      label: 'Last 30 days',
      value: '30_days',
    },

    {
      label: 'Last 3 months',
      value: '3_months',
    },

    {
      label: 'Last 6 months',
      value: '6_months',
    },

    {
      label: 'Last 12 months',
      value: '12_months',
    },

    {
      label: 'All',
      value: 'all',
    },
  ];


  //======= Delete dialog ===== //
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    uncheckAllFirst(() => { })
    setOpen(true);
  };
  const handleClose = () => {
    checkAllFirst()
    setOpen(false)
  };
  const handleConfirm = () => {

    uncheckAllFirst(() => {
      setUserTypesExport(userTypesEditExport)
      setAttendenceTypesExport(attendenceTypesEditExport)
      setGradeTypesExport(gradeTypesEditExport)

    })

    setOpen(false)
  };
  //======= Delete dialog ===== //

  const uncheckAllFirst = (callback) => {
    setUserTypeAll(false)
    setAttendanceTypeAll(false)
    setGradeTypeAll(false)
    callback()
  }
  const checkAllFirst = () => {
    setUserTypeAll(true)
    setAttendanceTypeAll(true)
    setGradeTypeAll(true)
  }

  return (
    <div className="Export">
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <SelectField
            FieldLabel={true}
            label="Select format"
            selectedOption={exportType}
            options={format}
            handleChange={(e) => handleExportType(e)}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <SelectField
            FieldLabel={true}
            label="Select date range"
            selectedOption={dateRange}
            options={dataRange}
            handleChange={(e) => handleDateRange(e)}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <div className='ExportOPtions'>
            <h1>Export options</h1>

            <Buttons
              label="Edit options"
              icon={<SettingsRoundedIcon />}
              buttonColor="#fff"
              border="#767680 1px solid"
              color="#1B1B1F"
              width=""
              height="32px"
              fontSize="14px"
              onClick={handleOpen}
            />

          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <div className="FieldLabel ExportLabel">User type</div>
                  <CheckBox
                    label="All"
                    checked={userTypeAll}
                    onChange={(e) => setUserTypeAll(e.target.checked)}
                  />
                  {userTypes.map((item, i) => (
                    <CheckBox
                      label={item.label}
                      checked={userTypesExport.includes(item.value)}
                      onChange={(e) => userTypesHandler(e, item.value)}
                    />
                  ))}
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="FieldLabel ExportLabel">Attendance</div>
                  <CheckBox
                    label="All"
                    checked={attendanceTypeAll}
                    onChange={(e) => setAttendanceTypeAll(e.target.checked)}
                  />
                  {attendance.map((item, i) => (
                    <CheckBox
                      label={item.label}
                      checked={attendenceTypesExport.includes(item.value)}
                      onChange={(e) => attendanceTypesHandler(e, item.value)}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <div className="FieldLabel ExportLabel">Class/Level</div>
              <CheckBox
                label="All"
                checked={gradeTypeAll}
                onChange={(e) => setGradeTypeAll(e.target.checked)}
              />
              {classLevel.map((item, i) => (
                <CheckBox
                  label={item.label}
                  checked={gradeTypesExport.includes(item.value)}
                  onChange={(e) => gradeTypesHandler(e, item.value)}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>





      <Modals
        ModalTitle="Edit export options"
        handleClose={(handleClose)}
        handleConfirm={() => handleConfirm()}
        open={open}
        width="552px"

        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"
        ModalFooter={false}
        textAlign="center"
        ModalBody={
          <div>
            <div>Select the options you want to see in the export screen</div>
            <ExportOptions
              userTypesExportEditOption={userTypesExportEditOption}
              setUserTypesExportEditOption={setUserTypesExportEditOption}
              attendenceTypesExportEditOption={attendenceTypesExportEditOption}
              setAttendenceTypesExportEditOption={setAttendenceTypesExportEditOption}
              gradeTypesExportEditOption={gradeTypesExportEditOption}
              setGradeTypesExportEditOption={setGradeTypesExportEditOption}
              userTypes={userTypes}
              attendance={attendance}
              classLevel={classLevel}
              userTypesEditExport={userTypesEditExport}
              attendenceTypesEditExport={attendenceTypesEditExport}
              gradeTypesEditExport={gradeTypesEditExport}
              userTypesEditHandler={userTypesEditHandler}
              attendanceTypesEditHandler={attendanceTypesEditHandler}
              gradeTypesEditHandler={gradeTypesEditHandler}
              handleConfirm={handleConfirm}
              handleClose={handleClose}
            />
          </div>
        }

      />

    </div>
  );
};

export default Export;
