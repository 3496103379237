import React, { useEffect, useState } from "react";
import "../../../assets/css/users.css";


import DraggableDialog from '../../../components/hooks/Dialog';
import { useHistory, useNavigate, useParams } from 'react-router-dom';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import CreateCours from './CreateCours';
import {
  useGetCourseDetailsQuery,
  useDeleteCourseMutation,
  useGetClassLevelListQuery,
} from '../../../redux/features/classes/classApi';
import { toast } from "react-toastify";
import useProtected from "../../../hooks/useProtected";
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { useGetSchoolYearListQuery } from '../../../redux/features/school_years/schoolYearApi';
import { TbUsers } from 'react-icons/tb';
import { RiTeamLine } from 'react-icons/ri';
import CourseSettings from './CourseSettings';
import AddSection from './AddSection';

import InnerPageLayout from '../../../components/InnerPageLayout/InnerPageLayout';
import { RiFileInfoFill } from "react-icons/ri";
import { RiGroupLine } from "react-icons/ri";
import { GrGroup } from "react-icons/gr";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import TeachingTeam from './TeachingTeam';
import Students from './Students';
import SectionDetails from './SectionDetails';
import CourseAttendance from "./CourseAttendance";
import Home from './Home';




const Details = () => {

  const { course_id, section_id } = useParams()

  const { data, isLoading, isError, error } = useGetCourseDetailsQuery(course_id);
  const { data: schoolYearList, isLoading: isSchoolYearListLoading, isError: isSchoolYearListError, error: schoolYearListError } = useGetSchoolYearListQuery();
  const { data: classLevelList, isLoading: isClassLevelListLoading, isError: isClassLevelListError, error: classLevelListError } = useGetClassLevelListQuery();
  const [deleteCourse, deleteCourseOptions] = useDeleteCourseMutation();

  useProtected(deleteCourseOptions.error);
  useProtected(schoolYearListError);
  useProtected(classLevelListError);

  useEffect(() => {
    if (!isSchoolYearListLoading && !isSchoolYearListError) {
      const data = schoolYearList.body.data;
      setSchoolYearOptions(getPreparedArray(data || []))
      if (data && data.length > 0) setSchoolYear(data[0].name)
    }
  }, [schoolYearList, isSchoolYearListLoading, isSchoolYearListError])


  useEffect(() => {
    if (!isClassLevelListLoading && !isClassLevelListError) {
      const data = classLevelList.body.data;
      setClassLevelOptions(getPreparedArray(data || []))
      if (data && data.length > 0) setClassLevel(data[0].name)
    }
  }, [classLevelList, isClassLevelListLoading, isClassLevelListError])

  const [currentTab, setCurrentTab] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const [applicationList, setApplicationList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [createIdDrawer, setCreateIdDrawer] = useState(false);
  const [schoolYearOptions, setSchoolYearOptions] = useState([])
  const [classLevelOptions, setClassLevelOptions] = useState([])
  const [schoolYear, setSchoolYear] = useState('')
  const [classLevel, setClassLevel] = useState('')

  const getPreparedArray = (data) => {
    return data.map((item) => ({
      value: item.name,
      label: item.name,
    }));
  };

  const handleOpenDrawer = () => setCreateIdDrawer(true);
  const handleCloseDrawer = () => setCreateIdDrawer(false);

  useEffect(() => {
    console.log(createIdDrawer)
  }, [createIdDrawer])

  const handleTabSwitch = (activeTab) => {
    setCurrentTab(activeTab);
  };

  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const navigate = useNavigate();
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  //======= Delete dialog ===== //

  //======= Remove  from group dialog ===== //
  const [openDialogRemoveGroup, setOpenRemoveGroup] = useState(false);
  const handleOpenRemoveGroup = () => {
    // setOpenRemoveGroup(true);
  };
  const handleCloseRemoveGroup = () => {
    setOpenRemoveGroup(false);
  };
  //======= Remove from group dialog  ===== //

  // Classes Drawer //
  const [openEditDrawer, setOpenEdit] = useState(false);
  const handleEditDrawerOpen = (id) => {
    setSelectedCourse(id);
    // setOpenEdit(true);
    setCreateIdDrawer(true);
  };
  const handleCloseEditDrawer = () => {
    // setOpenEdit(false);
    setCreateIdDrawer(true);
  };

  // Settings Drawer //
  const [OpenSettings, setOpenSettings] = useState(false);
  const handleDrawerOpenSettings = () => {
    setOpenSettings(true);
  };
  const handleDrawerCloseSettings = () => {
    setOpenSettings(false);
  };


  // Add Section //
  const [openDrawerAdd, setOpenAdd] = useState(false);
  const handleDrawerOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleCloseDrawerAdd = () => {
    setOpenAdd(false);
  };




  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };





  const [courseDetails, setCourseDetails] = useState({})
  useEffect(() => {
    if (!isLoading && !isError) {
      setCourseDetails(data?.body?.data)
    }
  }, [data, isLoading, isError]);


  const deleteCourseHandler = async () => {
    const result = await deleteCourse(course_id);
    if (result?.data) {
      toast.success('Course deleted');
      setOpenDialog(false)
    } else {
      toast.error(result?.data?.message);
    }
    // navigate(``);
    navigate(`/courses`)
  }


  const breadcrumbsData = [
    {
      label: "Courses",
      link: "/courses",
    },

    {
      label: "Details",
    },

  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const sideBarTabLabel = [
    {
      icon: <RiFileInfoFill />,
      label: "Home",
    },
    {
      icon: <RiGroupLine />,
      label: "Students",
    },
    {
      icon: <GrGroup />,
      label: "Teaching Team",
    },
    // {
    //   icon: <CampaignOutlinedIcon />,
    //   label: "Announcements",
    // },
    {
      icon: <CalendarTodayOutlinedIcon />,
      label: "Attendance",
    },
  ]

  const sideBarTabBody = [
    {
      id: 0,
      panel: <Home />,
    },
    {
      id: 1,
      panel: <Students
        section_id={section_id}
        course_id={course_id}
        courseDetails={courseDetails}
      />,
    },
    {
      id: 2,
      panel: <TeachingTeam
        section_id={section_id}
        course_id={course_id}
        courseDetails={courseDetails}
      />,
    },

    // {
    //   id: 3,
    //   panel: "Announcements",
    // },

    {
      id: 3,
      panel: <CourseAttendance
        section_id={section_id}
        course_id={course_id}
        courseDetails={courseDetails} />,
    },

  ]







  return (
    <>

      <InnerPageLayout
        BackArrow={true}
        BackArrowlink={`/course-details/${course_id}`}
        //BackArrowOnlcikc={BackArrowOnlcikc}
        CloseArrow={true}

        BreadcrumbsStatus={true}
        breadcrumbsData={breadcrumbsData}

        SideBarStatus={true}
        sideBarTabLabel={sideBarTabLabel}
        sideBarTabBody={sideBarTabBody}


      />




      {/* Delete Group*/}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={deleteCourseHandler}
        title="Delete course?"
        body="Are you sure you want to delete this course? Once you delete it, you can't get it back."
        ModalFooter={true}
        actionButton="Delete"
      />

      {/* Remove From Group*/}
      <DraggableDialog
        openDialog={openDialogRemoveGroup}
        handleCloseDialog={handleCloseRemoveGroup}
        title="Remove user from group?"
        body="Are you sure you want to this user from the group?"
        ModalFooter={true}
        actionButton="Remove"
      />

      {/* Edit Class drawer */}
      <SideDrawer
        open={openDrawerAdd}
        handleDrawerClose={handleCloseDrawerAdd}
        title="Add section"
        sideOpen="right"
        ButtonLabel="Add section"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={false}
        body={
          <>
            <AddSection

            />
          </>
        }
      />


      {/* Settings drawer */}
      <SideDrawer
        open={OpenSettings}
        handleDrawerClose={handleDrawerCloseSettings}
        title="Course Settings"
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        body={
          <>
            <CourseSettings

            />
          </>
        }
      />


    </>
  );
};




export default Details;



