import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useGetSearchByRoleAndTermResultMutation, useGetUsersByRoleQuery } from "../../../redux/features/users/userApi";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import { toTitleCase } from "../../../utils/helpers"


const AddStudents = (props) => {
  const { classStudents, setClassStudents, classDetailsInfo, schoolYear, setCourseUsersToUpdate, courseUsersToUpdate, studentsToThisClassLevel } = props
  const { data, isError, isLoading, error } = useGetUsersByRoleQuery('student', { refetchOnMountOrArgChange: true });
  const [getSearchedUserListByRoleAndTerm, options] = useGetSearchByRoleAndTermResultMutation();

  useProtected(error);
  useProtected(options.error)

  const [searchTerm, setSearchTerm] = useState('');
  const [uersAvailable, setUersAvailable] = useState([]);
  const [rows, setRows] = useState([]);


  useEffect(() => {
    if (!isLoading && !isError) {
      let usersToShow = data?.body?.data?.filter((singleUser) => {
        let userFound = studentsToThisClassLevel?.find((singUserAtt) => singUserAtt.userUniqueID == singleUser.userUniqueID)
        return userFound ? false : true
      })
      setRows(convertDataToCellRows(usersToShow));
      setUersAvailable(usersToShow)
    }
  }, [data, isError, isLoading])


  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr.map((item) => ({
        cell: [
          {
            Check: true, width: "34px",
            user_id: item?.userUniqueID,
            user: {
              userUniqueID: item.userUniqueID,
              firstName: `${item?.firstName}`,
              lastName: `${item?.lastName}`,
              role: item?.role,
              email: item?.email,
              status: item?.status,
              classLevel: classDetailsInfo?.name,
              schoolYearId: schoolYear
            }
          },
          {
            textBold: <>{item.firstName} {item.lastName}&nbsp;</>,
            user_id: item?.userUniqueID,
            user: {
              userUniqueID: item.userUniqueID,
              firstName: `${item?.firstName}`,
              lastName: `${item?.lastName}`,
              role: item?.role,
              email: item?.email,
              classLevel: classDetailsInfo?.name,
              schoolYearId: schoolYear
            },
            Status: true,
            background: item.status === 'active' ? '#EDF7EA' : '#FCE7E9',
            color: item.status === 'active' ? '#66BD50' : '#E63946',
            label: item.status === 'active' ? 'Active' : 'Inactive'
          },
          { text: item?.userUniqueID || "N/A" },
          { text: toTitleCase(item?.role) || "N/A" },
          { Status: true, background: '#EDEDEF', color: '#676C7B', label: 'N/A', },
        ]
      }));
    } else {
      return [];
    }

  };

  useEffect(() => {
    let newUser = [];
    classStudents.map((student) => {
      let foundUser = uersAvailable.find((user) => user.userUniqueID === student)
      if (foundUser) {
        newUser.push({
          userUniqueID: foundUser.userUniqueID,
          firstName: `${foundUser?.firstName}`,
          lastName: `${foundUser?.lastName}`,
          role: foundUser?.role,
          email: foundUser?.email,
          status: foundUser?.status,
          classLevel: classDetailsInfo?.name,
          schoolYearId: schoolYear
        })

      }
    })
    setCourseUsersToUpdate(newUser)
  }, [classStudents])


  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add the user to the array
      setClassStudents(prevStudents => [...prevStudents, item[0].user]);
      // setCourseUsersToUpdate(prevUsers => [...prevUsers, item[0].user]);
    } else {
      // Remove the user from the array
      setClassStudents(prevStudents => prevStudents.filter(user => user.userUniqueID !== item[0].user.userUniqueID));
      // setCourseUsersToUpdate(prevUsers => prevUsers.filter(user => user.userUniqueID !== item[0].user.userUniqueID));
    }
  }

  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Full Name", width: "40%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "ID #", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "User Type", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Class/Level", width: "30%", textAlign: "left", },
  ];



  ///============================== search part start  ========================///
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }

  const handleSearch = async () => {
    const result = await getSearchedUserListByRoleAndTerm({ role: 'student', term: searchTerm });
    console.log({ result })
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }

  }
  ///============================== search part end  ========================///

  return (
    <>

      <div className="TableSection">
        <Table
          tableHeader={true}
          headCells={headCells}
          rows={rows}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          innerHeader={true}
          showSearchBox={true}
          searchBoxPlaceholder="Search students"
          handleCheckItem={setClassStudents}
          selectedCheckList={classStudents}
          customCellObjectId="user_id"
          CheckboxDelete={true}
          isDataLoading={isLoading || options?.isLoading}
          FilterButtonDrawer={false}
          //onClickFilterButton={}
          handleRowClick={handleRowClick}

          // CheckboxDeleteModal={true}
          // DeleteText="REMOVE STUDENT"
          RowsPerPage={10}

        // selectAllHander={handleSelectAllClick}
        // selectAllHandler={handleSelectAllClick}
        />
      </div>

    </>
  );
};

export default AddStudents;
