import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import TableHead from '@material-ui/core/TableHead';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination } from '@mui/material';
// import Table from '../../../components/Table/Table';
import { makeStyles } from '@mui/styles';
import { styled } from "@mui/system";
import AttachmentRoundedIcon from '@mui/icons-material/AttachmentRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import SelectField from '../../../components/Form/SelectField/SelectField';
import Buttons from '../../../components/Form/Button/Button';
import TextArea from '../../../components/Form/TextArea/TextArea';

import AddClock from './AddClock';

import '../../../assets/css/users.css';
import {
  useGetAllAttendanceCodesQuery,
  useGetAttendanceDetailsQuery,
  useUpdateAttendanceMutation,
  useAddClockInTimeMutation,
  useAddClockOutTimeMutation,
  useUploadAbsentReasonAttachmentMutation,
  useDeleteAbsentReasonAttachmentMutation
} from '../../../redux/features/attendence/attendanceApi';
import useProtected from '../../../hooks/useProtected';
import Loader from '../../../components/Loader/Loader';
import { Button } from '@mui/material';
import { validateFormData } from '../../../utils/form';
import { toast } from 'react-toastify';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import { toTitleCase } from '../../../utils/helpers';
import dayjs from 'dayjs';
import Activities from './Activities';
import { useGetStudentClassMutation } from '../../../redux/features/classes/classApi';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MediaLibrarySelectMedia from '../../../components/MediaLibrary/MediaLibrarySelectMedia';
import Externaluse from '../../../components/MediaLibrary/Externaluse';
import DraggableDialog from '../../../components/hooks/Dialog';

import Tables from '../../../components/Table/Table';
import { useSelector } from 'react-redux';
import { isTheActionPermitted } from '../../../hooks/HelperFunctionsPermission';

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({
  root: {
    "&.MuiTableCell-root": {
      width: '50%',
      padding: '8px 16px'
    }
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2f0f4',
    },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

// ================= Table OD and EVEN Code ========//
const StyledTableCells = withStyles((theme) => ({}))(TableCell);

const StyledTableRows = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f2f0f4',
    },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

// * ====================== Test table =====================
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  "&.MuiPaper-root": {
    // border: '1px solid red',
    // borderRadius: "12px",
    boxShadow: "none !important",
  }
}))

const StyledRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    background: "#F2F0F4",
  },
  color: "#000000",

  // "&:hover": {
  //     boxShadow: "none",
  //     background: "#DD2D37",
  // },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
// * ====================== Test table =====================

const AttendanceDetails = ({ attendanceId, closeDrawer, previousSelectedStatus, setPreviousSelectedStatus, currentSelectedStatus, singleDrawerCloser, setCurrentSelectedStatus }) => {


  const [addClockInTime, addClockInTimeOptions] = useAddClockInTimeMutation();
  const [getStudentClass, getStudentClassOptions] = useGetStudentClassMutation()
  const [addClockOutTime, addClockOutTimeOptions] = useAddClockOutTimeMutation();
  const [updateAttendance, updateAttendanceOptions] = useUpdateAttendanceMutation();
  const [uploadAbsentReasonAttachment, uploadAbsentReasonAttachmentOptions] = useUploadAbsentReasonAttachmentMutation();
  const [deleteAbsentReasonAttachment, deleteAbsentReasonAttachmentOptions] = useDeleteAbsentReasonAttachmentMutation();

  const { data, isLoading, isError, error } = useGetAttendanceDetailsQuery(attendanceId);

  const { data: attendanceCodeResponse, isLoading: isLoadingAttendanceCode, error: attendanceCodeError } = useGetAllAttendanceCodesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useProtected(error);
  useProtected(attendanceCodeError);
  useProtected(addClockInTimeOptions.error);
  useProtected(addClockOutTimeOptions.error);
  useProtected(getStudentClassOptions.error);
  useProtected(updateAttendanceOptions.error);
  useProtected(uploadAbsentReasonAttachmentOptions.error);
  useProtected(deleteAbsentReasonAttachmentOptions.error);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  // == Select Field ===//
  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };
  const [clockTime, setClockTime] = useState('')
  const [clockType, setClockType] = useState('')
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [studentClass, setStudentClass] = useState({})
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAttachmentToDelete, setSelectecAttachmentToDelete] = useState('')
  const [isActiveLogoIcon, setActiveLogoIcon] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState(false);
  const [statusToRemember, setStatusToRemember] = useState('')
  const [clockInOutTimeError, setClockInOutTimeError] = useState('')
  const [mediaData, setMediaData] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);
  const [errors, setErrors] = useState({
    status: '',
    reason: '',
    comment: '',
  });
  const [selectedCode, setSelectedCode] = useState();
  const [attendanceCodeOptions, setAttendanceCodeOptions] = useState([]);
  const [clockTypeError, setClockTypeError] = useState(false)
  useEffect(() => {

    const currentTimeFormatted = dayjs().format('HH:mm');
    setClockTime(currentTimeFormatted)
  }, [])
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };



  const deleteAttachmentHandler = async () => {
    const deletedFilterData = attachmentData.filter((datas, index) => index !== selectedAttachmentToDelete)
    setAttachmentData(deletedFilterData)
    toast.success('Attachment deleted successfully');
    setOpenDialog(false)
    setSelectecAttachmentToDelete('')
    handleCloseDeleteDialog()
  }

  const convertAttendanceCodeResponseToSelectItem = (locations) => {
    if (locations.length > 0) {
      // console.log(locations)
      return locations.map((item) => ({ label: `${item.code.charAt(0).toUpperCase()}${item.code.slice(1)} - ${item.description.charAt(0).toUpperCase()}${item.description.slice(1)}`, value: item.code }));
    } else {
      return [];
    }
  };
  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setSelectedValue(false)
    setMediaItem({})
    setOpenSelectMedia(false);
  };
  const handleDrawerOpenNewMedia = () => {
    setOpenNewMedia(true);
  };

  //========= add media item =============//
  const clickSelectMediaItem = async () => {
    let newAttachments = [...attachmentData]
    if (selectedValue) {
      newAttachments.push(mediaItem.url)
      setAttachmentData(newAttachments)
      setSelectedValue(false)
      setMediaItem({})
      setOpenSelectMedia(false);
    } else {
      alert("Please select any media");
    }
  };
  //========= add media item =============//


  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };

  const handleMediaUploadClose = () => {
    setOpenNewMedia(false);
  };

  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
    setActiveLogoIcon(!isActiveLogoIcon);
  };



  useEffect(() => {
    if (!isLoadingAttendanceCode && !attendanceCodeError) {
      setAttendanceCodeOptions(
        convertAttendanceCodeResponseToSelectItem(
          attendanceCodeResponse.body.data,
        ),
      );
    }
    if (data && !error) {
      setSelectedCode(attendanceCodeResponse.body.data.find(item => item._id === data.body.data.status))
    }
  }, [attendanceCodeResponse, isLoadingAttendanceCode, attendanceCodeError, data]);


  const reasonOptions = [
    {
      label: 'Medical',
      value: 'Medical',
    },

    {
      label: 'Family Emergency',
      value: 'Family Emergency',
    },
    {
      label: 'Transportation',
      value: 'Transportation',
    },
  ];
  const [attendanceDetails, setAttendanceDetails] = useState(null);

  useEffect(() => {
    if (!isLoading && !isError) {
      setAttendanceDetails(data.body.data);
      setStatus(data?.body?.data?.status);
      setReason(data.body.data.reason);
      setComment(data.body.data.comment);
      setAttachmentData(data.body.data.absentAttachment)
      setStudentClassFromServer(data?.body?.data?.userUniqueID)
      if (previousSelectedStatus == '') {
        setPreviousSelectedStatus(data?.body?.data?.status)
      }
      setCurrentSelectedStatus(data?.body?.data?.status)
      if (statusToRemember != '') setStatus(statusToRemember)
    }
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (comment) {
      setComment(comment)
    }
    // console.log(comment, 34)
  }, [comment])

  // console.log(data?.body?.data)

  const setStudentClassFromServer = async (userId) => {
    let result = await getStudentClass({ userUniqueID: userId.toString() })
    if (result?.data && result?.data?.body?.data.length > 0) {
      setStudentClass(result?.data?.body?.data)
    }
  }

  const [OpenAttendanceDetails, setOpenAttendanceDetails] = useState(false);
  const handleOpenAttendanceDetails = () => {
    if (!isTheActionPermitted('addClockInOut')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenAttendanceDetails(true);
  }
  const handleCloseAttendanceDetails = () => {
    setClockInOutTimeError("")
    setOpenAttendanceDetails(false);
  }

  const handleUpdateAttendance = async () => {
    if (!isTheActionPermitted('updateAttendance')) {
      toast.error("You don't have access to this feature")
      return false
    }
    let data = status === 'P' ? {
      status,
      // reason,
      // comment,
    } : {
      status,
      reason,
      comment,
      absentAttachment: attachmentData

    };
    let errorObj;
    if (status === 'P') {
      errorObj = {
        status: 'Enter attendance',
      };
    } else {
      errorObj = {
        status: 'Enter attendance',
        reason: 'Enter absent reason',
        comment: 'Enter comments',
      };
    }

    const isValid = validateFormData(data, setErrors, errorObj);
    if (!isValid) {
      return;
    }

    const result = await updateAttendance({ id: attendanceId, ...data });

    if (result?.data) {
      toast.success('Attendance updated successfully');
      setStatus('');
      setReason('');
      setComment('');
      singleDrawerCloser(false);
    } else {
      toast.error(result?.data?.message);
    }
  };

  const handleInputChange = (e) => (setState) => {
    setErrors((prev) => {
      prev[e.target.name] = '';
      return prev;
    });
    if (e.target.name === 'status') {
      setCurrentSelectedStatus(e.target.value)
      setSelectedCode(attendanceCodeResponse.body.data.find(item => item._id === e.target.value))
      setStatusToRemember(e.target.value)

    }
    setState(e.target.value);
  };

  const isValidTimeAndTypeCheck = async (timestamp) => {
    console.log(attendanceDetails.clockIn, attendanceDetails.clockOut, timestamp)
    if (clockType === 'clock_in') {

    } else if (clockType === 'clock_out') {
      if (timestamp < (attendanceDetails.clockIn * 1)) {
        setClockInOutTimeError('Clock-out time should be later than the clock-in time.')
        return false
      }
    }
    return true

  }

  const addAttendanceClockInOutHandler = async () => {

    console.log('already added')
    let thisDate = new Date();
    let thisDateRegularFormat = thisDate.toISOString().split('T')[0];

    const [hours, minutes] = clockTime.split(':');
    const formattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    const date = dayjs(`${thisDateRegularFormat} ${formattedTime}`, { format: 'YYYY-MM-DD HH:mm' });
    const timestamp = date.valueOf();

    const data = {
      id: attendanceId,
      time: timestamp
    };


    let isValidTimeAndType = await isValidTimeAndTypeCheck(timestamp)

    let isValid = validateFormData(data, setErrors, {
      time: 'Enter clock time',
    });
    if (clockType === '') {
      isValid = false
      setClockTypeError(true)
    } else {
      console.log(isValid)
    }
    if (!isValid || !isValidTimeAndType) {
      return;
    }

    let result = null;
    if (clockType === 'clock_in') {
      result = await addClockInTime(data);
    } else if (clockType === 'clock_out') {
      result = await addClockOutTime(data);
    }
    setClockTypeError(false)
    if (result?.data) {
      if (clockType === 'clock_in') {
        toast.success('Clock in added successfully');
      } else if (clockType === 'clock_out') {
        toast.success('Clock out added successfully');
      }

      setClockTime('')
      setClockType('')
      setClockInOutTimeError('')

    } else {
      toast.error(result?.data?.message);
    }
    handleCloseAttendanceDetails()
  }

  const showTimeline = () => {
    // console.log(data)
    // return false;
    let timeLines = []
    timeLines = attendanceDetails?.timeLine?.map((singleTime) => {
      const date = new Date(singleTime.time);

      const monthAbbreviations = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const formattedDate = `${date.getDate()}-${monthAbbreviations[date.getMonth()]}-${date.getFullYear()}, ${date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}`;

      return (<StyledTableRows >
        <StyledTableCell align="left" className="TableLabel">{singleTime.attendanceType}</StyledTableCell>
        <StyledTableCell align="left" className="TableInfo">{formattedDate}</StyledTableCell>
        <StyledTableCell align="left" className="TableInfo">{singleTime.type}</StyledTableCell>
      </StyledTableRows>)
    })
    return timeLines
  }

  const updateStatusOnServer = async () => {
    let data = status === 'A' ? {
      status,
    } : {
      status,
      // reason,
      // comment,
    };

    let errorObj;
    if (status === 'P' || status === '') {
      errorObj = {
        status: 'Enter attendance',
      };
    } else {
      if (status === 'A') {

        errorObj = {
          status: 'Enter attendance',
          reason: 'Enter absent reason',
          comment: 'Enter comment',
        };
      }
    }

    const isValid = validateFormData(data, setErrors, errorObj);

    if (!isValid) {
      return;
    }

    const result = await updateAttendance({ id: attendanceId, ...data });

    if (result?.data) {
      // closeDrawer();
    }
  }
  useEffect(() => {
    // updateStatusOnServer()
  }, [status])


  const deleteConfirmModalOpen = (index) => {
    setSelectecAttachmentToDelete(index)
    setOpenDialog(true)
  }
  const showAttachments = () => {
    // console.log(attachmentData, 551)
    let attachments = []
    attachmentData?.map((attachment, index) => {
      attachments.push(<div className='attachmentCardContainer' key={index} style={{ marginBottom: '10px' }}>
        {/* {console.log(attachment)} */}
        <div className='attachmentIcon'>
          <AttachmentRoundedIcon />
        </div>
        <div className='attachmentFileInfo'>
          <p>{attachment}</p>
          <Button link={attachment} variant='text' sx={{ m: 0, p: 0, color: '#0450E1', fontWeight: '600', textTransform: 'initial' }}>Download</Button>
        </div>
        <div className='attachmentDeleteBtn'>
          <IconButton>
            <DeleteForeverRoundedIcon onClick={(e) => deleteConfirmModalOpen(index)} />
          </IconButton>
        </div>
      </div>)
    })
    return attachments
  }











  // === Time line Table Start == //
  const onClick = () => {
    alert('onclick working fine!');
  };

  const headCells = [
    { id: 'Name', numeric: false, disablePadding: true, label: '', width: '40%', textAlign: 'left', },
    { id: 'ID', numeric: false, disablePadding: true, label: 'Time', width: '60%', textAlign: 'left', },
    { id: 'Users', numeric: false, disablePadding: true, label: 'Type', width: '20%', textAlign: 'left', },

  ];

  const rows = [

    {
      cell: [
        { text: "Clock In	", color: "#46464A", },
        { textBold: "7-Nov-2023, 10:39 AM	", },
        { textBold: "Manual", TextAlign: "right", },
      ],
    },

    {
      cell: [
        { text: "Clock In	", color: "#46464A", },
        { textBold: "7-Nov-2023, 10:40 AM	", },
        { textBold: "Manual", TextAlign: "right", },
      ],
    },

    {
      cell: [
        { text: "Clock In	", color: "#46464A", },
        { textBold: "7-Nov-2023, 1:39 AM	", },
        { textBold: "Manual", TextAlign: "right", },
      ],
    },


    {
      cell: [
        { text: "Clock In	", color: "#46464A", },
        { textBold: "7-Nov-2023, 4:39 AM	", },
        { textBold: "Manual", TextAlign: "right", },
      ],
    },

  ];
  // === Time line Table Start == //

  return (
    <>
      <div className="InvitedUsers">
        {isLoading ? (
          <Loader
            height="80px"
            width="80px"
            borderWidth="8px"
            isFullWidth={true}
            isOverlay={true}
          />
        ) : (
          <div className="ProfileTable">
            <div>
              <div style={{ border: '1px solid #d1d1d9', borderRadius: '12px', overflow: 'hidden' }}>
                <StyledTableContainer component={Paper}>
                  <Table>
                    <TableBody>

                      <StyledRow>
                        <TableCell>User</TableCell>
                        <TableCell className="TableInfo">{attendanceDetails?.name || 'N/A'}</TableCell>
                      </StyledRow>
                      <StyledRow>
                        <TableCell>User Type</TableCell>
                        <TableCell className="TableInfo">{toTitleCase(attendanceDetails?.userType || 'N/A')}</TableCell>
                      </StyledRow>
                      <StyledRow>
                        <TableCell>Class/Level</TableCell>
                        <TableCell className="TableInfo">{(studentClass.length > 0) ? studentClass[0]?.name : <span className='TableLabel'>{'Not Available'}</span>}</TableCell>
                      </StyledRow>
                      <StyledRow>
                        <TableCell>ID</TableCell>
                        <TableCell className="TableInfo">{attendanceDetails?.userUniqueID || 'N/A'}</TableCell>
                      </StyledRow>
                      <StyledRow>
                        <TableCell align="left" className="TableLabel">
                          Attendance
                        </TableCell>
                        <TableCell align="left" className="TableInfo">
                          <div className="UserTypeSelect">
                            <SelectField
                              selectedOption={status}
                              options={attendanceCodeOptions}
                              name="status"
                              background="#fff"
                              handleChange={(e) => {
                                handleInputChange(e)(setStatus)

                              }
                              }
                              helperText={error?.status}
                            />
                          </div>
                        </TableCell>
                      </StyledRow>

                      {status !== 'P' && (
                        <>
                          <StyledRow>
                            <StyledTableCell align="left" className="TableLabel">
                              Reason
                            </StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo">
                              <div className="UserTypeSelect">
                                <SelectField
                                  FieldLabel={false}
                                  label="Select reason"
                                  selectedOption={reason}
                                  options={reasonOptions}
                                  name={"reason"}
                                  handleChange={(e) =>
                                    handleInputChange(e)(setReason)
                                  }
                                  error={errors?.reason}
                                  helperText={errors?.reason}
                                />
                              </div>
                            </StyledTableCell>
                          </StyledRow>

                          <StyledRow>
                            <StyledTableCell
                              align="left"
                              className="TableLabel"
                              style={{ verticalAlign: 'top' }}
                            >
                              Comment
                            </StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo">
                              <div className="UserTypeSelect">
                                <TextArea
                                  name="comment"
                                  placeholder="Write comment here..."
                                  value={comment}
                                  handleChange={(e) =>
                                    handleInputChange(e)(setComment)
                                  }
                                  errorMsg={errors.comment}
                                  count={100}
                                />
                              </div>
                            </StyledTableCell>
                          </StyledRow>
                        </>
                      )}
                      {/* ====================== Attachment field start ====================== */}
                      {status === 'A' && (
                        <StyledRow>
                          <StyledTableCell
                            align="left"
                            className="TableLabel"
                            style={{ verticalAlign: 'top' }}
                          >
                            Attachments
                          </StyledTableCell>
                          <StyledTableCell align="left" className="TableInfo">
                            {(attachmentData?.length > 0) && showAttachments()}
                            <Button
                              variant='outlined'
                              sx={{
                                width: '100%',
                                color: '#0450E1',
                                fontWeight: '700',
                                justifyContent: 'flex-start',
                                border: '1px solid #C6C6D0',
                                borderRadius: '8px'
                              }}
                              startIcon={<AddRoundedIcon />}
                              onClick={(e) => handleDrawerOpenSelectMedia()}
                            >
                              Add attachment
                            </Button>

                            {/* ========= Media lib drawer start ============ */}

                            <SideDrawer
                              title="Media Library"
                              ButtonLabel="Add"
                              //clickSaveButton={clickSaveButton}
                              cancelButtonLabel="Cancel"
                              sideOpen="right"
                              open={openSelectMedia}
                              handleDrawerClose={handleDrawerCloseSelectMedia}
                              FooterWithoutTab={true}
                              LeftButton={true}
                              ButtonLeft="Upload New"
                              ButtonLeftIcon={<FileUploadOutlinedIcon />}
                              clickButtonLeft={handleDrawerOpenNewMedia}
                              clickSaveButton={clickSelectMediaItem}
                              padding="0"
                              body={
                                <MediaLibrarySelectMedia
                                  onClickRadioIcon={setSelectedValue}
                                  onItemSelected={getMediaItem}
                                />
                              }
                            />

                            <Externaluse
                              open={openNewMedia}
                              handleCloseButton={handleMediaUploadClose}
                              handleOpenNewMedia={(handleOpenNewMedia)}
                            />
                          </StyledTableCell>
                        </StyledRow>
                      )}
                      {/* ====================== Attachment field end ====================== */}

                    </TableBody>
                  </Table>

                </StyledTableContainer>
              </div>
            </div>

            {/* <div className="DividerLine"></div> */}
            <div style={{ padding: '24px', width: '100%' }}></div>

            <div className="TableTitle">
              Timeline
              <span></span>
              <Buttons
                label="Add entry"
                buttonColor="#fff"
                border="#FFB606 1px solid"
                color="#000"
                width=""
                height="40px"
                fontSize="14px"
                link=""
                disabled={status === 'A'}
                onClick={handleOpenAttendanceDetails}
              />
            </div>

            {/* important code (commented temporarily) */}
            <div className="TableContainer oddEvenTable">
              <TableContainer component={Paper}>
                <Table aria-label="customized table">


                  <TableHead>
                    <TableRow style={{ background: "#f2f0f4" }}>
                      <StyledTableCells></StyledTableCells>
                      <StyledTableCells>Time</StyledTableCells>
                      <StyledTableCells>Type</StyledTableCells>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {showTimeline()}
                    {console.log(showTimeline())}

                    {/* <StyledTableRow >
                      <StyledTableCell align="left" className="TableLabel">Clock Out</StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">6/26/2023, 8:00 PM</StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">ID Scanned</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow >
                      <StyledTableCell align="left" className="TableLabel">Clock In</StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">6/26/2023, 10:00 AM</StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">Manual</StyledTableCell>
                    </StyledTableRow> */}

                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {/* ==================== */}
            {/* <Activities
              handleOpenAttendanceDetails={handleOpenAttendanceDetails}
              attendanceDetails={attendanceDetails} /> */}
          </div>
        )}

        {/* new user drawer */}
        <SideDrawer
          open={OpenAttendanceDetails}
          handleDrawerClose={handleCloseAttendanceDetails}
          title="Add clock in / clock out time"
          buttonLabel="Save"
          // buttonLink={}
          sideOpen="right"
          ButtonLabel="Save"
          cancelButtonLabel="Cancel"
          FooterWithoutTab={true}
          body={
            <>
              <AddClock />
            </>
          }
        />

        <DrawerFooterActions
          isLoading={updateAttendanceOptions.isLoading}
          handleClose={closeDrawer}
          handleAction={handleUpdateAttendance}
          closeBtnLabel={'Cancel'}
          mobileCloseBtnLabel={'Cancel'}
          actionButtonLabel={'Save'}
        />

        <SideDrawer
          open={OpenAttendanceDetails}
          handleDrawerClose={handleCloseAttendanceDetails}
          title="Add clock in / clock out time"
          buttonLabel="Save"
          // buttonLink={}
          sideOpen="right"
          ButtonLabel="Save"
          cancelButtonLabel="Cancel"
          FooterWithoutTab={true}
          clickSaveButton={addAttendanceClockInOutHandler}

          body={
            <>
              <AddClock
                clockTime={clockTime != '' ? clockTime : dayjs().format('HH:mm')}
                setClockTime={setClockTime}
                clockType={clockType}
                setClockType={setClockType}
                clockTypeError={clockTypeError}
                setClockTypeError={setClockTypeError}
                attendanceDetails={attendanceDetails}
                clockInOutTimeError={clockInOutTimeError} />
            </>
          }
        />
      </div >

      {/* Delete Group*/}
      < DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={deleteAttachmentHandler}
        title="Delete attachment?"
        body={<> Are you sure you want to delete this attachment ? <br />Once you delete it, you can't get it back.</>}
        ModalFooter={true}
        actionButton="DELETE"
        cancelButton="CANCEL"
      />
    </>
  );
};

export default AttendanceDetails;
